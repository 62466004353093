<template>
  <v-card class="teros-elevation" :color="tableColor">
    <v-card-title class="px-5 pb-0 text-uppercase v-title-cadastro">Log de Horas por Colaborador</v-card-title>
    <v-card-text>
      <div class="d-flex pt-1">
        <div class="table-v-action-button mr-3 pt-1" @click="doLoad()">
          <v-icon>mdi-refresh</v-icon>Atualizar
        </div>
        <v-autocomplete
          v-if="!funcionarioLoggedId"
          v-model="funcionarioId"
          :items="opts.colaboradores"
          item-value="id"
          item-text="label"
          dense
          placeholder="nenhum selecionado"
          prefix="Colaborador:"
          prepend-icon="mdi-account-box-outline"
          hide-details
          clearable
          class="mb-n1 mt-0 mr-6"
          style="max-width: 550px"
          @change="doLoad()"
        ></v-autocomplete>
        <monthly-filter
          style="width: 220px"
          oneMonth
          @change="
            competencia = $event;
            doLoad();
          "
        ></monthly-filter>
      </div>
    </v-card-text>
    <v-row class="m-0">
      <v-col class="p-0">
        <div
          class="font-weight-medium pt-4 pb-3 text-uppercase month-label"
        > 
          {{ mesLabel }} 
        </div>
      </v-col>
    </v-row>
    <v-row
      v-if="!(funcionarioId || funcionarioLoggedId)"
      class="m-0"
    >
      <v-col
        class="font-weight-medium white"
        style="text-align: center"
      >
        <p class="m-0">Nenhum colaborador selecionado.</p>
      </v-col>
    </v-row>
    <v-row v-else class="m-0">
      <v-col
        style="text-align: center"
        class="white row-border"
        v-for="(diaSemana, idxD) in ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado', 'Total']"
        v-bind:key="'ds'+idxD"
      > 
        {{ diaSemana }} 
      </v-col>
    </v-row>
    <v-row
      v-for="(semana, idx) in relatorio"
      v-bind:key="'s'+idx"
      class="m-0 white"
      :class="{'row-hover': funcionarioLoggedId}"
      @click="weekClick(semana)"
    >
      <v-col
        v-for="(d, idxD) in semana"
        v-bind:key="'d'+idxD"
        class="p-0 cel-auto-size"
        :class="{'grey': !d.dia, 'lighten-3': !d.dia}"
      >
        <div class="cel b-r b-t d-flex align-center justify-center">
          <v-chip v-if="d.horas > 0" class="ma-2" :color="idxD != 0 && idxD != 6 ? '#25935F' : '#EF8065'" text-color="white">
            <v-avatar left>
              <v-icon>mdi-clock-time-eight-outline</v-icon>
            </v-avatar>
            {{d.horas | toStandardTime}}
          </v-chip>
          <div style="position: absolute;bottom: 2px; right: 6px; font-size:11px">{{d.dia}}</div>
        </div>
      </v-col>
      <v-col class="p-0 cel-auto-size">
        <template v-for="(total,idx) in [totalSemana(semana)]">
          <div class="cel b-t d-flex align-center justify-center" v-bind:key="'t'+idx">           
            <v-chip v-if="total > 0" class="ma-2" color="#10777E" text-color="white">
              <v-avatar left>
                <v-icon>mdi-clock-time-eight-outline</v-icon>
              </v-avatar>
              {{total | toStandardTime}}
            </v-chip>
          </div>
        </template>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        class="d-flex font-weight-medium justify-end px-8"
      >Total de horas: {{totalHorasMes | toStandardTime}}</v-col>
    </v-row>
  </v-card>
</template>

<script>
import moment from "moment";
import { mapGetters } from 'vuex';

export default {
  components: {
    "monthly-filter": () => import("@/components/monthly-filter.vue"),
  },
  computed: {
    ...mapGetters(["clientId"]),
    isClient: function () {
      return this.getClient().isClient;
    },
    resourceColaboradores: function () {
      return this.apiResource(`/v1/rh/${this.clientId}/selecao`);
    },
    resourceTimesheet: function () {
      return this.apiResource(
        `/v1/timesheet/${this.clientId}/${
          this.funcionarioLoggedId
            ? this.funcionarioLoggedId
            : this.funcionarioId
        }`
      );
    },
    mesLabel: function () {
      moment.locale("pt-br");
      return moment(this.competencia).format("MMMM [de] YYYY");
    },
    totalHorasMes: function () {
      return this.relatorio
        .map((s) => this.totalSemana(s))
        .reduce((a, b) => a + b, 0);
    },
    funcionarioLoggedId: function () {
      return this.$store.getters.jwtData.data.link.funcionarioId;
    },
  },
  filters: {
    toStandardTime: function (time) {
      return moment.duration(time, "hours").format("h:mm", {
        trim: false,
      });
    },
  },
  created: async function () {
    await this.getColaboradores();
    this.doLoad();
  },
  methods: {
    doLoad: function () {
      if (this.funcionarioId || this.funcionarioLoggedId) {
        this.resourceTimesheet
          .get({ query: `competencia=${this.competencia}` })
          .then((response) => {
            this.relatorio = this.getSemanas(
              this.funcionarioLoggedId ? response.timesheets : response
            );
          });
      } else {
        this.relatorio = [];
      }
    },
    getColaboradores: async function () {
      return this.resourceColaboradores.get().then((response) => {
        this.opts.colaboradores = (response.colaboradores || response)
          .map((col) => ({ ...col, label: `${col.matricula ? `${col.matricula} — ` : ''}${col.nome}` }))
          .sort(({ nome: nomeA }, { nome: nomeB }) => nomeA.localeCompare(nomeB));
        return this.opts.colaboradores;
      });
    },
    weekClick: function (semana) {
      if (this.funcionarioLoggedId) {
        var dataIni = null;
        if (semana[0].date) {
          dataIni = semana[0].date.format("YYYY-MM-DD");
        } else {
          dataIni = semana[6].date.clone().startOf("week").format("YYYY-MM-DD");
        }
        var dataFim = null;
        if (semana[6].date) {
          dataFim = semana[6].date.format("YYYY-MM-DD");
        } else {
          dataFim = semana[0].date.clone().endOf("week").format("YYYY-MM-DD");
        }

        this.$router.push({ name: "listagem-alocar-horas", query: { dataIni, dataFim } });
      }
    },
    getSemanas: function (response) {
      var semanas = [[]];
      var currentSemana = semanas[0];
      var dia1 = moment(this.competencia + "-01");
      var ultimoDia = dia1.clone().endOf("month");
      for (var i = 0; i < dia1.day(); i++) {
        currentSemana.push({ dia: null });
      }
      for (var j = dia1.date(); j <= ultimoDia.date(); j++) {
        var dia = dia1.clone().date(j);
        if (dia.day() == 0) {
          currentSemana = [];
          semanas.push(currentSemana);
        }
        var totalHoras = response
          .filter((t) => t.data == dia.format("YYYY-MM-DD"))
          .map((t) => t.tempo * 1)
          .reduce((a, b) => a + b, 0);
        currentSemana[dia.day()] = {
          dia: dia.date(),
          horas: totalHoras,
          date: dia,
        };
      }
      for (var k = ultimoDia.day() + 1; k < 7; k++) {
        currentSemana.push({ dia: null });
      }

      return semanas;
    },
    totalSemana: function (semana) {
      return semana.reduce(
        (d1, d2) => {
          if (d2.horas) {
            d1.horas += d2.horas;
          }
          return d1;
        },
        { horas: 0 }
      ).horas;
    },
  },
  data: function () {
    return {
      value: "",
      funcionarioId: null,
      competencia: null,
      relatorio: [],
      opts: {
        colaboradores: [],
      },
    };
  },
  watch: {
    clientId: function () {
      this.getColaboradores();
      this.funcionarioId = null;
      this.doLoad();
    },
  },
};
</script>

<style lang="scss" scoped>
.row-hover:hover {
  cursor: pointer;
  background-color: #f2f9ff !important;
}

.row-hover:hover span {
  cursor: pointer;
}

.cel-auto-size {
  height: calc(16.666vh - 61px);
}

.cel {
  height: 100%;
}

.b-r {
  border-right: #e0e0e0 1px solid;
}

.b-b {
  border-bottom: #e0e0e0 1px solid;
}

.b-t {
  border-top: #e0e0e0 1px solid;
}

.b-l {
  border-left: #e0e0e0 1px solid;
}

.month-label {
  background-color: var(--v-table-header-base);
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
  height: 100%;
  text-align: center;
}
</style>
