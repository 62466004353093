<template>

    <MasterDetail ref="masterDetail" :hasExportCSV="false" :hasExportXLSX="false" :opts="opts" :cols="cols"
      :hasNewButton="false" :canEdit="false" :canDelete="false" :actionBarButtons="actionBarButtons"
      :contextOptions="contextMenu" :customResource="customResource">
    </MasterDetail>

</template>

<script>
import MasterDetail from '@/components/master-detail.vue';
import { mapGetters } from 'vuex';
//import FormModal from '@/components/form-modal.vue';

export default {
  // props: {},
  components: {
    MasterDetail,
    // FormModal,
  },
  // mixins: [],
  // directives: {},
  data() {
    return {
      opts: {
        status: [
          {
            id: 1,
            name: 'Ativo',
          },
          {
            id: 2,
            name: 'Cancelado',
          },
          {
            id: 3,
            name: 'Finalizado',
          },
          {
            id: 4,
            name: 'Em definição do projeto',
          },
          {
            id: 5,
            name: 'Em pareamento de rubricas',
          },
          {
            id: 6,
            name: 'Em parametrização do orçamento',
          },
          {
            id: 7,
            name: 'Em detalhamento orçamento',
          },
        ],
        consultoresGalapos: []
      },
    }
  },
  computed: {
    ...mapGetters(['clientId']),
    actionBarButtons() {
      const actionBarButtons = [];
      const that = this;
      actionBarButtons.push({
        text: "Adicionar",
        icon: "mdi-plus-box-outline",
        show: that.clientId > 0,
        action() {
          that.$router.push({ name: "projetos-captacao-cadastro", query: { clientId: that.clientId } });
        }
      });
      return actionBarButtons.filter(({ show }) => show);
    },
    contextMenu() {
      const contextMenuButtons = [];
      const that = this;
      contextMenuButtons.push(
        // {
        //   name: "Rubrica descrição",
        //   show: true,
        //   cb(row){
        //     that.$router.push({ name: "descricao-dispendios", query: { projetoId: row.id }});
        //   }
        // },
        {
          name: "Lançamentos de despesas",
          show: true,
          cb(row) {
            that.$router.push({ name: "demais-dispendios", params: { id: row.id } });
          }
        },
        {
          name: "Dispêndios de RH",
          show: true,
          cb(row) {
            that.$router.push({ name: "timesheets-captacao", params: { id: row.id } });
          }
        },
        {
          name: "Resumo (PREV/REAL)",
          show: true,
          cb(row) {
            if(row.status !== 1) {
              that.$notify({
                group: "geral",
                duration: 5000,
                type: "error",
                title: "Erro",
                text: `O projeto não está ativo, conclua a configuração do projeto para acessar o resumo do orçamento`,
              });
              return false;
            }
            that.$router.push({ name: "comprovacao-orcamento", params: { id: row.id } });
          }
        },
        {
          name: "sep",
          show: {
            get() {
              return contextMenuButtons.slice(0, 3).some(({ show }) => show) && contextMenuButtons.slice(4).some(({ show }) => show);
            }
          },
        },
        {
          name: "Garantias",
          show: true,
          cb(row) {
            that.$router.push({ name: "captacao-garantias", params: { id: row.id } });
          }
        },
        {
          name: "sep",
          show: {
            get() {
              return contextMenuButtons.slice(0, 3).some(({ show }) => show) && contextMenuButtons.slice(4).some(({ show }) => show);
            }
          },
        },
        {
          name: "Editar",
          show: true,
          cb(row) {
            that.$router.push({ name: "projetos-captacao-edicao", params: { id: row.id } });
          }
        },
        // {
        //   name: "Excluir",
        //   show: true,
        //   cb(row){
        //     that.excluirProjeto(row.id);
        //   }
        // },

      );

      return contextMenuButtons.filter(({ show }) => show);
    },
    resourceUrl() {
      return this.apiResource(`/v1/captacao/projetos/${this.clientId}`);
    },
    customResource() {
      const resource = this.resourceUrl;
      const that = this;
      return {
        ...resource,
        get(...args) {
          return resource.get(args).then((response) => {
            that.getConsultores();
            return response;
          });
        }
      }
    },
    cols() {
      return [
        {
          key: 'id',
          name: 'Código',
          type: this.$fieldTypes.TEXT,
          hideInTable: true,
          hideInform: true,
        },
        {
          key: 'titulo',
          name: 'Titulo projeto',
          type: this.$fieldTypes.TEXT,
        },
        {
          key: 'nro_contrato',
          name: 'Nº contrato',
          type: this.$fieldTypes.TEXT,
        },
        {
          key: 'status',
          name: 'Status',
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: 'status', key: 'id', name: 'name' }
        },
        {
          key: 'periodo_retroativo',
          name: 'Período retroativo',
          type: this.$fieldTypes.DATE,
        },
        {
          key: 'data_assinatura',
          name: 'Data assinatura',
          type: this.$fieldTypes.DATE,
        },
        {
          key: 'gestor_galapos_id',
          name: 'Responsável',
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: 'consultoresGalapos', key: 'id', name: 'nome' }
        },
        {
          key: 'consultor_galapos_id',
          name: 'Responsável técnico',
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: 'consultoresGalapos', key: 'id', name: 'nome' }
        },
      ]
    }
  },
  // filters: {},
  // created() {},
  // mounted() {},
  // updated() {},
  // destroyed() {},
  methods: {
    excluirProjeto(idProjeto) {
      const resource = this.resourceUrl;
      return resource.delete(idProjeto).then((response) => {
        this.doLoad();
        return response;
      });
    },
    getConsultores() {
      const resource = this.apiResource(`/v1/consultores`);
      resource.get({ query: `empresaId=${this.clientId}` }).then((result) => {
        return this.opts.consultoresGalapos = result.consultores;
      })
    },
  }
}
</script>

<style></style>