/**
 * Gera seed para geração de número aleatorio
 * @param {string} str semente original
 * @returns {[number, number, number, number]} quatro sementes necessárias para o método sfc32
 */
export const cyrb128 = str => {
  let h1 = 1779033703,
    h2 = 3144134277,
    h3 = 1013904242,
    h4 = 2773480762;
  for (let i = 0, k; i < str.length; i++) {
    k = str.charCodeAt(i);
    h1 = h2 ^ Math.imul(h1 ^ k, 597399067);
    h2 = h3 ^ Math.imul(h2 ^ k, 2869860233);
    h3 = h4 ^ Math.imul(h3 ^ k, 951274213);
    h4 = h1 ^ Math.imul(h4 ^ k, 2716044179);
  }
  h1 = Math.imul(h3 ^ (h1 >>> 18), 597399067);
  h2 = Math.imul(h4 ^ (h2 >>> 22), 2869860233);
  h3 = Math.imul(h1 ^ (h3 >>> 17), 951274213);
  h4 = Math.imul(h2 ^ (h4 >>> 19), 2716044179);
  (h1 ^= h2 ^ h3 ^ h4), (h2 ^= h1), (h3 ^= h1), (h4 ^= h1);
  return [h1 >>> 0, h2 >>> 0, h3 >>> 0, h4 >>> 0];
};

/**
 * Retorna método que gera números aleatórios e aceita quatro seeds de parametro
 * @param {string} a 
 * @param {string} b 
 * @param {string} c 
 * @param {string} d 
 * @returns {() => number}
 */
export const sfc32 = (a, b, c, d) => {
  return function() {
    a |= 0;
    b |= 0;
    c |= 0;
    d |= 0;
    let t = (((a + b) | 0) + d) | 0;
    d = (d + 1) | 0;
    a = b ^ (b >>> 9);
    b = (c + (c << 3)) | 0;
    c = (c << 21) | (c >>> 11);
    c = (c + t) | 0;
    return (t >>> 0) / 4294967296;
  };
};

/**
 * Retorna uma função que gera numéros "aleatórios" de uma sequencia pré determinada pela semente usada
 * @param {string | number} seed
 * @returns {() => number}
 */
export const generateRandomFn = (seed) => {
  if (!['string', 'number'].includes(typeof seed)) {
    throw new Error(`Seed inválida. Esperado string ou number, mas recebido ${typeof seed}.`);
  }

  const seeds = cyrb128(`${seed}`);
  return sfc32(...seeds);
};

export default generateRandomFn;
