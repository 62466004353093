<template>
  <MasterDetail
    refs="masterDetail"
    asyncExportType="nfepresumido"
    :canDelete="false"
    :canEdit="false"
    :cols="cols"
    formTitle="Nota Fiscal do Presumido"
    :hasExportCSV="false"
    :hasMonthlyFilter="true"
    :hasNewButton="false"
    :opts="opts"
    :resourceUrl="resourceUrl"
    :serverPagination="true"
  ></MasterDetail>
</template>

<script>
import MasterDetail from "@/components/master-detail.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    MasterDetail,
  },
  computed: {
    ...mapGetters(["clientId", "intervaloCompetencia"]),
    resourceUrl() {
      return `/v1/faturamento/nfe/${this.clientId}`;
    },
    cols() {
      return [
        {
          key: "num_doc",
          name: "Nº Doc",
          type: this.$fieldTypes.TEXT,
        },
        {
          key: "n_item",
          name: "Nº Item",
          type: this.$fieldTypes.TEXT,
        },
        {
          key: "cod_item",
          name: "Cód. Item",
          type: this.$fieldTypes.TEXT,
        },
        {
          key: "descr_compl",
          name: "Desc. Complementar",
          type: this.$fieldTypes.TEXT,
        },
        {
          key: "ncm",
          name: "NCM",
          type: this.$fieldTypes.TEXT,
        },
        {
          key: "cfop",
          name: "CFOP",
          type: this.$fieldTypes.TEXT,
        },
        {
          key: "vl_item",
          name: "Valor Item",
          type: this.$fieldTypes.TEXT,
        },
        {
          key: "cst_icms",
          name: "CST ICMS",
          type: this.$fieldTypes.TEXT,
        },
        {
          key: "vl_bc_icms",
          name: "Valor BC ICMS",
          type: this.$fieldTypes.TEXT,
        },
        {
          key: "aliq_icms",
          name: "Aliq. ICMS",
          type: this.$fieldTypes.TEXT,
        },
        {
          key: "vl_icms_op",
          name: "Valor ICMS OP",
          type: this.$fieldTypes.TEXT,
        },
        {
          key: "p_dif",
          name: "P DIF",
          type: this.$fieldTypes.TEXT,
        },
        {
          key: "vl_icms_dif",
          name: "Valor ICMS DIF",
          type: this.$fieldTypes.TEXT,
        },
        {
          key: "vl_icms",
          name: "Valor ICMS",
          type: this.$fieldTypes.TEXT,
        },
        {
          key: "vlr_oper",
          name: "Valor Oper.",
          type: this.$fieldTypes.TEXT,
        },
        {
          key: "credito_presumido",
          name: "Crédito Presumido",
          type: this.$fieldTypes.TEXT,
        },
        {
          key: "status",
          name: "Status de Cálculo",
          type: this.$fieldTypes.TEXT,
        },
        {
          key: "classificacao",
          name: "Classificação de CFOP",
          type: this.$fieldTypes.TEXT,
        },
      ];
    },
  },
  data() {
    return {
      opts: {},
    };
  },
};
</script>

<style lang="scss" scoped>
.table-v-action-button {
  display: inline-block;
}
</style>
