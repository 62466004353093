<template>
  <v-card class="teros-elevation" :color="tableColor">
    <v-card-title class="px-5 pb-0 text-uppercase v-title-cadastro">
      Resumo do Faturamento incentivado
    </v-card-title>
    <v-card-text>
      <div class="d-flex pt-1">
        <!-- TODO ocultado da tela até eventualmente existir uma exportação em PDF, provavelmente assíncrona -->
        <!-- <div class="table-v-action-button mr-3 pt-1" aria-label="imprimirButton" @click="print()">
          <v-icon>mdi-file</v-icon>Imprimir
        </div> -->
        <div class="table-v-action-button mr-3 pt-1" aria-label="atualizarButton" @click="doLoad()">
          <v-icon>mdi-refresh</v-icon>Atualizar
        </div>
        <monthly-filter style="width:260px" class="mr-3" v-model="competencia" aria-label="filtroMensal" @change="doLoad()"></monthly-filter>
      </div>
    </v-card-text>
    <v-data-table
      id="dashboard_ped_table"
      data-app
      :items="relatorioFormatado"
      :footer-props="{
        'items-per-page-options': [-1],
      }"
      hide-default-footer
      fixed-header
    >
      <template v-slot:header>
        <tr style="cursor: default" class="blue lighten-5">
          <th rowspan="2" style="padding-left: 1rem">Faturamento</th>
          <th
            colspan="2"
            style="text-align: center"
            v-for="competencia in intervaloCompetencias"
            :key="`competencia-${competencia}`"
          >
            {{ competencia | toMonth }}
          </th>
          <th colspan="2" style="text-align: center">TOTAL</th>
        </tr>
        <tr style="cursor: default" class="blue lighten-5">
          <template v-for="competencia in intervaloCompetencias">
            <th style="text-align: center" :key="`ppb-${competencia}`">PPB</th>
            <th style="text-align: center" :key="`tecnac-${competencia}`">TECNAC</th>
          </template>
          <th style="text-align: center" :key="`ppbTotal`">PPB</th>
          <th style="text-align: center" :key="`tecnacTotal`">TECNAC</th>
        </tr>
      </template>
      <template v-slot:item="{ item }">
        <tr
          class="table-v-tr"
          style="cursor: default"
          :class="{ 'font-weight-bold blue lighten-5': item.isTotal }"
        >
          <td
            v-for="col in colunas"
            v-bind:key="col.value"
            :class="{
              nowrap: col.nowrap,
              'text-end': col.align == 'end',
              'font-weight-bold': item.isTotal,
            }"
          >
            {{ item[col.value] | toCurrency }}
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-card-text class="pt-1">
      <v-row id="dashboard_ped_chart">
        <v-col>
          <div class="font-weight-bold text-uppercase mb-3">
            Faturamento Realizado PPB e Tecnac Acumulado
          </div>
          <div style="background: white; border-radius: 5px; padding: 20px">
            <div style="height: 280px; max-width: 280px; margin: 0px auto">
              <pie-chart
                :chartdata="faturamentoRealizadoAcumulado"
                :options="piechartoptions"
              ></pie-chart>
            </div>
          </div>
        </v-col>
        <v-col>
          <div class="font-weight-bold text-uppercase mb-3">
            Faturamento Liquido Realizado PPB e Tecnac
          </div>
          <div style="background: white; border-radius: 5px; padding: 20px">
            <div style="height: 280px; max-width: 280px; margin: 0px auto">
              <bar-chart
                style="height: 280px"
                :chartdata="faturamentoRealizadoTrimestral"
                :options="barChartOptions"
              ></bar-chart>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text class="pt-0 pl-5 mb-n5">
      <v-row id="dashboard_ped_chart">
        <v-col cols="4">
          <div class="font-weight-bold" style="color:var(--red);">
            {{ mensagemInvestimento }}
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from "moment";
import { mapGetters } from 'vuex';

export default {
  components: {
    "bar-chart": () => import("@/components/bar-chart.vue"),
    "monthly-filter": () => import("@/components/monthly-filter.vue"),
    "pie-chart": () => import("@/components/pie-chart.vue"),
  },
  computed: {
    ...mapGetters(['clientId']),
    colunas: function () {
      const faturamento =[{
        text: "Faturamento",
        value: "faturamento",
        nowrap: true,
        sortable: false,
      }];
      const totais = [
        {
          text: "PPB Total",
          value: "ppbTotal",
          nowrap: true,
          sortable: false,
        },
        {
          text: "TECNAC Total",
          value: "tecnacTotal",
          nowrap: true,
          sortable: false,
        },
      ];
      const competencias = this.intervaloCompetencias.reduce((acc, comp) => [
        ...acc,
        {
          text: `PPB ${this.$options.filters.toMonth(comp)}`,
          value: `${comp}-ppb`,
          nowrap: true,
          sortable: false,
        },
        {
          text: `TECNAC ${this.$options.filters.toMonth(comp)}`,
          value: `${comp}-tecnac`,
          nowrap: true,
          sortable: false,
        },
      ], []);

      return faturamento.concat(competencias).concat(totais);
    },
    mensagemInvestimento: function () {
      // TODO este alerta foi descontinuado??
      // const findedItem = this.relatorio.projetado.find((item) => 'alerta' in item);
      // return findedItem ? findedItem.alerta : '';
      return '';
    },
    faturamentoRealizadoAcumulado: function () {
      const [{ ppbTotal, tecnacTotal }] = this.relatorioFormatado.filter(
        ({ faturamento }) => faturamento === 'Faturamento Liquido'
      );
      return {
        datasets: [
          {
            data: [ppbTotal, tecnacTotal],
            backgroundColor: this.$chartColors,
            label: "Pizza",
          },
        ],
        labels: ["PPB", "TECNAC"],
      };
    },
    faturamentoRealizadoTrimestral: function () {
      return {
        labels: this.intervaloCompetencias.map((competencia) => this.$options.filters.toMonth(competencia)),
        datasets: [
          {
            backgroundColor: this.$chartColors[0],
            borderColor: this.$chartColors[0],
            data: this.intervaloCompetencias.map((competencia) => this.parsedRelatorio[competencia].ppb.fat_liquido),
            hidden: false,
            label: "PPB",
          },
          {
            backgroundColor: this.$chartColors[1],
            borderColor: this.$chartColors[1],
            data: this.intervaloCompetencias.map((competencia) => this.parsedRelatorio[competencia].tecnac.fat_liquido),
            hidden: false,
            label: "TECNAC",
          },
        ],
      };
    },
    intervaloCompetencias: function () {
      let [ini, fim] = this.competencia;
      [ini, fim] = [moment(ini, "YYYY-MM"), moment(fim, "YYYY-MM")];
      let length = fim.diff(ini, 'month') + 1;
      length = length < 1 ? 1 : length;
      
      if (!ini.isValid() || !fim.isValid()) {
        return [];
      }

      return new Array(length).fill('').map((_, index) => moment(ini).add(index, 'month').format("YYYY-MM"));
    },
    resource: function () {
      return this.apiResource(
        `/v1/faturamento/resumogeral/${this.clientId}`
      );
    },
    relRows: function () {
      return [
        {
          key: "fat_bruto",
          label: "Faturamento Bruto",
        },
        {
          key: "deducao_ipi",
          label: "Dedução IPI",
        },
        {
          key: "deducao_icmsst",
          label: "Dedução ICMS ST",
        },
        {
          key: "devolucoes",
          label: "Dedução Devoluções Liquidas (IPI e ICMS ST)",
        },
        {
          key: "fat_liquido",
          label: "Faturamento Liquido",
          isTotal: true
        },
      ];
    },
    relatorioFormatado: function () {
      const rows = this.relRows.reduce(
        (acc, { key, label, isTotal }) => ({
          ...acc,
          [key]: {
            faturamento: label,
            isTotal,
            ppbTotal: 0,
            tecnacTotal: 0,
          },
        }),
        {}
      );

      Object
        .values(this.parsedRelatorio)
        .forEach((comp) => Object
          .values(comp)
          .forEach(
            ({
              incentivo,
              competencia,
              fat_bruto,
              deducao_ipi,
              deducao_icmsst,
              devolucoes,
              fat_liquido,
            }) => {
              if (moment(competencia, 'YYYY-MM').isValid() && incentivo) {
                const incetivoNome = incentivo === "PPB" ? "ppb" : "tecnac";
                const key = `${competencia}-${incetivoNome}`;
                const totalKey = `${incetivoNome}Total`;

                rows.fat_bruto[key] = fat_bruto;
                rows.deducao_ipi[key] = deducao_ipi;
                rows.deducao_icmsst[key] = deducao_icmsst;
                rows.devolucoes[key] = devolucoes;
                rows.fat_liquido[key] = fat_liquido;
                
                rows.fat_bruto[totalKey] += fat_bruto;
                rows.deducao_ipi[totalKey] += deducao_ipi;
                rows.deducao_icmsst[totalKey] += deducao_icmsst;
                rows.devolucoes[totalKey] += devolucoes;
                rows.fat_liquido[totalKey] += fat_liquido;
              }
            }
          )
        );

      return Object.values(rows);
    },
    parsedRelatorio: function () {
      return this.intervaloCompetencias.reduce((acc, competencia) => {
        const [ppb, tecnac] = ['ppb', 'tecnac'].map((incentivo) => {
          const exist = (this.relatorio && this.relatorio[competencia] && this.relatorio[competencia][incentivo]) ? this.relatorio[competencia][incentivo] : {};
          return {
            competencia,
            incentivo: incentivo === 'ppb' ? 'PPB' : 'TecNac',
            fat_bruto: 0,
            deducao_ipi: 0,
            deducao_icmsst: 0,
            devolucoes: 0,
            fat_liquido: 0,
            ...exist,
          }
        });
        return {
          ...acc,
          [competencia]: { ppb, tecnac },
        };
      }, {});
    },
    options: function () {
      return {
        responsive: true,
        maintainAspectRatio: false,
        spanGaps: true,
        elements: { line: { tension: 0.000001 } },
        tooltips: {
          callbacks: {
            label: (tooltipItem) => {
              return this.$options.filters.toCurrency(tooltipItem.value);
            },
          },
        },
        scales: {
          yAxes: [
            {
              stacked: false,
              ticks: {
                callback: (value) => {
                  return this.abbrNumber(value);
                },
              },
            },
          ],
        },
        plugins: {
          filler: { propagate: false },
          "samples-filler-analyser": { target: "chart-analyser" },
        },
      };
    },
    barChartOptions: function () {
      return {
        responsive: true,
        maintainAspectRatio: false,
        spanGaps: true,
        elements: { line: { tension: 0.000001 } },
        tooltips: {
          callbacks: {
            label: (tooltipItem) => {
              return this.$options.filters.toCurrency(tooltipItem.value);
            },
          },
        },
        scales: {
          yAxes: [
            {
              stacked: true,
              ticks: {
                callback: (value) => {
                  return this.abbrNumber(value);
                },
              },
            },
          ],
          xAxes: [
            {
              stacked: true,
              ticks: {
                callback: (value) => {
                  return this.abbrNumber(value);
                },
              },
            },
          ],
        },
        plugins: {
          filler: { propagate: false },
          "samples-filler-analyser": { target: "chart-analyser" },
        },
      };
    },
    piechartoptions: function () {
      return {
        responsive: true,
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => {
              return this.$options.filters.toCurrency(
                data.datasets[0].data[tooltipItem.index]
              );
            },
          },
        },
      };
    },
  },
  methods: {
    doLoad: function () {
      if (!this.competencia?.length) {
        return;
      }

      const [ini, fim] = this.competencia;
      const query = `competenciaIni=${ini}&competenciaFim=${fim}`;

      this.resource
        .get({
          query,
        })
        .then((response) => {
          if (!response.error) {
            this.relatorio = response;
          }
        });
    },
    print: function () {
      this.htmltoPDF(
        [
          document.getElementById("dashboard_ped_table"),
          document.getElementById("dashboard_ped_chart"),
        ],
        "resumo_geral_li",
        1050
      );
    },
  },
  data: function () {
    return {
      competencia: [],
      relatorio: {},
    };
  },
  watch: {
    clientId: function () {
      this.doLoad();
    },
  },
};
</script>
