<template>
    <MasterDetail
      ref="masterDetail"
      :hasExportCSV="false"
      :hasExportXLSX="false"
      :opts="opts"
      :cols="cols"
      :canEdit="false"
      :canDelete="false"
      :resourceUrl="resourceUrl">
    </MasterDetail>
</template>

<script>
import MasterDetail from '@/components/master-detail.vue';
import { mapGetters } from 'vuex';

export default {
    // props: {},
    components: {
        MasterDetail,
    },
    // mixins: [],
    // directives: {},
     data() {
         return {
           opts: {
             statusRubrica: [
               {
                 id: 1,
                 value: 'Ativo'
               },
               {
                 id: 0,
                 value: 'Inativo'
               },
             ],
            },
         }
     },
    computed: {
      ...mapGetters(['clientId']),
      cols() {
        return [
          {
            key: 'id',
            name: 'Código',
            hideInform: true,
            hideInTable: true,
            width: '50px',
          },
          {
            key: 'nome',
            name: 'Nome',
            type: this.$fieldTypes.TEXT,
            rules: [{ rule: 'required' }],
          },
          {
            key: 'substituto',
            name: 'Substituto',
            type: this.$fieldTypes.TEXT,
            rules: [{ rule: 'required' }],
          },
          {
            key: 'data_inicio',
            name: 'Data de início',
            type: this.$fieldTypes.TEXT,
          },
          {
            key: 'data_fim',
            name: 'Data de término',
            type: this.$fieldTypes.TEXT,
          },
        ]
      },
      resourceUrl() {
        return `/v1/projetos/${this.clientId}/analise`;
      },
    },
    // filters: {},
    // created() {},
    // mounted() {},
    // updated() {},
    // destroyed() {},
     methods: {
     },
    // watch: {},
}
</script>

<style>

</style>
