<template>
  <v-card class="teros-elevation mt-2" :color="!disabled ? tableColor : ''">
    <MasterDetail
      :cols="cols"
      :opts="opts"
      :customResource="customResource"
      :hasExportCSV="false"
      :hasExportXLS="false"
      :hasNewButton="!disabled"
      :canEdit="!disabled"
      :canDelete="!disabled"
      :lastRowData="lastRowData"
      :disablePagination="true"
      @onOpenFormDialog="openForm()"
      @update:numero_parcela="numeroParcelaUpdate($event)"
    >
    </MasterDetail>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    disabled: { type: Boolean, default: false }
  },
  components: {
    MasterDetail: () => import("@/components/master-detail.vue")
  },
  computed: {
    ...mapGetters(["clientId"]),
    projetoId() {
      return this.$route.params.id;
    },
    cols() {
      return [
        {
          key: "id",
          name: "Codigo",
          type: this.$fieldTypes.TEXT,
          hideInTable: true,
          hideInform: true
        },
        {
          key: "rubrica_id",
          name: "Rubrica",
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: "rubricasSelecao", key: "id", name: "tipo" },
          colSize: 12
        },
        {
          key: "valor",
          name: "Valor",
          type: this.$fieldTypes.MONEY,
          colSize: 6
        },
        {
          key: "contrapartida",
          name: "Contrapartida",
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: "contrapartida", key: "id", name: "nome" },
          defaultValue: "Nao",
          colSize: 6
        },
        {
          key: "versao",
          name: "Versao",
          type: this.$fieldTypes.TEXT,
          hideInTable: true,
          hideInform: true
        }
      ];
    },
    resourceUrl() {
      return this.apiResource(
        `v1/captacao/${this.clientId}/orcamentos/${this.projetoId}/detalhado`
      );
    },
    customResource() {
      const resource = this.resourceUrl;
      const that = this;
      return {
        ...resource,
        get(...args) {
          return resource.get(...args).then(result => {
            that.opts.rubricasSelecao = result.rubricasPareadas;
            that.opts.parcelas = result.parcelas;
            that.lastRowData = result.orcamentos.reduce(
              (acc, curr) => ({
                ...acc,
                valor:
                  Number.parseFloat(acc.valor) + Number.parseFloat(curr.valor)
              }),
              {
                tipo: "Total",
                valor: 0
              }
            );
            return result.orcamentos;
          });
        },
        save(...args) {
          let dados = args[0];
          dados["empresa_id"] = that.clientId;
          dados["projeto_id"] = that.projetoId;

          // if(dados.descricao_id !== null) {
          //     dados['finalidade'] = that.opts.descricoes.filter((desc) => desc.id == dados.descricao_id)[0].finalidade;
          // }

          return resource.save(dados, dados.id).then(result => {
            return result;
          });
        }
      };
    }
  },
  data() {
    return {
      opts: {
        rubricas: [],
        rubricasSelecao: [],
        descricoes: [],
        contrapartida: [
          {
            id: "Nao",
            nome: "Não"
          },
          {
            id: "Sim",
            nome: "Sim"
          }
        ],
        parcelas: []
      },
      lastRowData: {}
    };
  },
  created() {
    this.getRubricas();
  },
  methods: {
    getRubricas() {
      this.apiResource(
        `/v1/captacao/${this.clientId}/rubricas/pareamento/${this.projetoId}`
      )
        .get()
        .then(result => {
          this.opts.rubricas = result.rubricas;
          this.opts.descricoes = result.pareamentos;
          return result;
        });
    },
    openForm() {
      const resource = this.resourceUrl;
      const that = this;
      resource.get().then(result => {
        that.opts.rubricasSelecao = result.rubricasPareadas;
        that.opts.parcelas = result.parcelas;
        return result.orcamentos;
      });
    }
  }
};
</script>
