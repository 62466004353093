import moment from "moment";

/**
 * @param {string[]|string} payload
 * @param {string} [format]
 * @return {[string, string]|[]}
 */
const getValidDatesTuple = (payload, format = 'YYYY-MM') => {
  if (typeof payload === 'string') {
    if (!moment(payload, format).isValid()) {
      console.error(`${payload} não é um valor aceito para competencia.`);
      return [];
    }

    return [payload, payload];
  } else if (Array.isArray(payload)) {
    let dates = payload
      .map((date) => moment(date, format))
      .filter((date) => date.isValid())
      .sort((a, b) => b.isAfter(a) ? -1 : 1)
      .map((date) => date.format(format));

    if (dates.length === 0 || dates.length > 2) {
      console.error(`Intervalo com quantidade inválida de datas. Esperava 2 datas e recebeu ${dates.length}.`);
      return [];
    }

    return dates;
  }

  return [];
}

export default getValidDatesTuple;
