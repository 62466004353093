<template>
  <div>
    <MasterDetail
      :actionBarButtons="actionBarButtons"
      :canEdit="false"
      :canDelete="false"
      :cols="cols"
      descriptionProperty="titulo"
      disableContextMenu
      expandContextMenu
      expandSubItem
      :hasNewButton="false"
      :hasExportCSV="false"
      :hasYearFilter="hasYearFilter"
      :opts="opts"
      ref="masterDetail"
      :customResource="customResource"
      :selection.sync="selection"
      :selectionCol="canGroup"
      :serverPagination="true"
      @contextmenu="contextMenu"
    >
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <div class="table-v-action-button mr-3" v-bind="attrs" v-on="on">
          <v-icon>mdi-file-delimited-outline</v-icon>
          Exportações Assíncronas
        </div>
      </template>
      <v-list>
        <v-list-item>
          <v-list-item-title
            class="table-v-action-button"
            @click="exportCsv(false)"
          >
            <v-icon>mdi-file-delimited</v-icon>
            para conferência
          </v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-title
            class="table-v-action-button"
            @click="exportCsv(true)"
          >
            <v-icon>mdi-file-arrow-up-down</v-icon>
            para alteração
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-dialog
      v-model="exportacaoAsyncDialog"
      scrollable
      persistent
      max-width="400"
    >
      <v-card>
        <v-card-title style="display: block" class="pb-1">
          <v-icon x-large class="mr-3">mdi-file-delimited-outline</v-icon>
          Exportar Relatório
          <v-btn
            @click="exportacaoAsyncDialog = false"
            style="position: absolute; right: 5px; top: 5px"
            icon
          >
            <v-icon>mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <p>Requisição entrou na fila para processamento.</p>
          <p>Para ver o progresso e baixar o relatório, veja a fila.</p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="px-6 pb-3 pt-0">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="pr-5"
            dark
            depressed
            @click="exportacaoAsyncDialog = false"
          >
            <v-icon left>mdi-chevron-left</v-icon> Fechar
          </v-btn>
          <v-btn
            color="primary"
            dark
            depressed
            class="px-5 ml-3"
            @click="verFila()"
          >
            Ver fila
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </MasterDetail>

    <g-modal
      ref="confirmDesmantleModal"
      appendTitleIcon="mdi-help-box-outline"
      title="Confirmar desagrupamento"
    >
      <p>Deseja desfazer o agrupamento {{ selectedRow.titulo || "selecionado" }}?</p>
      <p>Os projetos do agrupamento voltarão a aparecer na listagem de projetos.</p>
      <template v-slot:buttons="{ close }">
        <v-spacer />
        <v-btn
          class="px-3"
          color="secondary"
          dark
          depressed
          @click="close(false)"
        >
          Cancelar
        </v-btn>
        <v-btn
          class="px-3"
          color="primary"
          dark
          depressed
          @click="close(true)"
        >
          Continuar
        </v-btn>
      </template>
    </g-modal>

    <g-modal
      ref="confirmDeleteModal"
      appendTitleIcon="mdi-help-box-outline"
      title="Confirmar exclusão do agrupamento"
    >
      <p>Deseja excluir o agrupamento? Os dados do descritivo de projetos do agrupamento serão perdidos.</p>
      <p>Essa ação não pode ser desfeita.</p>
      <template v-slot:buttons="{ close }">
        <v-spacer />
        <v-btn
          class="px-3"
          color="secondary"
          dark
          depressed
          @click="close(null)"
        >
          Cancelar
        </v-btn>
        <v-btn
          class="px-3"
          color="primary"
          dark
          depressed
          @click="close(false)"
        >
          Não
        </v-btn>
        <v-btn
          class="px-3"
          color="primary"
          dark
          depressed
          @click="close(true)"
        >
          Sim
        </v-btn>
      </template>
    </g-modal>

    <form-modal
      :cols="agrupamentoFields"
      :errorMessage="errorMessage"
      :opened.sync="agruparModalOpened"
      :opts="opts"
      title="Criar agrupamento"
      :value.sync="agruparModalData"
      @save="saveAgrupamento"
    ></form-modal>

    <form-modal
      :cols="editarAgrupamentoFields"
      :errorMessage="errorMessage"
      :opened.sync="editarAgrupamentoModalOpened"
      :opts="opts"
      title="Alterar projetos agrupados"
      :value.sync="editarAgrupamentoModalData"
      @save="saveAgrupamento"
    ></form-modal>

    <form-modal
      :cols="plurianualField"
      :maxWidth="plurianualDialogWidth"
      :opened.sync="plurianualDialog"
      :opts="opts"
      :title="plurianualTitle"
      :value.sync="plurianualData"
      @save="gerarProximoAnoBase($event)"
    ></form-modal>

    <form-modal
      :cols="statusCols"
      :opened.sync="statusModalOpened"
      :opts="opts"
      title="Alterar Status"
      ref="modalStatusRef"
      :value.sync="statusModalData"
      @save="saveStatusProjeto"
    ></form-modal>

    <v-dialog v-model="deParaDialog" persistent max-width="500">
      <v-card>
        <v-card-title style="display: block" class="pb-1">
          <v-icon x-large class="mr-3">mdi-source-branch-plus mdi-rotate-270</v-icon>De > Para
          <v-btn
            icon
            style="position: absolute; right: 5px; top: 5px"
            @click="deParaDialog = false"
          >
            <v-icon>mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="mt-2">
          Esta ação irá mover todos os dados de:
          <br />
          <span
            v-if="depara.de"
            class="font-weight-medium"
          >
            {{ depara.de.id_projeto }} - {{ depara.de.titulo }}
          </span>
          <br />para:
          <input-v
            v-model="depara.para"
            :valid.sync="deParaField.valid"
            :label="deParaField"
            :hideDetails="true"
          ></input-v>
          <span
            class="font-weight-medium red--text text--lighten-1"
          >
            Cuidado! Esta ação não pode ser desfeita!
          </span>
          <br />
          <span
            v-if="depara.de && !depara.de.data_inicio"
            class="font-weight-medium red--text text--lighten-1"
          >
            {{ depara.de.id_projeto }} - {{ depara.de.titulo }} não possui uma data de inicio válida. Projeto alvo terá data inicial inválida.
          </span>
          <br />
          <div v-if="depara.de && depara.para" class="d-flex align-center justify-content-between">
            <div class="d-flex flex-column">
              <div><b>Data início:</b> {{ depara.de.data_inicio | toDate }}</div>
              <div><b>Data fim:</b> {{ depara.de.data_fim | toDate }}</div>
              <div><b>Serviço:</b> {{ getServico(depara.de.trabalho) }}</div>
            </div>
            <div>
              <i class="fas fa-angle-double-right"></i>
            </div>
            <div class="d-flex flex-column">
              <div><b>Data início:</b> {{ selectedPara.data_inicio | toDate }}</div>
              <div><b>Data fim:</b> {{ selectedPara.data_fim | toDate }}</div>
              <div><b>Serviço:</b> {{ getServico(selectedPara.trabalho) }}</div>
            </div>
          </div>
          <v-alert
            v-if="deParaError"
            dense
            style="font-size: 13px"
            text
            class="mb-0 mt-4"
            type="error"
          >
            {{ deParaError }}
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" dark depressed @click="deParaDialog = false">Cancelar</v-btn>
          <v-btn
            color="primary"
            dark
            depressed
            @click="executarDePara()"
          >
            Executar De > Para
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <form-modal
      :cols="modalFields"
      class="justificativa"
      id="justificativa"
      maxWidth="60%"
      :opened.sync="justificativaModalOpen"
      :opts="opts"
      :title="`Justificativa de enquadramento para o projeto: ${tituloProjetoSelecionado}`"
      :value.sync="justificativaModalData"
      @save="salvarJustificativaEnquadramento"
      >
    </form-modal>

    <v-dialog v-model="userJustify" max-width="500">
      <v-card class="teros-elevation">
        <v-card-title class="border">
          <div class="d-flex align-center">
            <v-icon class="mr-3">mdi-square-edit-outline</v-icon>
            <span>Justificativa de enquadramento</span>
          </div>
        </v-card-title>
        <v-card-text class="mt-3">
          <div v-if="justificativaModalData.justificativa">
            <span class="pt-2 pl-1">{{ justificativaModalData.justificativa }}</span>
          </div>
          <span class="pt-2 pl-1" v-if="!justificativaModalData.justificativa">Não há justificativa cadastrada para esse projeto.</span>
        </v-card-text>
        <v-card-actions class="border d-flex justify-center">
          <v-btn color="primary"
          dark
          depressed
          class="px-5"
          @click="userJustify = false">Fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <g-modal ref="shouldChangeGroupDate" title="Ajustar data">
      <v-row>
        <v-col>
          <p class="should-change-group-date">Deseja ajustar as datas do Agrupamento?</p>
        </v-col>
        <input-v
          v-model="changeDateData.prevStart"
          :colSize="6"
          :editable="false"
          :label="changeDateFields.prevStart"
          :opts="opts"
        ></input-v>
        <input-v
          v-model="changeDateData.prevEnd"
          :colSize="6"
          :editable="false"
          :label="changeDateFields.prevEnd"
          :opts="opts"
        ></input-v>
        <input-v
          v-model="changeDateData.nextStart"
          :colSize="6"
          :label="changeDateFields.nextStart"
          :opts="opts"
        ></input-v>
        <input-v
          v-model="changeDateData.nextEnd"
          :colSize="6"
          :label="changeDateFields.nextEnd"
          :opts="opts"
        ></input-v>
      </v-row>

      <template v-slot:buttons="{ close }">
        <v-spacer />
        <v-btn
          class="px-3 should-change-group-date"
          color="secondary"
          dark
          depressed
          @click="close(null)"
        >
          Cancelar
        </v-btn>
        <v-btn
          class="px-3 should-change-group-date"
          color="secondary"
          dark
          depressed
          @click="close(false)"
        >
          Não
        </v-btn>
        <v-btn
          class="px-3 should-change-group-date"
          color="primary"
          dark
          depressed
          @click="close(true)"
        >
          Sim
        </v-btn>
        <v-spacer />
      </template>
    </g-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import { BeneficiosEnum, BeneficiosLabels } from '@/core/enums/beneficios';
import { projectsOptions } from "@/helpers/yearsOptions";
import { AnyClient } from '@/core/enums/user-types';

export default {
  components: {
    "g-modal": () => import("@/components/g-modal.vue"),
    "form-modal": () => import("@/components/form-modal.vue"),
    "input-v": () => import("@/components/input-v.vue"),
    MasterDetail: () => import("@/components/master-detail.vue"),
  },
  created: function () {
    if (this.clientId) {
      this.resourceProjetosSelecao.get().then((response) => {
        this.projetos = response.projetos;
      });
    }
  },
  computed: {
    ...mapGetters(["anoBase", "clientId", "user"]),
    actionBarButtons: function () {
      const buttons = [
        {
          text: "Criar Agrupamento",
          icon: "mdi-file-document-multiple-outline",
          show: this.userHasAccess('Projetos.agrupamentos.create') && this.selection.filter(({ agrupamento }) => !agrupamento).length > 0,
          action: this.criarAgrupamento,
        },
        {
          text: "Alterar Status",
          icon: 'mdi-update',
          show: this.selection.length && this.userHasAccess('Projetos.agrupamentos.create'),
          action: () => {
            this.alterarStatusProjetos();
          },
        }
      ];

      return buttons.filter(({ show }) => show);
    },
    agrupamentoFields: function () {
      const fields = [
        {
          key: "titulo",
          name: "Nome do Novo Agrupamento",
          placeholder: "Insira o nome do novo agrupamento",
          type: this.$fieldTypes.TEXT,
          rules: [{ rule: "min", params: { size: 3 } }],
          show: true,
        },
        {
          key: "data_inicio",
          name: "Data Inicial",
          type: this.$fieldTypes.DATE,
          colSize: 6,
          show: true,
        },
        {
          key: "data_fim",
          name: "Previsão de Término",
          type: this.$fieldTypes.DATE,
          colSize: 6,
          show: true,
        },
        {
          key: "alert",
          name: "Agrupamentos selecionados serão ignorados. Só é possivel agrupar projetos.",
          type: this.$fieldTypes.SUBTITLE,
          show: this.selection.some(({ agrupamento }) => agrupamento),
        },
      ];

      return fields.filter(({ show }) => show);
    },
    canGroup: function () {
      return this.userHasAccess('Projetos.agrupamentos.create');
    },
    editarAgrupamentoFields() {
      const agrupamentosSelecionados = this.selection.filter(({ agrupamento }) => agrupamento);
      const agrupamentoSelecionado = this.selection.find(({ id }) => id === this.editarAgrupamentoModalData.id);
      const opcoesProjetos = this.projetosSelecao.filter(({ agrupamento_id }) => !agrupamento_id || agrupamento_id === this.editarAgrupamentoModalData.id);
      const projetosAgrupados = agrupamentoSelecionado?.projetos_agrupados?.map(({ id }) => id) ?? [];
      const projetosModal = this.editarAgrupamentoModalData.projetos ?? [];
      const datesChanges = this.isGroupChanged(projetosAgrupados, projetosModal);
      const setModalDates = this.setModalDates;
      const fields = [
        {
          key: "titulo",
          name: "Agrupamento",
          type: this.$fieldTypes.TEXT,
          editable: false,
          show: agrupamentosSelecionados.length < 2,
        },
        {
          key: "id",
          name: "Agrupamento",
          placeholder: "Selecione um dos agrupamentos selecionados",
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { toEdit: agrupamentosSelecionados, key: "id", name: "titulo" },
          rules: [{ rule: "required" }],
          show: agrupamentosSelecionados.length > 1,
        },
        {
          key: "projetos",
          name: "Projetos pertencentes ao agrupamento",
          type: this.$fieldTypes.AUTOCOMPLETE_MULTIPLE,
          rel: { toEdit: opcoesProjetos, key: "id", name: "titulo" },
          rules: [{ rule: "required" }],
          show: true,
          valueChanged: (ids) => setModalDates(agrupamentoSelecionado, ids),
        },
        {
          key: "prevStart",
          name: "Data Inicial Anterior",
          type: this.$fieldTypes.DATE,
          editable: false,
          colSize: 6,
          show: datesChanges,
        },
        {
          key: "prevEnd",
          name: "Previsão de Término Anterior",
          type: this.$fieldTypes.DATE,
          editable: false,
          colSize: 6,
          show: datesChanges,
        },
        {
          key: "data_inicio",
          name: "Data Inicial",
          type: this.$fieldTypes.DATE,
          colSize: 6,
          show: datesChanges,
        },
        {
          key: "data_fim",
          name: "Previsão de Término",
          type: this.$fieldTypes.DATE,
          colSize: 6,
          show: datesChanges,
        },
      ];

      return fields.filter(({ show }) => show);
    },
    isClient: function () {
      return this.getClient().isClient;
    },
    hasYearFilter: function () {
      return { max: moment().add(2, 'year').get('year') };
    },
    customResource: function () {
      const resource = this.apiResource(`/v1/projetos/${this.clientId}/enquadrados`);
      return {
        ...resource,
        get(...args) {
          return resource.get(...args).then((response) => {
            const data = response.data.map((row) => !row.agrupamento ? row : [row, row.projetos_agrupados || []]);
            return {
              ...response,
              data,
            };
          });
        }
      };
    },
    deParaField: function () {
      return {
        key: "id",
        name: "Projeto",
        type: this.$fieldTypes.AUTOCOMPLETE,
        rel: {
          toEdit: this.projetosSelecao.filter(({ id }) => id !== this.depara.de?.id),
          key: "id",
          name: "titulo",
          nameSpacer: " - ",
        },
      };
    },
    plurianualField: function () {
      return [
        {
          key: "ano_base",
          name: "Gerar para o ano:",
          type: this.$fieldTypes.SELECT,
          rules: [{ rule: 'required' }],
          rel: { toEdit: projectsOptions, id: "value", name: "text" },
          show: true,
          colSize: this.plurianualData?.agrupamento ? 4 : 12,
        },
        {
          key: "projetos_agrupados",
          name: "Projetos desse agrupamento que serão replicadas junto do agrupamento",
          placeholder: "Nenhum projeto selecionado, agrupamento sera replicado vazio",
          type: this.$fieldTypes.AUTOCOMPLETE_MULTIPLE,
          rel: { to: this.projetosAgrupados, key: "id", name: "titulo" },
          show: !!this.plurianualData?.agrupamento,
          colSize: 12,
        },
        {
          key: "ano_base",
          name: "Projeto será gerado no ano base desvinculado de qualquer agrupamento.",
          type: this.$fieldTypes.SUBTITLE,
          show: !!this.plurianualData?.projetoAgrupado,
        },
      ].filter(({ show }) => show);
    },
    plurianualDialogWidth: function () {
      return this.plurianualData?.agrupamento ? '700px' : '400px';
    },
    plurianualTitle: function () {
      return `Gerar outro Ano Base${this.plurianualData ? ` para ${this.plurianualData.titulo}` : ''}`;
    },
    resourceAgrupamento: function () {
      return this.apiResource(`/v1/projetos/${this.clientId}/agrupamento`);
    },
    resourceProjetosSelecao: function () {
      return this.apiResource(`/v1/projetos/${this.clientId}/selecao`);
    },
    client: function () {
      return this.getClient();
    },
    selectedPara: function () {
      if (!this.depara.para) {
        return {};
      }
      return this.projetosSelecao.find(({ id }) => id === this.depara.para);
    },
    isUser: function () {
      return AnyClient.includes(this.user.tipo_usuario)
    },
    resourceProjetos: function () {
      return this.apiResource(`/v1/projetos/${this.clientId}/projeto`);
    },
    modalFields: function() {
      return [{
          key: "justificativa",
          name: "Justificativa",
          type: this.$fieldTypes.TEXTAREA,
          editable: !this.isUser,
          rules: [{ rule: "max", params: { size: 2000 } }]
      }]
    },
  },
  methods: {
    contextMenu: function (v, e, expanded) {
      const opts = [
        {
          name: `${this.isClient ? "Preencher" : "Ver"}  descritivo`,
          limit: 1,
          show: this.userHasAccessToAction('update'),
          cb: (row) => {
            this.$router.push({
              name: "ficha-projeto-enquadrado",
              params: { projetoId: row.id },
            });
          },
        },
        {
          name: "Histórico de alterações",
          limit: 1,
          show: this.userHasAccessToAction('update'),  // TODO por a permissão correta
          cb: (row) => {
            this.$router.push({
              name: "rastreio-projeto",
              params: { projetoId: row.id },
              query: { agrupamento: !!row.agrupamento },
            });
          },
        },
        {
          name: `Gerar Ano Base do ${v.agrupamento ? "Agrupamento" : "Projeto"}`,
          limit: 1,
          show: this.userHasAccessToAction('create') || this.userHasAccessToAction('plurianual'),
          cb: async (row) => {
            this.projetosAgrupados = row.agrupamento ? row.projetos_agrupados : [];
            this.plurianualData = {
              agrupamento: row.agrupamento,
              titulo: row.titulo,
              ano_base: row.data_fim,
              data_fim: row.data_fim,
              id: row.id,
              projetos_agrupados: row.agrupamento ? row.projetos_agrupados.map(({ id }) => id) : undefined,
            };
            this.plurianualDialog = true;
          },
        },
        {
          name: "De > Para",
          limit: 1,
          show: !v.agrupamento && this.userHasAccessToAction('depara'),
          cb: async (row) => {
            try {
              await this.getProjetosSelecao();
              this.depara.de = { ...row };
              this.depara.para = null;
              this.deParaError = null;
              this.deParaDialog = true;
            } catch (e) {
              this.deParaDialog = false;
            }
          },
        },
        {
          name: "Criar agrupamento",
          limit: 1,
          show: !v.agrupamento && this.selection.filter(({ agrupamento }) => !agrupamento).length > 0 && this.userHasAccess('Projetos.agrupamentos.create'),
          cb: this.criarAgrupamento,
        },
        {
          name: "Alterar projetos agrupados",
          limit: 1,
          show: v.agrupamento && this.userHasAccess('Projetos.agrupamentos.create'),
          cb: async (row) => {
            try {
              await this.getProjetosSelecao();
              const { data_inicio, data_fim, projetos_agrupados } = row;
              const projetosAgrupados = projetos_agrupados.map(({ id }) => id);
              this.editarAgrupamentoModalData = {
                id: row.id,
                titulo: row.titulo,
                projetos: projetosAgrupados,
                prevEnd: data_fim,
                prevStart: data_inicio,
                agrupamentoId: row.id,
              };

              if (row && !this.selection.includes(row)) {
                this.selection.push(row);
              }

              const projetosNaoAgrupados = this.selection.filter(({ agrupamento, id }) => !agrupamento && !projetosAgrupados.includes(id)).map(({ id }) => id);
              this.editarAgrupamentoModalData.projetos.push(...projetosNaoAgrupados);

              if (this.isGroupChanged([row.id], projetosNaoAgrupados)) {
                this.setModalDates(row, projetosNaoAgrupados);
              }

              this.editarAgrupamentoModalOpened = true;
            } catch (e) {
              this.editarAgrupamentoModalOpened = false;
            }
          },
        },
        {
          name: "Desagrupar projetos",
          limit: 1,
          show: v.agrupamento && v.projetos_agrupados?.length > 0 && this.userHasAccess('Projetos.agrupamentos.create'),
          cb: async (row) => {
            this.selectedRow = row;
            const shouldSave = await this.$refs.confirmDesmantleModal.asyncOpen();

            if (!shouldSave) {
              return;
            }

            const shouldDelete = await this.$refs.confirmDeleteModal.asyncOpen();

            if (shouldDelete === null) {
              return;
            }

            const body = { id: row.id, titulo: row.titulo, projetos: [] };
            await this.saveAgrupamento(body, null, shouldDelete);

            if (shouldDelete) {
              await this.deleteAgrupamento(row.id);
            }
          },
        },
        {
          name: "Apagar agrupamento",
          limit: 1,
          show: v.agrupamento && v.projetos_agrupados?.length === 0 && this.userHasAccess('Projetos.agrupamentos.create'),
          cb: (row) => {
            const title = "Confirmar exclusão do agrupamento";
            const msg = `
              Deseja excluir o agrupamento ${row.titulo || "selecionado"}?<br/>
              Os dados do descritivo de projetos do agrupamento serão perdidos.<br/><br/>
              Essa ação não pode ser desfeita.
            `;
            const cb = () => this.deleteAgrupamento(row.id);
            this.showConfirm(title, msg, cb);
          },
        },
        {
          name: "Alterar status",
          limit: 1,
          show: this.userHasAccess('Projetos.agrupamentos.create'),
          cb: (row) => {
            if (row && !this.selection.includes(row)) {
              this.selection.push(row);
            }

            this.alterarStatusProjetos();
          },
        },
        {
          name: "Justificativa de enquadramento",
          limit: 1,
          show: this.userHasAccess('Projetos.agrupamentos.create'),
          cb: (row) => {
            this.justificativaModalData = row;
            this.tituloProjetoSelecionado = row.titulo;
            if(this.isUser) {
              return this.userJustify = true;
            }
            this.justificativaModalOpen = true;
          },
        }
      ];
      const expandedOpts = [
        {
          name: "Gerar ano base apenas deste projeto",
          limit: 1,
          show: this.userHasAccessToAction('create'),
          cb: async (row) => {
            this.projetosAgrupados = [];
            this.plurianualData = {
              projetoAgrupado: true,
              titulo: row.titulo,
              ano_base: row.data_fim,
              data_fim: row.data_fim,
              id: row.id,
            };
            this.plurianualDialog = true;
          },
        },
      ];
      const menu = expanded ? expandedOpts : opts;

      this.showContext({
        left: e.clientX,
        top: e.clientY,
        val: v,
        menu: menu.filter(({ show }) => show),
      });
    },
    criarAgrupamento: function () {
      const projetos = this.selection.filter(({ agrupamento }) => !agrupamento);
      const [data_inicio, data_fim] = this.takeBiggerInterval(...projetos);
      this.agruparModalData = { data_inicio, data_fim };
      this.errorMessage = null;
      this.agruparModalOpened = true;
    },
    deleteAgrupamento: function (agrupamentoId) {
      return this.resourceAgrupamento
        .delete(agrupamentoId)
        .then((response) => {
          const doLoad = this.$refs.masterDetail.doLoad;

          if (!response.error && doLoad) {
            doLoad();
          }

          return response;
        });
    },
    gerarAnoBase: function(ano_base, projetos_agrupados)  {
      this.apiResource(`v1/projetos/${this.clientId}/plurianual/${this.plurianualData.id}`)
          .save({ ano_base, projetos_agrupados })
          .then((response) => {
            if (response.error) {
              return;
            }

            this.plurianualDialog = false;
            this.$notify({
              group: "geral",
              duration: 7000,
              type: "success",
              title: "Gerado com sucesso.",
              text: `Gerado plurianual para o ano ${response.ano_base}.`,
            });
        });
    },
    gerarProximoAnoBase: function ({ ano_base, data_fim, projetos_agrupados }) {
      const dataAnoBase = moment(data_fim).get('year');
      if (data_fim && (dataAnoBase < ano_base)) {
        const dataFimExibicao = moment(data_fim).format('DD/MM/YYYY');
        this.showConfirm(
          `Projeto encerrado em ${dataFimExibicao}`,
          `Deseja prosseguir com a replicação do projeto para o ano ${ano_base}? O novo projeto será criado com data fim indefinida.`,
          () => this.gerarAnoBase(ano_base, projetos_agrupados),
        );
      } else {
        this.gerarAnoBase(ano_base, projetos_agrupados);
      }
    },
    getProjetosSelecao: async function () {
      try {
        const query = `ano=${this.anoBase}`;
        const { code, error, projetos } = await this.resourceProjetosSelecao.get({ query });

        if (error) {
          throw { code, error };
        }

        return this.projetosSelecao = projetos.sort((a, b) => a.titulo?.localeCompare(b.titulo));
      } catch (e) {
        return this.projetosSelecao = [];
      }
    },
    saveAgrupamento: function (formData, closeFn, skipReload = false) {
      const selectionProjetosNaoAgrupados = this.selection.filter(({ agrupamento }) => !agrupamento).map(({ id }) => id);
      const body = {
        ano_base: this.anoBase,
        projetos: formData.projetos || (selectionProjetosNaoAgrupados.length > 0 ? selectionProjetosNaoAgrupados : []),
        titulo: formData.titulo,
        id: formData.id,
      };

      // Só adiciona data_inicio e data_fim caso seja o fluxo de criação ou no fluxo de edição teve alteração de projetos
      if (!formData.agrupamentoId || this.isGroupChanged([formData.agrupamentoId], body.projetos)) {
        body.data_inicio = formData.data_inicio;
        body.data_fim = formData.data_fim;
      }

      return this.resourceAgrupamento
        .save(body, body.id)
        .then((response) => {
          if (response.errors) {
            return this.errorMessage = response.errors;
          }

          if (closeFn) {
            closeFn();
          }

          const doLoad = this.$refs.masterDetail.doLoad;
          this.selection = [];

          if (doLoad && !skipReload) {
            doLoad();
          }

          return response;
        })
        .catch(({ response }) => {
          this.errorMessage = Object.values(response.data.errors).reduce((acc, curr) => [...acc, ...curr], []);
        });
    },
    validaDePara: function () {
      if (!this.depara.para) {
        return "Selecione um projeto para receber os dados.";
      }

      if (this.depara.para == this.depara.de.id) {
        return "Selecione um projeto diferente para receber os dados.";
      }

      let projetoPara = this.projetosSelecao.find(({ id }) => id == this.depara.para);

      if (!projetoPara) {
        return "Erro ao selecionar o projeto de destino.";
      }

      let trabalhoPara = projetoPara.trabalho;
      let trabalhoDe = this.depara.de.trabalho;

      if(trabalhoPara != trabalhoDe && trabalhoPara != this.trabalho.AMBOS) {
        if(trabalhoPara == this.trabalho.LEI_DO_BEM){
          return "Só pode receber dados de projetos para Lei do Bem."
        }

        if(trabalhoPara == this.trabalho.LEI_DE_INFORMATICA){
          return "Só pode receber dados de projetos para Lei de Informática."
        }
      }
    },
    executarDePara: function () {
      this.deParaError = "";
      let errorMessage = this.validaDePara();
      if (!errorMessage) {
        this.apiResource(
          `/v1/projetos/${this.clientId}/depara/${this.depara.de.id}/${this.depara.para}`
        )
          .save({})
          .then((response) => {
            if (!response.error) {
              this.deParaDialog = false;
              setTimeout(this.$refs.masterDetail.doLoad, 1000);
            }
          });
      } else {
        this.deParaError = errorMessage;
      }
    },
    getServico: function (trabalho) {
      const servico = this.opts.trabalho.find(({ id }) => id === trabalho);
      return servico && servico.nome;
    },
    alterarStatusProjetos() {
      this.statusModalOpened = !this.statusModalOpened;
      this.apiResource(`/v1/projetos/${this.clientId}/status?tipo=enquadrados`).get().then((result) => {
        const wrong = result.filter(item => item.label == null)[0];
        result.splice(result.indexOf(wrong, 0), 1);
        this.opts.statusOpts = result;
      });
    },
    saveStatusProjeto() {
      const projetoIds = this.selection.map(({ id }) => id);
      this.apiResource(`/v1/projetos/${this.clientId}/update-status`)
        .save({
          status: this.statusModalData.status,
          projetos: projetoIds
        }).then((result) => {
          if(result && !result.errors) {
            const doLoad = this.$refs.masterDetail.doLoad;
            if (doLoad) {
              doLoad();
            }
            this.$notify({
              group: "geral",
              duration: 5000,
              type: "success",
              title: "Alterações salvas",
              text: `${result.message}`,
            })
          } else {
            this.$notify({
              group: "geral",
              duration: 5000,
              type: "error",
              title: "Erro",
              text: `${result.errors.status}`,
            })
          }
        })
      this.statusModalOpened = false;
    },
    salvarJustificativaEnquadramento: function () {
      this.resourceProjetos.save(this.justificativaModalData, this.justificativaModalData.id).then((response) => {
        if (response.error) {
          throw response;
        }
        const doLoad = this.$refs.masterDetail.doLoad;
        if (doLoad) {
          doLoad();
        }
        this.$notify({
          group: "geral",
          duration: 7000,
          type: "success",
          title: "Sucesso",
          text: "Sua justificativa foi salva.",
        });
      });
      this.justificativaModalOpen = false;
    },
    exportCsv: function (forAlt = false) {
      this.apiResource(`v1/clientes/${this.clientId}/exportacao`)
        .save({
          tipoRelatorio: forAlt ? "projetos-enquadrados-edicao" : "projetos-enquadrados", anoBase: this.$store.getters.anoBase
        })
        .then(() => {
          this.exportacaoAsyncDialog = true;
      });
    },
    verFila: function () {
      this.$router.push({
        path: "/clientes/dashboard/relatorios-exportados",
      });
    },
    takeBiggerInterval(...projects) {
      if (!Array.isArray(projects)) {
        return ['', ''];
      }

      const { starts, ends } = projects.reduce(({ starts, ends }, { data_inicio, data_fim }) => {
        if (data_inicio) {
          starts.push(data_inicio);
        }

        if (data_fim) {
          ends.push(data_fim);
        }

        return { starts, ends };
      }, { starts: [], ends: [] });

      const start = starts.length > 0 ? starts.sort()[0] : '';
      const end = ends.length > 0 ? ends.sort()[ends.length - 1] : '';
      return [start, end];
    },
    setModalDates(agrupamento, ids) {
      if (!Array.isArray(ids)) {
        return;
      }

      const projetos = this.projetosSelecao.filter(({ id }) => ids.includes(id));
      const [dataInicio, dataFim] = this.takeBiggerInterval(agrupamento, ...projetos);
      this.editarAgrupamentoModalData = {
        ...this.editarAgrupamentoModalData,
        data_inicio: dataInicio,
        data_fim: dataFim,
      };
    },
    isGroupChanged(idsAgrupados, ids) {
      if (!Array.isArray(idsAgrupados) || !Array.isArray(ids)) {
        return;
      }

      return !ids.every((id) => idsAgrupados.includes(id));
    },
  },
  data: function () {
    return {
      agruparModalOpened: false,
      agruparModalData: {},
      deParaDialog: false,
      deParaDialogValid: true,
      depara: { de: null, para: null },
      deParaError: null,
      editarAgrupamentoModalOpened: false,
      editarAgrupamentoModalData: {},
      errorMessage: null,
      projetosAgrupados: [],
      projetosSelecao: [],
      plurianualData: {},
      plurianualDialog: false,
      selection: [],
      selectedRow: {},
      trabalho: {
        LEI_DO_BEM: 1,
        LEI_DE_INFORMATICA: 2,
        AMBOS: 3,
      },
      cols: [
        {
          key: "empresa",
          name: "Cliente",
          hideInTable: !this.clientId,
        },
        {
          key: "titulo",
          name: "Nome do Projeto",
          nowrap: true,
          tooltip: true,
          width: '350px',
        },
        {
          key: "ano_base",
          name: "Ano Base",
        },
        { key: "id_projeto", name: "Código" },
        {
          key: "status_label",
          name: "Status",
          type: this.$fieldTypes.AUTOCOMPLETE,
          width: '170px'
        },
        {
          key: "responsavelTxt",
          name: "Responsável",
          nowrap: true,
          tooltip: true,
        },
        {
          key: "data_inicio",
          name: "Data Inicial",
          type: this.$fieldTypes.DATE,
        },
        {
          key: "data_fim",
          name: "Data Final",
          type: this.$fieldTypes.DATE,
        },
        {
          key: "trabalho",
          name: "Benefício",
          rel: { to: "trabalho", key: "id", name: "nome" },
        },
        {
          key: "horas_timesheets",
          name: "Horas Timesheets",
          type: this.$fieldTypes.TIME,
        },
        /* DATA reuniao */
        {
          key: "modificado_em",
          name: "Modificado Há",
          align: 1,
          nowrap: true,
          type: this.$fieldTypes.FROMNOW,
        },
        {
          key: "criado_em",
          name: "Cadastro Há",
          align: 1,
          nowrap: true,
          type: this.$fieldTypes.FROMNOW,
        },
      ],
      opts: {
        // status: [],
        statusOpts: [],
        trabalho: [
          {
            id: BeneficiosEnum.LEI_DO_BEM,
            nome: BeneficiosLabels.LEI_DO_BEM,
          },
          {
            id: BeneficiosEnum.LEI_DE_INFORMATICA,
            nome: BeneficiosLabels.LEI_DE_INFORMATICA,
          },
          {
            id: BeneficiosEnum.LEI_DO_BEM_E_INFORMATICA,
            nome: BeneficiosLabels.LEI_DO_BEM_E_INFORMATICA,
          },
        ],
      },
      statusCols: [
        {
          key: "status",
          name: "Status",
          type: this.$fieldTypes.SELECT,
          rel: { to: "statusOpts", key: "id", name: "label"}
        }
      ],
      statusModalOpened: false,
      statusModalData: {},
      justificativaModalOpen: false,
      justificativaModalData: { justificativa: ''},
      tituloProjetoSelecionado: '',
      userJustify: false,
      exportacaoAsyncDialog: false,
      changeDateData: {},
      changeDateFields: {
        prevStart: {
          key: "prevStart",
          name: "Data Inicial Anterior",
          type: this.$fieldTypes.DATE,
        },
        prevEnd: {
          key: "prevEnd",
          name: "Previsão de Término Anterior",
          type: this.$fieldTypes.DATE,
        },
        nextStart: {
          key: "nextStart",
          name: "Data Inicial",
          type: this.$fieldTypes.DATE,
        },
        nextEnd: {
          key: "nextEnd",
          name: "Previsão de Término",
          type: this.$fieldTypes.DATE,
        },
      },
    };
  },
};
</script>