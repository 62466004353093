var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"teros-elevation",attrs:{"color":_vm.tableColor}},[_c('v-card-title',{staticClass:"px-5 pb-0 text-uppercase v-title-cadastro"},[_vm._v(" Valor Aproveitado de Incentivo ")]),_c('v-card-text',[_c('div',{staticClass:"d-flex pt-0"},[_c('div',{staticClass:"table-v-action-button mr-3 pt-1",attrs:{"aria-label":'atualizarButton'},on:{"click":function($event){return _vm.doLoad()}}},[_c('v-icon',[_vm._v("mdi-refresh")]),_vm._v("Atualizar ")],1),_c('v-menu',{attrs:{"offset-y":"","dense":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"table-v-action-button mr-3 pt-1",attrs:{"aria-label":'exportacaoButton'}},'div',attrs,false),on),[_c('v-icon',[_vm._v("mdi-file-export-outline")]),_vm._v("Exportação ")],1)]}}])},[_c('v-list',[_c('v-list-item',{staticStyle:{"cursor":"pointer"},attrs:{"aria-label":'exportarCsvButton'},on:{"click":function($event){return _vm.exportCsv(_vm.arrayToPrint, _vm.headerToPrint)}}},[_c('v-list-item-title',{staticClass:"v-label-input"},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-file-delimited-outline")]),_vm._v("Exportar CSV ")],1)],1),_c('v-list-item',{staticStyle:{"cursor":"pointer"},attrs:{"aria-label":'exportarXslxButton'},on:{"click":function($event){return _vm.exportXlsx2(_vm.relatorioFormatado, _vm.exportOptions)}}},[_c('v-list-item-title',{staticClass:"v-label-input"},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-file-excel-outline")]),_vm._v("Exportar XLSX ")],1)],1)],1)],1),_c('quarter-filter',{staticClass:"mb-n1",staticStyle:{"margin-top":"-2px"},attrs:{"multiple":"","quarters":_vm.trimestres,"year":_vm.ano},on:{"update:quarters":function($event){_vm.trimestres=$event},"update:year":function($event){_vm.ano=$event},"change":function($event){return _vm.doLoad()}}})],1)]),_c('v-row',{staticClass:"m-0"},[_c('v-col',{staticClass:"p-0 white"},[_c('v-data-table',{staticStyle:{"border-right":"thin solid rgba(0, 0, 0, 0.12)"},attrs:{"data-app":"","items":_vm.relatorioFormatado,"headers":_vm.colunas,"footer-props":{
          'items-per-page-options': [-1]
        },"hide-default-footer":"","fixed-header":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
        var item = ref.item;
        var headers = ref.headers;
return [_c('tr',{staticClass:"table-v-tr",class:{ 'font-weight-bold blue lighten-5': item.highlight },staticStyle:{"cursor":"default"}},_vm._l((headers),function(col){return _c('td',{key:col.value,class:{
                nowrap: col.nowrap,
                'text-end': col.align == 'end',
                'font-weight-bold': item.highlight
              }},[_vm._v(" "+_vm._s(col.format ? col.format(item[col.value]) : item[col.value])+" "),(item.asterisco && col.value != 'valor')?_c('span',{staticClass:"red--text"},[_vm._v("*")]):_vm._e()])}),0)]}}])}),_c('div',{staticClass:"p-3 font-weight-medium red--text blue lighten-5 footer"},[_vm._v(" * Valor utilizado para fins de cálculo do incentivo fiscal. ")])],1),_c('v-col',{staticClass:"p-0 white"},[_c('div',{staticClass:"font-weight-medium chart-title"},[_vm._v(" Total de Dispêndio e Incentivo Utilizado ")]),_c('div',{staticClass:"py-4 pl-4 pr-5",staticStyle:{"height":"280px"}},[_c('bar-chart',{staticStyle:{"height":"280px"},attrs:{"options":_vm.chartOptions,"chartdata":_vm.chartData}})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }