<template>
  <MasterDetail
    :actionBarButtons="actionBarButtons"
    :canDelete="false"
    :canEdit="false"
    :cols="cols"
    :customResource="customResource"
    :disableContextMenu="true"
    formTitle="Usuários do Sistema"
    :hasNewButton="false"
    :opts="opts"
    ref="detail"
    @contextmenu="contextMenu"
  ></MasterDetail>
</template>

<script>
import { uniq } from 'lodash';
import { mapGetters } from "vuex";
import MasterDetail from "@/components/master-detail.vue";
import { CLIENTE as clientMenu } from "@/core/constants/menus";
import { UserTypeEnum } from "@/core/enums/user-types.js";
import getRecursiveProperty from "@/helpers/getRecursiveProperty";

const camelize = str => {
  return str
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function(match, index) {
      if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
      return index === 0 ? match.toLowerCase() : match.toUpperCase();
    });
};

const mapMenuChildren = menus =>
  !Array.isArray(menus)
    ? []
    : menus.map(({ children, permissions }) => {
        const safePerms = Array.isArray(permissions) ? permissions : [];

        if (children) {
          return safePerms.concat(...mapMenuChildren(children));
        }

        return safePerms;
      });

const countPermissions = (user, permissions = []) =>
  permissions.reduce(
    (acc, permission) =>
      user?.empresa?.permissoes &&
      getRecursiveProperty(permission, user.empresa.permissoes)
        ? acc + 1
        : acc,
    0
  );

const clientModules = clientMenu.map(({ children, name, permissions = [] }) => {
  const childrenPermissions = mapMenuChildren(children);
  return {
    key: camelize(name),
    name,
    permissions: permissions.concat(...childrenPermissions)
  };
});

export default {
  components: {
    MasterDetail
  },
  computed: {
    ...mapGetters(["clientId", "selectedClient", "user"]),
    actionBarButtons: function() {
      return this.userHasAccessToAction("create")
        ? [
            {
              text: "Adicionar",
              icon: "mdi-plus-box-outline",
              action: () => {
                this.$router.push({ name: "cadastro-usuario" });
              }
            }
          ]
        : [];
    },
    cols: function() {
      const modules = !this.clientId ? [] : clientModules.map(({ name, key }) => ({
        key,
        name,
        align: 0,
        type: this.$fieldTypes.TEXT,
        hideInform: true
      }));
      return [
        { key: "id", name: "ID", align: 1, hideInform: true },
        {
          key: "nome",
          name: "Nome",
          type: this.$fieldTypes.TEXT,
          width: "250px"
        },
        { key: "usuario", name: "Usuário", type: this.$fieldTypes.TEXT },
        {
          key: "tipo_usuario",
          name: "Tipo",
          type: this.$fieldTypes.SELECT,
          rel: { to: "tipo", key: "id", name: "nome" },
          width: "200px"
        },
        {
          key: "ativo",
          name: "Status",
          align: 0,
          type: this.$fieldTypes.HTML,
          rel: { to: "usuarioStatus", key: "id", name: "nome" },
          hideInform: true
        },
        ...modules,
        {
          key: "modificado_em",
          name: "Modificado Em",
          type: this.$fieldTypes.DATETIME,
          hideInform: true
        }
      ];
    },
    customResource: function() {
      const resource = this.apiResource(
        `/v1/acesso/${this.clientId}/controleacesso`
      );
      const hasClientSelected = !!this.clientId;

      return {
        ...resource,
        async get(...args) {
          try {
            const response = await resource.get(...args);

            if (response.error) {
              throw response.error;
            }

            if (!hasClientSelected) {
              return response;
            }

            return response.map(user => {
              return clientModules.reduce(
                (acc, { permissions, key }) => {
                  const uniqPermissions = uniq(permissions);
                  let count = countPermissions(user, uniqPermissions);

                  if (count === 0) {
                    count = "Não";
                  } else if (count === uniqPermissions.length) {
                    count = "Sim";
                  } else {
                    count = `${count}/${uniqPermissions.length}`;
                  }

                  return {
                    ...acc,
                    [key]: count,
                  };
                },
                user
              );
            });
          } catch (error) {
            return [];
          }
        }
      };
    }
  },
  data: function() {
    return {
      opts: {
        tipo: [
          { id: UserTypeEnum.ADMINISTRADOR, nome: "Administrador" },
          { id: UserTypeEnum.CONSULTOR, nome: "Consultor" },
          {
            id: UserTypeEnum.CLIENTE_ADMINISTRADOR,
            nome: "Cliente Administrador [SaaS]"
          },
          {
            id: UserTypeEnum.CLIENTE_CONSULTOR,
            nome: "Cliente Consultor [SaaS]"
          },
          { id: UserTypeEnum.GERENCIAL_CLIENTE, nome: "Gerencial Cliente" },
          { id: UserTypeEnum.COLABORADOR, nome: "Colaborador" }
        ],
        usuarioStatus: [
          { id: true, nome: '<span style="color: green">Ativo</span>' },
          { id: false, nome: '<span style="color: lightgray">Inativo</span>' }
        ]
      }
    };
  },
  methods: {
    contextMenu: function(v, e) {
      const menu = [
        {
          name: "Editar",
          limit: 1,
          disabled:
            v.tipo_usuario === UserTypeEnum.ADMINISTRADOR &&
            this.user.tipo_usuario !== UserTypeEnum.ADMINISTRADOR,
          cb: row => {
            this.$router.push({
              name: "edicao-usuario",
              params: { id: row.id }
            });
          }
        }
      ];
      this.showContext({
        left: e.clientX,
        top: e.clientY,
        val: v,
        menu
      });
    }
  }
};
</script>
