<template>
  <v-card :color="tableColor" elevation="0">
    <v-card-text
      v-if="hasDefaultSlot || shouldHaveFilter || actionBarButtons.length"
      class="d-flex"
    >
      <div
        v-for="(button, idx) in actionBarButtons"
        :key="'action-bar-' + idx"
        @click="button.action"
        class="table-v-action-button mr-2 actionBarButtons"
        :aria-label="'actionBarButtons-' + idx"
      >
        <v-icon>{{ button.icon }}</v-icon>
        {{ button.text }}
      </div>

      <template v-if="shouldHaveFilter">
        <div
          v-if="!someColsShouldFilter"
          class="table-v-action-button mr-2"
          @click="showFilter = !showFilter"
        >
          <v-icon>mdi-filter-outline</v-icon> Filtro
        </div>
        <template v-else>
          <table-filter-modal
            :fields="filterFields"
            :opts="opts"
            :value.sync="filters"
            @clear="changePage"
            @filter="changePage"
          >
            <template v-slot:activator="{ on, attrs }">
              <div
                v-bind="attrs"
                v-on="on"
                class="table-v-action-button mr-2"
              >
                <v-icon>{{ atLeastOneFilteredColumn ? 'mdi-filter' : 'mdi-filter-outline'}}</v-icon> Ver filtros
              </div>
            </template>
          </table-filter-modal>

          <div
            v-if="atLeastOneFilteredColumn"
            class="table-v-action-button mr-2"
            @click="
              filters = {};
              changePage();
            "
          >
            <v-icon>mdi-filter-off</v-icon> Limpar filtros
          </div>
        </template>
      </template>

      <v-menu offset-y dense>
        <template v-slot:activator="{ on, attrs }">
          <div
            v-if="hasExportCSV && !hasAsyncExport"
            v-bind="attrs"
            v-on="on"
            class="table-v-action-button mr-2 exportacao"
            :aria-label="'exportacao'"
          >
            <v-icon>mdi-file-export-outline</v-icon>Exportação
          </div>
        </template>
        <v-list>
          <v-list-item
            v-if="hasExportCSV"
            style="cursor: pointer"
            @click="handleExportCsv()"
          >
            <v-list-item-title class="v-label-input exportarCsv" :aria-label="'exportarCsv'">
              <v-icon left>mdi-file-delimited-outline</v-icon>Exportar CSV
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="hasExportXLSX"
            style="cursor: pointer"
            @click="handleExportXlsx()"
          >
            <v-list-item-title class="v-label-input exportarXlsx" :aria-label="'exportarXlsx'">
              <v-icon left>mdi-file-excel-outline</v-icon>Exportar XLSX
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <async-export-button
        v-if="hasAsyncExport"
        class="mr-2 exportacaoAssincrona"
        :aria-label="'exportacaoAssincrona'"
        :anoBase="yearFilter"
        :beneficio="beneficio"
        :competencia="monthlyFilter"
        :type="asyncExportType"
      ></async-export-button>

      <quarter-filter
        v-if="quarterFilter || oneQuarterFilter"
        :aria-label="'trimestreAno'"
        class="mt-n2 mr-2"
        :multiple="quarterFilter"
        @change="changeQuarterFilterHandler($event)"
      ></quarter-filter>

      <monthly-filter
        v-if="monthlyFilter || oneMonthFilter"
        :aria-label="'filtroMensal'"
        class="mt-n1 mr-2"
        :style="`width: ${monthlyFilter ? '275' : '225'}px`"
        :oneMonth="!!oneMonthFilter"
        :interval="monthlyFilter || oneMonthFilter"
        @change="changeMonthlyFilterHandler($event)"
      ></monthly-filter>

      <day-filter
        v-if="dayFilter"
        class="mt-n1 mr-3"
        :style="`width: 275px`"
        :interval="dayFilter"
        @change="changeDayFilterHandler($event)"
      ></day-filter>

      <year-select
        v-if="yearFilter"
        class="mt-n1 mr-3 filtroAno"
        :aria-label="'filtroAno'"
        style="width: 20px; max-width: min-content"
        :value="yearFilter"
        :max="yearFilterConfig.max"
        :min="yearFilterConfig.min"
        @change="$emit('update:yearFilter', $event)"
      ></year-select>

      <servico-select
        v-if="beneficio"
        class="mt-n1 mr-3 filtroBeneficio"
        :aria-label="'filtroBeneficio'"
        style="max-width: 265px"
        :value="beneficio"
        @change="$emit('update:beneficio', $event)"
      ></servico-select>

      <div class="ml-auto" v-if="hasDefaultSlot">
        <slot></slot>
      </div>
    </v-card-text>
    <v-expand-transition>
      <div
        v-show="showFilter"
        class="pb-3 px-3"
      >
        <v-text-field
          class="mt-n3 table-v-action-button"
          v-model="filtro"
          :aria-label="'filtrar'"
          append-icon="mdi-magnify"
          label="Filtrar"
          single-line
          hide-details
        ></v-text-field>
      </div>
    </v-expand-transition>

    <v-data-table
      id="table_v"
      data-app
      :headers="headers"
      :items="items"
      @click:row="click"
      hide-default-header
      :hide-default-footer="disablePagination"
      :disable-pagination="disablePagination"
      :header-props="{
        'sort-icon': 'mdi-chevron-down',
      }"
      no-data-text="Não existem dados para mostrar."
      no-results-text="Não há dados para este filtro."
      :height="height"
      fixed-header
      :footer-props="{
        'disable-items-per-page': false,
        'items-per-page-text': 'Registros por página',
        'items-per-page-options': itemsPerPageOptions,
      }"
      :loading="loading"
      loading-text="Buscando dados..."
      :options.sync="options"
      :server-items-length="totalItems"
      :expanded.sync="expanded"
      :show-expand="expandSubItem"
      :item-key="resourceId"
      multi-sort
      @update:expanded="$emit('update:expandedItems', $event)"
    >
      <template v-slot:header="{ props: { headers }, on: { sort } }">
        <thead class="v-data-table-header">
          <tr>
            <th
              v-for="head in headers"
              :key="head.value"
              :class="[
                `text-${head.align || 'start'}`,
                {
                  'sortable': head.sortable && !head.filterable,
                  'active': isSortableActive(head, options),
                  'asc': !isSortableDesc(head, options),
                  'desc': isSortableDesc(head, options)
                },
                head.class
              ]"
              :data-label="head.text"
              :aria-label="ariaLabel(head, options)"
              :aria-sort="ariaSort(head, options)"
              :width="head.width"
              role="columnheader"
              scope="col"
            >
              <template v-if="items.length && head.type == 'expand' && hasSomeExpanded">
                <v-tooltip right >
                  <template v-slot:activator="{on}">
                    <div class="cursor-pointer" v-on="on" @click.stop="expandAll(!isSomeExpanded)">
                      <v-icon v-if="isSomeExpanded">mdi-chevron-double-up</v-icon>
                      <v-icon v-else>mdi-chevron-double-down</v-icon>
                    </div>
                  </template>
                  {{ isSomeExpanded ? "Recolher tudo" : "Expandir tudo" }}
                </v-tooltip>
              </template>
              <template v-if="showCheckAllHeader && head.type == 'selection'">
                <v-checkbox
                  class="mt-0"
                  :disabled="disableCheckAll"
                  hide-details
                  :indeterminate="isSomeChecked"
                  :ripple="false"
                  :value="isAllChecked"
                  @click.stop="checkAllChange"
                ></v-checkbox>
              </template>
              <template v-else>
                <span @click="!head.filterable && head.sortable && sort(head.value)">{{ head.text }}</span>
                <i
                  v-if="head.sortable && !head.filterable"
                  aria-hidden="true"
                  class="v-icon notranslate v-data-table-header__icon mdi mdi-chevron-down theme--light ordenacao"
                  :aria-label="'ordenacao' + head.value"
                  style="font-size: 18px;"
                  @click="sort(head.value)"
                ></i>
                <table-filter
                  v-if="head.filterable"
                  v-model="filters[head.originalValue]"
                  :field="filterFields[head.originalValue]"
                  :open.sync="showFilterModal[head.originalValue]"
                  :sortDirection="sortDirection(head.originalValue)"
                  @clear="changePage"
                  @filter="changePage"
                  @manualSort="manualSort(head.originalValue, $event)"
                ></table-filter>
                <v-icon
                  v-if="head.icon"
                  :ripple="false"
                  :style="head.icon.style"
                  @click="head.icon.click"
                >
                  {{ head.icon.text }}
                </v-icon>
              </template>
            </th>
          </tr>
        </thead>
      </template>
      <template v-slot:item="{ item, isExpanded, expand }">
        <tr
          :class="{ 'disable-hover': disableHover }" class="table-v-tr"
          id="table-v-tr"
        >
          <td
            v-for="col in headers"
            v-bind:key="col.key"
            :data-label="col.text"
            id="table-v-td"
            :class="{
              'text-truncate': col.label ? col.label.truncate : false,
              'text-no-wrap': col.nowrap,
            }"
            :style="{
              'text-align': textAligns[col.align],
              'min-width': col.width || getTDWidth(item[col.value]),
              'max-width': col.width,
            }"
            @contextmenu="contextmenu($event, item, col)"
            @click="click($event, item, col)"
          >
            <template v-if="col.type == 'save'">
              <v-btn
                dense
                depressed
                dark
                color="primary"
                :disabled="disableSaveButton(item)"
                @click.stop="$emit('save', item)"
                >Salvar</v-btn
              >
            </template>
            <template v-if="col.type == 'download'">
              <v-btn
                dense
                depressed
                dark
                color="primary"
                :disabled="!item[col.value]"
                @click.stop="doDownload(item[col.value])"
              >
                Baixar
              </v-btn>
            </template>
            <!-- TODO aqui tinha um teste de && item.__expandData[resourceId] que pode ainda ser necessário -->
            <template
              v-else-if="col.type == 'expand' && item.__expandData"
            >
              <div @click.stop="expand(!isExpanded)" class="expandIcon" :aria-label="'expandIcon'">
                <v-icon v-if="isExpanded">mdi-chevron-double-up</v-icon>
                <v-icon v-else>mdi-chevron-double-down</v-icon>
              </div>
            </template>
            <template v-else-if="col.type == 'selection'">
              <v-checkbox
                :value="isChecked(item)"
                :ripple="false"
                class="mt-0"
                hide-details
                @click.stop="checkChange(item)"
              ></v-checkbox>
            </template>
            <template v-else-if="col.label && col.label.editOnTable">
              <kore-select
                v-model="item[col.label.key]"
                :valid.sync="col.label.valid"
                :label="col.label"
                :opts="opts"
                :editable="!isSend && editablePropParser(col.label.editable, item)"
                :commentForField.sync="item[col.label.commentForField]"
                :hideDetails="true"
                @input="valueChanged(col.label.valueChanged, $event)"
              ></kore-select>
            </template>
            <template v-else>
              <div
                v-if="col.type == 4 || col.type == $fieldTypes.HTML"
                v-html="item[col.value]"
                :class="{ 'mx-auto': !col.withoutMargin }"
                style="display: table"
              ></div>
              <template
                v-else-if="col.currency || col.type == $fieldTypes.MONEY"
                >
                  <span v-if="col.label && !col.label.tooltip">{{ item[col.value] | toCurrency }}</span>
                  <v-tooltip right >
                    <template v-slot:activator="{on}" v-if="col.label && col.label.tooltip">
                      <span v-on="on">{{ item[col.value] | toCurrency }}</span>
                    </template>
                    {{ item[col.value] | toCurrency }}
                  </v-tooltip>
                </template
              >
              <template
                v-else-if="col.currency || col.type == $fieldTypes.MONEY2"
                >
                  <span v-if="col.label && !col.label.tooltip">{{ item[col.value] | toCurrencyValue }}</span>
                  <v-tooltip right >
                    <template v-slot:activator="{on}" v-if="col.label && col.label.tooltip">
                      <span v-on="on">{{ item[col.value] | toCurrencyValue }}</span>
                    </template>
                    {{ item[col.value] | toCurrencyValue }}
                  </v-tooltip>
                </template
              >
              <template v-else-if="col.type == $fieldTypes.DATE">
                <span v-if="col.label && !col.label.tooltip">{{ item[col.value] | toDate }}</span>
                <v-tooltip right >
                  <template v-slot:activator="{on}" v-if="col.label && col.label.tooltip">
                    <span v-on="on">{{ item[col.value] | toDate }}</span>
                  </template>
                  {{ item[col.value] | toDate }}
                </v-tooltip>
              </template>
              <template v-else-if="col.type == $fieldTypes.NUMBER">
                <span v-if="col.label && !col.label.tooltip">{{ item[col.value] | toNumber }}</span>
                <v-tooltip right >
                  <template v-slot:activator="{on}" v-if="col.label && col.label.tooltip">
                    <span v-on="on">{{ item[col.value] | toNumber }}</span>
                  </template>
                  {{ item[col.value] | toNumber }}
                </v-tooltip>
              </template>
              <template v-else-if="col.type == $fieldTypes.DOUBLE">
                <span v-if="col.label && !col.label.tooltip">{{ item[col.value] | toDouble }}</span>
                <v-tooltip right >
                  <template v-slot:activator="{on}" v-if="col.label && col.label.tooltip">
                    <span v-on="on">{{ item[col.value] | toDouble }}</span>
                  </template>
                  {{ item[col.value] | toDouble }}
                </v-tooltip>
              </template>
              <template v-else-if="col.type == $fieldTypes.DURATION">
                <span class="d-block">
                  <span v-if="col.label && !col.label.tooltip">{{ item[col.value] | toDuration }}</span>
                  <v-tooltip right >
                    <template v-slot:activator="{on}" v-if="col.label && col.label.tooltip">
                      <span v-on="on">{{ item[col.value] | toDuration }}</span>
                    </template>
                    {{ item[col.value] | toDuration }}
                  </v-tooltip>
                </span>
                <span style="font-size: 11px; display: block">
                  <span v-if="col.label && !col.label.tooltip">{{ item[col.value] | toDiasUteis }}</span>
                  <v-tooltip right >
                    <template v-slot:activator="{on}" v-if="col.label && col.label.tooltip">
                      <span v-on="on">{{ item[col.value] | toDiasUteis }}</span>
                    </template>
                    {{ item[col.value] | toDiasUteis }}
                  </v-tooltip>
                </span>
              </template>
              <template v-else-if="col.type == $fieldTypes.DATETIME">
                <span v-if="col.label && !col.label.tooltip">{{ item[col.value] | toDateTime }}</span>
                <v-tooltip right >
                  <template v-slot:activator="{on}" v-if="col.label && col.label.tooltip">
                    <span v-on="on">{{ item[col.value] | toDateTime }}</span>
                  </template>
                  {{ item[col.value] | toDateTime }}
                </v-tooltip>
              </template>
              <template v-else-if="col.type == $fieldTypes.FROMNOW">
                <span style="display: block">
                  <span v-if="col.label && !col.label.tooltip">{{ item[col.value] | toFromNow }}</span>
                  <v-tooltip right >
                    <template v-slot:activator="{on}" v-if="col.label && col.label.tooltip">
                      <span v-on="on">{{ item[col.value] | toFromNow }}</span>
                    </template>
                    {{ item[col.value] | toFromNow }}
                  </v-tooltip>
                </span>
                <span style="font-size: 11px; display: block">
                  <span v-if="col.label && !col.label.tooltip">{{ item[col.value] | toDateTime }}</span>
                  <v-tooltip right >
                    <template v-slot:activator="{on}" v-if="col.label && col.label.tooltip">
                      <span v-on="on">{{ item[col.value] | toDateTime }}</span>
                    </template>
                    {{ item[col.value] | toDateTime }}
                  </v-tooltip>
                </span>
              </template>
              <template v-else-if="col.type == $fieldTypes.MONTH">
                <span v-if="col.label && !col.label.tooltip">{{ item[col.value] | toMonth }}</span>
                <v-tooltip right >
                  <template v-slot:activator="{on}" v-if="col.label && col.label.tooltip">
                    <span v-on="on">{{ item[col.value] | toMonth }}</span>
                  </template>
                  {{ item[col.value] | toMonth }}
                </v-tooltip>
              </template>
              <template v-else-if="[$fieldTypes.TIME, $fieldTypes.BIGTIME].includes(col.type)">
                <span v-if="col.label && !col.label.tooltip">{{ item[col.value] | toTime }}</span>
                <v-tooltip right >
                  <template v-slot:activator="{on}" v-if="col.label && col.label.tooltip">
                    <span v-on="on">{{ item[col.value] | toTime }}</span>
                  </template>
                  {{ item[col.value] | toTime }}
                </v-tooltip>
              </template>
              <template v-else-if="col.type == $fieldTypes.FILE || col.type == $fieldTypes.FILE2">
                <v-alert
                  v-for="(anexo, akey) in item[col.value]"
                  :key="akey"
                  border="left"
                  color="primary"
                  dense
                  text
                  class="my-1"
                  style="font-size: 13px"
                  @click.stop="openUrl(anexo.url)"
                  >{{ anexo.nome }}</v-alert
                >
              </template>
              <template v-else-if="col.type == $fieldTypes.PASSWORD">
                <div style="display: flex; align-items: center; justify-content: space-between">
                  <v-text-field
                    :value="item[col.value]"
                    v-if="(item[col.value] && item[col.value].length > 0)"
                    type="password"
                    variant="solo"
                    style="max-width: 100px"
                    disabled
                    >
                  </v-text-field>
                  <v-btn icon @click="copyToClipboard(item[col.value])"
                  v-if="(item[col.value] && item[col.value].length > 0) && col.label && col.label.copyToClipboard">
                    <v-icon>
                      mdi-content-copy
                    </v-icon>
                  </v-btn>
                </div>
              </template>
              <template v-else-if="col.type == $fieldTypes.CNPJ">
                <span>{{ formatCNPJ(item[col.value]) }}</span>
              </template>
              <template v-else-if="col.type == $fieldTypes.CPF">
                <span>{{ formatCPF(item[col.value]) }}</span>
              </template>
              <template v-else>
                <span v-if="col.label && !col.label.tooltip">{{ item[col.value] }}</span>
                <v-btn icon
                  :key="col.label.key"
                  v-if="(item[col.value] && item[col.value].length > 0) && col.label && col.label.copyToClipboard"
                  @click="copyToClipboard(item[col.value])"
                  style="margin-left: 20px">
                  <v-icon :key="col.label.key">mdi-content-copy</v-icon>
                </v-btn>
                <v-tooltip right>
                  <template v-slot:activator="{on}" v-if="col.label && col.label.tooltip">
                    <span v-on="on">{{ item[col.value] && item[col.value].length > 33 ? item[col.value].substring(0, 33).concat('...') : item[col.value]}}</span>
                  </template>
                  <span>{{ item[col.value] }}</span>
                </v-tooltip>
              </template>
            </template>
          </td>
        </tr>
      </template>
      <template v-slot:[`footer.page-text`]="items">
        {{ items.pageStart }} - {{ items.pageStop }} de
        {{ items.itemsLength }}
      </template>
      <template v-if="selectedToCompare" v-slot:[`body.prepend`]="{}">
        <tr class="table-v-tr">
          <td
            style="
              position: sticky;
              top: 48px;
              background: #ffefaa;
              font-weight: 500;
            "
            v-for="col in headers"
            v-bind:key="col.key"
            :data-label="col.text"
            id="table-v-td"
            :class="{
              'text-truncate': col.label ? col.label.truncate : false,
              'text-no-wrap': col.nowrap,
            }"
            :style="{
              'text-align': textAligns[col.align],
              'min-width': getTDWidth(selectedToCompare[col.value]),
            }"
          >
            <template v-if="col.type == 'save'">
              <v-btn
                dense
                depressed
                dark
                color="primary"
                @click.stop="$emit('save', selectedToCompare)"
                >Salvar</v-btn
              >
            </template>
            <template v-else-if="col.label.editOnTable">
              <kore-select
                v-model="selectedToCompare[col.label.key]"
                :valid.sync="col.label.valid"
                :label="col.label"
                :opts="opts"
                :editable="!isSend && editablePropParser(col.label.editable, selectedToCompare)"
                :hideDetails="true"
                @changed="valueChanged(col.label.valueChanged, $event)"
              ></kore-select>
            </template>
            <template v-else>
              <div
                v-if="col.type == 4 || col.type == $fieldTypes.HTML"
                v-html="selectedToCompare[col.value]"
                :class="{ 'mx-auto': !col.withoutMargin }"
                style="display: table"
              ></div>
              <template
                v-else-if="col.currency || col.type == $fieldTypes.MONEY"
                >{{ selectedToCompare[col.value] | toCurrency }}</template
              >
              <template v-else-if="col.type == $fieldTypes.DATE">{{
                selectedToCompare[col.value] | toDate
              }}</template>
              <template v-else-if="col.type == $fieldTypes.NUMBER">{{
                selectedToCompare[col.value] | toNumber
              }}</template>
              <template v-else-if="col.type == $fieldTypes.DOUBLE">{{
                selectedToCompare[col.value] | toDouble
              }}</template>
              <template v-else-if="col.type == $fieldTypes.DURATION">
                <span class="d-block">{{
                  selectedToCompare[col.value] | toDuration
                }}</span>
                <span style="font-size: 11px; display: block">{{
                  selectedToCompare[col.value] | toDiasUteis
                }}</span>
              </template>
              <template v-else-if="col.type == $fieldTypes.DATETIME">{{
                selectedToCompare[col.value] | toDateTime
              }}</template>
              <template v-else-if="col.type == $fieldTypes.FROMNOW">
                <span style="display: block">
                  {{ selectedToCompare[col.value] | toFromNow }}
                </span>
                <span style="font-size: 11px; display: block">
                  {{ selectedToCompare[col.value] | toDateTime }}
                </span>
              </template>
              <template v-else-if="col.type == $fieldTypes.MONTH">{{
                selectedToCompare[col.value] | toMonth
              }}</template>
              <template v-else-if="[$fieldTypes.TIME, $fieldTypes.BIGTIME].includes(col.type)">{{
                selectedToCompare[col.value] | toTime
              }}</template>
              <template v-else-if="col.type == $fieldTypes.FILE || col.type == $fieldTypes.FILE2">
                <v-alert
                  v-for="(anexo, akey) in selectedToCompare[col.value]"
                  :key="akey"
                  border="left"
                  color="primary"
                  dense
                  text
                  class="my-1"
                  style="font-size: 13px"
                  @click.stop="openUrl(anexo.url)"
                  >{{ anexo.nome }}</v-alert
                >
              </template>
              <template v-else>{{ selectedToCompare[col.value] }}</template>
            </template>
          </td>
        </tr>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <tr v-if="item.__expandData && item.__expandData.length === 0" class="table-v-tr">
          <td
            class="text-center"
            colspan="100%"
            style="
              background: #ebedef;
              border-bottom: 1px solid #212529;
              height: 48px;
            "
          >
            Não existem dados para mostrar.
          </td>
        </tr>
        <tr
          v-for="(expandItem, index) of item.__expandData"
          :key="`item${item[resourceId]}-sub${index}`"
          class="table-v-tr"
        >
          <td
            style="
              background: #ebedef;
              height: 48px;
              border-bottom: 1px solid #212529;
            "
            v-for="col in headers"
            :key="col.key"
            :data-label="col.text"
            id="table-v-td"
            :class="{
              'text-truncate': col.label ? col.label.truncate : false,
              'text-no-wrap': col.nowrap,
            }"
            :style="{
              'text-align': textAligns[col.align],
              'min-width': getTDWidth(expandItem[col.value]),
            }"
            @contextmenu="expandContextMenu && contextmenu($event, expandItem, col, true)"
          >
            <template v-if="col.type == 'save'">
              <v-btn
                dense
                depressed
                dark
                color="primary"
                @click.stop="$emit('save', expandItem)"
                >Salvar</v-btn
              >
            </template>
            <template v-else>
              <div
                v-if="col.type == 4 || col.type == $fieldTypes.HTML"
                v-html="expandItem[col.value]"
                :class="{ 'mx-auto': !col.withoutMargin }"
                style="display: table"
              ></div>
              <template
                v-else-if="col.currency || col.type == $fieldTypes.MONEY"
                >{{ expandItem[col.value] | toCurrency }}</template
              >
              <template v-else-if="col.type == $fieldTypes.DATE">{{
                expandItem[col.value] | toDate
              }}</template>
              <template v-else-if="col.type == $fieldTypes.NUMBER">{{
                expandItem[col.value] | toNumber
              }}</template>
              <template v-else-if="col.type == $fieldTypes.DOUBLE">{{
                expandItem[col.value] | toDouble
              }}</template>
              <template v-else-if="col.type == $fieldTypes.DURATION">
                <span class="d-block">{{
                  expandItem[col.value] | toDuration
                }}</span>
                <span style="font-size: 11px; display: block">{{
                  expandItem[col.value] | toDiasUteis
                }}</span>
              </template>
              <template v-else-if="col.type == $fieldTypes.DATETIME">{{
                expandItem[col.value] | toDateTime
              }}</template>
              <template v-else-if="col.type == $fieldTypes.FROMNOW">
                <span style="display: block">
                  {{ expandItem[col.value] | toFromNow }}
                </span>
                <span style="font-size: 11px; display: block">
                  {{ expandItem[col.value] | toDateTime }}
                </span>
              </template>
              <template v-else-if="col.type == $fieldTypes.MONTH">{{
                expandItem[col.value] | toMonth
              }}</template>
              <template v-else-if="[$fieldTypes.TIME, $fieldTypes.BIGTIME].includes(col.type)">{{
                expandItem[col.value] | toTime
              }}</template>
              <template v-else-if="col.type == $fieldTypes.FILE || col.type == $fieldTypes.FILE2">
                <v-alert
                  v-for="(anexo, akey) in expandItem[col.value]"
                  :key="akey"
                  border="left"
                  color="primary"
                  dense
                  text
                  class="my-1"
                  style="font-size: 13px"
                  @click.stop="openUrl(anexo.url)"
                  >{{ anexo.nome }}</v-alert
                >
              </template>
              <template v-else>{{ expandItem[col.value] }}</template>
            </template>
          </td>
        </tr>
      </template>
      <template v-if="lastRowData && rows.length" v-slot:[`body.append`]="{}">
        <tr :class="{ 'disable-hover': disableHover }" class="table-v-tr" id="table-v-tr">
          <td
            style="font-weight: bold;"
            v-for="col in headers"
            :key="col.key"
            :data-label="col.text"
            id="table-v-td"
            :class="{
              'text-truncate': col.label ? col.label.truncate : false,
              'text-no-wrap': col.nowrap,
            }"
            :style="{
              'text-align': textAligns[col.align],
              'min-width': getTDWidth(lastRowData[col.value]),
            }"
          >
            <template>
              <template
                v-if="col.currency || col.type == $fieldTypes.MONEY"
                >{{ lastRowData[col.value] | toCurrency }}</template
              >
              <template
                v-else-if="col.currency || col.type == $fieldTypes.MONEY2"
                >{{ lastRowData[col.value] | toCurrencyValue }}</template
              >
              <template v-else-if="col.type == $fieldTypes.NUMBER">{{
                lastRowData[col.value] | toNumber
              }}</template>
              <template v-else-if="col.type == $fieldTypes.DOUBLE">{{
                lastRowData[col.value] | toDouble
              }}</template>
              <template v-else-if="col.type == $fieldTypes.MONTH">{{
                lastRowData[col.value] | toMonth
              }}</template>
              <template v-else-if="[$fieldTypes.TIME, $fieldTypes.BIGTIME].includes(col.type)">{{
                lastRowData[col.value] | toTime
              }}</template>
              <template v-else>{{ lastRowData[col.value] }}</template>
            </template>
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { cloneDeep, find } from "lodash";
import { mapGetters, mapMutations } from 'vuex';
import { ITEMS_PER_PAGE_OPTIONS, ITEMS_PER_PAGE_OPTIONS_SERVE_SIDE } from "../helpers/itemsPerPageOptions";
import { formatCNPJ, formatCPF } from "@/helpers/masks";

export default {
  components: {
    "async-export-button": () => import("@/components/async-export-button.vue"),
    "kore-select": () => import("@/components/input-v.vue"),
    "day-filter": () => import("@/components/day-filter.vue"),
    "monthly-filter": () => import("@/components/monthly-filter.vue"),
    "quarter-filter": () => import("@/components/quarter-filter.vue"),
    "servico-select": () => import("@/components/servico-select.vue"),
    "table-filter": () => import("@/components/table-filter.vue"),
    "table-filter-modal": () => import("@/components/table-filter-modal.vue"),
    "year-select": () => import("@/components/year-select.vue"),
  },
  props: {
    cols: {},
    opts: { default: () => ({}) },
    rows: {},
    lastRowData: {},
    resourceId: { default: "id" },
    actionBarButtons: { default: () => ([]) },
    hasFilter: { type: Boolean, default: false },
    hasExportCSV: { default: true },
    hasExportXLSX: { default: true },
    hasExportPDF: { default: true },
    asyncExportType: { type: String },
    loading: { default: false },
    editOnTable: { default: false },
    totalItems: { default: undefined },
    selectedToCompare: { default: undefined },
    height: { default: "calc(100vh - 275px)" },
    expandSubItem: { type: Boolean, default: false },
    expandContextMenu: { type: Boolean, default: false },
    quarterFilter: { type: [Boolean, Object], default: false },
    oneQuarterFilter: { type: [Boolean, Object], default: false },
    monthlyFilter: { type: [Boolean, Array], default: false },
    oneMonthFilter: { type: [Boolean, String], default: false },
    yearFilter: { type: [Boolean, String], default: false },
    yearFilterConfig: { type: Object, default: () => ({}) },
    disableTableSaveButton: { type: [Boolean, Function], default: false },
    disableAllItensPerPage: { type: Boolean, default: false },
    disableHover: { default: false },
    beneficio: { type: [Boolean, Number], default: false},
    selectionCol: { type: Boolean, default: false },
    hideCheckAll: { type: Boolean, default: false },
    selection: { type: Array, default: () => [] },
    disablePagination: { type: Boolean, default: false },
    expandedItems: { type: Array },
    dayFilter: { type: [Boolean, Array], default: false },
  },
  data: function () {
    const selectionMap = new Map(this.selection.map((row) => [row[this.resourceId], row]));
    return {
      options: {
        sortBy: [],
        sortDesc: [],
      },
      isCopied: false,
      isSend: false,
      showFilter: false,
      optsMap: {},
      aligns: {
        "a-1": "start",
        a0: "center",
        a1: "end",
      },
      textAligns: {
        start: "left",
        center: "center",
        end: "right",
      },
      textCanvas: null,
      filters: {},
      showFilterModal: {},
      filtro: '',
      selectionMap,
      expanded: [],
    };
  },
  watch: {
    options: function ({ itemsPerPage }) {
      this.setPaginationItemsPerPage(itemsPerPage);
      this.changePage();
    },
    filters: {
      deep: true,
      handler: function (value) {
        this.setPageTableFilters(value);
      },
    },
    selection: function (value) {
      this.selectionMap = new Map(value.map((row) => [row[this.resourceId], row]));
    },
    expandedItems(value) {
      if (Array.isArray(value)) {
        this.expanded = value;
      }
    },
  },
  methods: {
    ...mapMutations(['setPaginationItemsPerPage', 'setPageTableFilters']),
    isSortableActive: function (headers, options) {
      const { value } = headers;
      const { sortBy } = options;
      return Array.isArray(sortBy) && sortBy.includes(value);
    },
    isSortableDesc: function (headers, options) {
      const { value } = headers;
      const { sortBy, sortDesc } = options;
      return this.isSortableActive(headers, options) && sortDesc[sortBy.indexOf(value)];
    },
    ariaLabel: function (headers, options) {
      const { text } = headers;
      const index = !this.isSortableActive(headers, options) ? 0 : !this.isSortableDesc(headers, options) ? 1 : 2;
      const ordenacao = [
        'Não ordenado',
        'Ordenado crescente',
        'Ordenado decrescente',
      ]
      const proxOrdenacao = [
        'Ative para ordenar crescente',
        'Ative para ordenar decrescente',
        'Ative para remover a ordenação',
      ]
      return `${text}: ${ordenacao[index]}. ${proxOrdenacao[index]}. tableColumnHeader-${text}`;
    },
    ariaSort: function (headers, options) {
      return !this.isSortableActive(headers, options) ? 'none' : !this.isSortableDesc(headers, options) ? 'ascending' : 'descending';
    },
    sortDirection: function (colKey) {
      const { sortBy, sortDesc } = this.options || {};

      if (sortBy?.includes(colKey)) {
        const index = sortBy.indexOf(colKey);
        return sortDesc[index] ? 'desc' : 'asc';
      }

      return 'none';
    },
    manualSort: function (value, desc = false) {
      const { sortBy, sortDesc, ...rest } = this.options;

      if (sortBy.includes(value)) {
        const index = sortBy.indexOf(value);

        if (sortDesc[index] !== desc) {
          sortDesc[index] = desc;
        } else {
          sortBy.splice(index, 1);
          sortDesc.splice(index, 1);
        }
      } else {
        sortBy.push(value);
        sortDesc.push(desc);
      }

      this.options = {
        ...rest,
        sortBy: cloneDeep(sortBy),
        sortDesc: cloneDeep(sortDesc),
      };
    },
    disableSaveButton: function (item) {
      return typeof this.disableTableSaveButton === 'function' ? this.disableTableSaveButton(item) : this.disableTableSaveButton || false;
    },
    editablePropParser: function (editableProp, rowData) {
      return typeof editableProp === 'function' ? editableProp(rowData) : editableProp;
    },
    openUrl: function (url) {
      window.open(url);
    },
    contextmenu: function (event, row, col, expanded = false) {
      event.preventDefault();
      this.$emit("contextmenu", row, event, expanded, col);
    },
    handleExportCsv: function () {
      const promise = new Promise((resolve, reject) => {
        this.$emit("rowstoprint", resolve, reject);
      });
      promise.then((arrayFromData) => {
        this.exportCsv(this.formatResultForPrint(arrayFromData));
      });
    },
    handleExportXlsx: function () {
      const promise = new Promise((resolve, reject) => {
        this.$emit("rowstoprint", resolve, reject);
      });
      promise.then((ws_data) => {
        this.exportXlsx(this.formatResultForPrint(ws_data));
      });
    },
    printTable: function () {
      this.htmltoPDF([
        document.getElementById("table_v").querySelectorAll("table")[0],
      ]);
    },
    s2ab: function (s) {
      var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
      var view = new Uint8Array(buf); //create uint8array as viewer
      for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff; //convert to octet
      return buf;
    },
    click: function (e, v, col) {
      this.$emit("click", v, e, false, col);
    },
    formatResultForPrint: function (rows) {
      var parsedRows = this.parseResult(rows);
      var formatResult = [this.headers.map((c) => c.text)].concat(
        parsedRows.map((r) =>
          this.headers.map((cr) => {
            if (
              this.filterMethods[cr.type] &&
              ![this.$fieldTypes.MONEY, this.$fieldTypes.MONEY2].includes(cr.type)
            ) {
              return this.filterMethods[cr.type](
                r[cr.value],
                r[cr.originalValue]
              );
            }
            return r[cr.value];
          })
        )
      );
      return formatResult;
    },
    parseResult: function (rows) {
      let resolveData = (data) => {
        this.cols
          .filter((c) => c.rel && c.rel.name && !c.hideInTable)
          .forEach((col) => {
            var d = col.rel.toEdit ? col.rel.toEdit : this.opts[col.rel.to];
            var s = find(d, (e) => e[col.rel.key] === data[col.key]);

            if (Array.isArray(col.rel.name)) {
              data[col.key + col.rel.name.join("")] = s
                ? col.rel.name.map((field) => s[field]).join(col.rel.nameSpacer)
                : "--";
            } else {
              if (Array.isArray(data[col.key])) {
                var result = [];
                data[col.key].forEach((val) => {
                  var rel = find(d, (e) => e[col.rel.key] === val);
                  if (rel) {
                    result.push(rel[col.rel.name]);
                  }
                });

                data[col.key + col.rel.name] = result
                  ? result.join(", ")
                  : "--";
              } else {
                data[col.key + col.rel.name] = s ? s[col.rel.name] : "--";
              }
            }
          });
        return data;
      };
      let resolveExpandData = (expandData) => {
        return Array.isArray(expandData)
          ? expandData.map((data) => resolveData(data))
          : [resolveData(expandData)];
      };

      let result = rows.map((data) =>
        (this.expandSubItem && Array.isArray(data))
          ? { ...resolveData(data[0]), __expandData: resolveExpandData(data[1]) }
          : resolveData(data)
      );

      if (this.filtro && this.filtro != "") {
        result = result.filter((f) => {
          return !!this.headers
            .map((h) =>
              this.filterMethods[h.type] && h.type != this.$fieldTypes.HTML
                ? this.filterMethods[h.type](f[h.value], f[h.originalValue])
                : f[h.value]
            )
            .find((value) =>
              !!value &&
              value
                .toString()
                .toLowerCase()
                .includes(this.filtro.toLowerCase())
            );
        });
      }

      return result;
    },
    getTDWidth: function (text) {
      if (Array.isArray(text)) {
        var width = 0;
        text
          .map((t) => this.getTextWidth(t.nome))
          .forEach((w) => {
            if (w > width) width = w;
          });
        return Math.round(width) + 80 + "px";
      }
      return Math.round(this.getTextWidth(text) / 2.55) + 50 + "px";
    },
    getTextWidth: function (text) {
      var font = "13px Poppins, serif";
      // re-use canvas object for better performance
      var canvas =
        this.textCanvas || (this.textCanvas = document.createElement("canvas"));
      var context = canvas.getContext("2d");
      context.font = font;
      var regex = /( |<([^>]+)>)/ig;
      var sanitized = typeof text === 'string' ? text.replace(regex, "") : text;
      var metrics = context.measureText(sanitized);
      return metrics.width;
    },
    doDownload: function (url) {
      const a = document.createElement("a");
      a.href = url;
      a.target = "_blank";
      a.click();
      a.remove();
    },
    changeMonthlyFilterHandler: function (value) {
      const eventName = this.monthlyFilter ? 'update:monthlyFilter' : 'update:oneMonthFilter';
      this.$emit(eventName, value);
    },
    changeQuarterFilterHandler({ quarters, year }) {
      if (this.quarterFilter) {
        this.$emit('update:quarterFilter', { quarters, year });
        return;
      }

      this.$emit('update:oneQuarterFilter', { quarter: quarters[0], year });
    },
    changeDayFilterHandler(value) {
      this.$emit('update:dayFilter', value);
    },
    changePage: function () {
      const { page, itemsPerPage, sortBy, sortDesc } = this.options;
      const sort = sortBy
        .reduce((prev, curr, currIndex) => ({
          ...prev,
          [curr]: sortDesc[currIndex] ? 'desc' : 'asc'
        }), {});
      const params = {
        page: page || 1,
        perPage: itemsPerPage || 10,
        sort,
        filters: {...this.filters},
      };
      this.$emit("changePage", params);
    },
    checkChange: function (row) {
      if (!(this.resourceId in row)) {
        throw Error(`Registro não possui propriedade de nome ${this.resourceId}. Declare corretamente um resourceId.`);
      }

      const id = row[this.resourceId];
      if (this.selectionMap.has(id)) {
        this.selectionMap.delete(id);
      } else {
        this.selectionMap.set(id, row);
      }

      this.selectionMap = new Map(this.selectionMap.entries());
      this.$emit('update:selection', Array.from(this.selectionMap.values()));
    },
    checkAllChange: function () {
      if (this.isAllChecked) {
        this.selectionMap.clear();
      } else {
        this.items.forEach((item, index) => {
          if (!(this.resourceId in item)) {
            throw Error(`Registro de indice ${index} não possui propriedade de nome ${this.resourceId}. Declare corretamente um resourceId.`);
          }

          const id = item[this.resourceId];
          this.selectionMap.set(id, item);
        });
      }

      this.selectionMap = new Map(this.selectionMap.entries());
      this.$emit('update:selection', Array.from(this.selectionMap.values()));
    },
    isChecked: function (row) {
      const rowData = Array.isArray(row) ? row[0] : row;

      if (!(this.resourceId in rowData)) {
        throw Error(`Registro não possui propriedade de nome ${this.resourceId}. Declare corretamente um resourceId.`);
      }

      const id = row[this.resourceId];
      return this.selectionMap.has(id);
    },
    copyToClipboard(value) {
      this.isCopied = !this.isCopied;
      navigator.clipboard.writeText(value)
    },
    valueChanged: function (valueChanged, value) {
      if (valueChanged) {
        valueChanged(value);
      }
    },
    expandAll(expand) {
      if (expand) {
        this.expanded = this.items.filter(({ __expandData }) => Array.isArray(__expandData));
      } else {
        this.expanded = [];
      }
      this.$emit("update:expandedItems", this.expanded);
    },
    formatCNPJ,
    formatCPF,
  },
  computed: {
    ...mapGetters([
      'pageTableFilters',
      'getClientSidePaginationItemsPerPage',
      'getServerSidePaginationItemsPerPage',
    ]),
    isClient: function () {
      return this.getClient().isClient;
    },
    disableCheckAll: function () {
      return !(this.items?.length > 0);
    },
    isAllChecked: function () {
      return this.items.length > 0 && this.selectionMap.size === this.items.length;
    },
    isSomeChecked: function () {
      return 0 < this.selectionMap.size && this.selectionMap.size < this.items.length;
    },
    items: function () {
      let a = this.parseResult(this.rows);

      // TODO Apagar esse IF quando a ordenação for do lado do servidor
      if (this.disableAllItensPerPage && this.totalItems) {
        const { sortBy, sortDesc } = this.options;
        sortBy?.forEach((name, index) => {
          a.sort((a, b) => {
            const test = sortDesc[index] ? a[name] > b[name] : b[name] > a[name];
            return test ? -1 : 1;
          });
        });
      }

      return a;
    },
    filterMethods: function () {
      let methods = {};
      methods[this.$fieldTypes.TEXT] = (val) => val;
      methods[this.$fieldTypes.HTML] = (val, originalVal) => originalVal;
      methods[this.$fieldTypes.MONEY] = this.$options.filters.toCurrency;
      methods[this.$fieldTypes.DATETIME] = this.$options.filters.toDateTime;
      methods[this.$fieldTypes.FROMNOW] = this.$options.filters.toDateTime;
      methods[this.$fieldTypes.DATE] = this.$options.filters.toDate;
      methods[this.$fieldTypes.NUMBER] = this.$options.filters.toNumber;
      methods[this.$fieldTypes.DOUBLE] = this.$options.filters.toDouble;
      methods[this.$fieldTypes.MONTH] = this.$options.filters.toMonth;
      methods[this.$fieldTypes.TIME] = this.$options.filters.toTime;
      methods[this.$fieldTypes.BIGTIME] = this.$options.filters.toTime;
      methods[this.$fieldTypes.FILE] = (anexos) => {
        return anexos.map((a) => a.url).join("; ");
      };
      methods[this.$fieldTypes.FILE2] = (anexos) => {
        return anexos.map((a) => a.url).join("; ");
      };
      return methods;
    },
    headers: function () {
      let headers = [];

      if (this.expandSubItem) {
        headers.push({
          text: "",
          type: "expand",
          value: "data-table-expand",
          class: `vuetify-table-header${this.isClient ? "-cli" : ""}`,
        });
      }

      if (this.selectionCol) {
        headers.push({
          text: "",
          type: "selection",
          value: "data-table-selection",
          class: `vuetify-table-header${this.isClient ? "-cli" : ""}`,
        });
      }

      this.cols.forEach((col) => {
        if (!col.hideInTable && col.type != this.$fieldTypes.SUBTITLE) {
          let header = {
            text: col.name,
            value:
              col.rel && col.rel.name
                ? col.key +
                  (Array.isArray(col.rel.name)
                    ? col.rel.name.join("")
                    : col.rel.name)
                : col.key,
            originalValue: col.key,
            currency: col.currency,
            type: col.type,
            nowrap: col.nowrap,
            editOnTable: col.editOnTable,
            rel: col.rel,
            align: col.align != undefined ? this.aligns["a" + col.align] : null,
            label: col,
            sortable: col.sortable !== false,
            filterable: !!col.filterable,
            class: `vuetify-table-header${this.isClient ? "-cli" : ""} ${
              col.nowrapHeader === false ? "" : "nowrap"
            } ${
              col.highlighted ? "highlighted" : ""
            }`,
            withoutMargin: !!col.withoutMargin,
            valueChanged: col.valueChanged,
          };

          if (col.width) {
            header.width = col.width;
          }

          if (col.icon) {
            header.icon = {
              click: '',
              style: '',
              text: '',
              ...col.icon,
            };
          }

          if (col.title || col.icon?.title) {
            header.title = col.title || col.icon.title;
          }

          headers.push(header);
        }
      });

      if (this.cols.find((h) => !!h.editOnTable)) {
        headers.push({
          type: "save",
          class: `vuetify-table-header${this.isClient ? "-cli" : ""}`,
          sortable: false,
        });
      }

      return headers;
    },
    hasDefaultSlot() {
      return !!this.$slots.default;
    },
    hasAsyncExport() {
      return !!this.asyncExportType;
    },
    itemsPerPageOptions: function () {
      return this.disableAllItensPerPage ? ITEMS_PER_PAGE_OPTIONS_SERVE_SIDE : ITEMS_PER_PAGE_OPTIONS;
    },
    someColsShouldFilter: function () {
      return this.headers.some(({ filterable }) => filterable);
    },
    shouldHaveFilter: function() {
      return this.hasFilter || this.someColsShouldFilter;
    },
    showCheckAllHeader: function () {
      return !this.hideCheckAll;
    },
    filterFields: function() {
      const selectableTypes = [
        this.$fieldTypes.AUTOCOMPLETE,
        this.$fieldTypes.AUTOCOMPLETE_MULTIPLE,
        this.$fieldTypes.CHECKBOXES,
        this.$fieldTypes.COMBOBOX,
        this.$fieldTypes.SELECT,
        this.$fieldTypes.SWITCH,
        this.$fieldTypes.RADIO,
      ];

      const fields = this.cols
        .filter(({ filterable }) => !!filterable)
        .reduce((prev, col) => {
          let items = [];
          let itemValue = 'value';
          let itemText = 'text';

          if (col.rel) {
            items = Array.isArray(col.rel.toEdit) ? col.rel.toEdit : this.opts[col.rel.to];
            itemValue = col.rel.key;
            itemText = col.rel.name;
          }

          const value = {
            ...col,
            autocomplete: selectableTypes.includes(col.type),
            items,
            itemValue,
            itemText,
            name: col.name,
            key: col.key,
            type: selectableTypes.includes(col.type) ? this.$fieldTypes.AUTOCOMPLETE : col.type,
          };
          return { ...prev, [col.key]: value };
        }, {});
      return fields;
    },
    atLeastOneFilteredColumn: function () {
      return Object
        .values(this.filters)
        .some((value) => Array.isArray(value) ? value.length > 0 : !!value);
    },
    hasSomeExpanded() {
      return this.items.some(({ __expandData }) => Array.isArray(__expandData));
    },
    isSomeExpanded() {
      return this.expanded.length > 0;
    },
  },
  created: function () {
    const itemsPerPage = this.disableAllItensPerPage
      ? this.getServerSidePaginationItemsPerPage
      : this.getClientSidePaginationItemsPerPage;
    this.options = { itemsPerPage };
    this.filters = cloneDeep(this.pageTableFilters);
  },
};
</script>

<style lang="scss">
@include sm {
  table {
    thead {
      display: none;
    }

    .table-v-tr {
      display: flex;
      flex-flow: column;
      align-items: flex-end;
      border-radius: 10px;
      padding: 0.5rem;
      margin: 1rem;
      box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;

      &:first-child {
        margin-top: 1rem;
      }


      #table-v-td {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        border-bottom: 1px #81B4EA solid;
        max-width: unset !important;

        &:last-child {
          border: none;
        }

        &::before {
          content: attr(data-label);
          font-weight: bold;
        }

        .mx-auto {
          margin: unset !important;
        }
      }
    }
  }
}


#vue-table >>> tbody tr :hover {
  cursor: pointer;
}

.disable-hover:hover {
  background-color: #FFFFFF !important;
  pointer-events: none;
}
</style>
