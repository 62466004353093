<template>
  <v-row>
    <v-card  class="teros-elevation">
      <v-card-title class="px-5 text-uppercase v-title-cadastro">
        Ficha de Inclusão de Business Plan
      </v-card-title>
      <v-card-text class="px-5 v-form-cadastro">
        <v-row class="ml-n4 mr-0">
          <input-v
            v-for="(field, index) of cols"
            class="pl-4"
            :colSize="field.colSize"
            :key="index"
            :label="field"
            :opts="opts"
            :valid.sync="field.valid"
            v-model="projeto[field.key]"
          ></input-v>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card  class="teros-elevation">
      <v-card-title class="px-5 text-uppercase v-title-cadastro">
        Responsáveis
      </v-card-title>
      <v-card-text class="px-5 v-form-cadastro">
        <v-row class="ml-n4 mr-0">
          <input-v
            v-for="(field, index) of colsResponsaveis"
            class="pl-4"
            :colSize="field.colSize"
            :key="index"
            :label="field"
            :opts="opts"
            :valid.sync="field.valid"
            v-model="responsaveis[field.key]"
          ></input-v>
        </v-row>
      </v-card-text>
    </v-card>
  </v-row>
</template>

<script>

export default {
  props: {
    projeto: {}
  },
  components: {
    'input-v': () => import('@/components/input-v.vue'),
  },
  // mixins: [],
  // directives: {},
  data() {
      return {
        currentStep: 0,
        responsaveis: {},
        opts: {
          status: [
            {
              id: 1,
              value: 'Em definição do BP'
            },
            {
              id: 0,
              value: 'Inativo'
            },
          ],
          responsaveis: [
            {
              id: 1,
              value: 'Em definição do BP'
            },
          ]
        },
      }
  },
  computed: {
    cols() {
      return [
        {
          key: 'nome',
          name: 'Título do BP',
          type: this.$fieldTypes.TEXT,
          rules: [{ rule: 'required' }],
          colSize: 6
        },
        {
          key: 'status',
          name: 'Status',
          type: this.$fieldTypes.SELECT,
          rel: { to:'status', key: 'id', name: 'value'},
          colSize: 6
        },
        {
          key: 'data_inicio',
          name: 'Data de início',
          type: this.$fieldTypes.DATE,
          rules: [{ rule: 'required' }],
          colSize: 6
        },
        {
          key: 'data_fim',
          name: 'Data de término',
          type: this.$fieldTypes.DATE,
          rules: [{ rule: 'required' }],
          colSize: 6
        },
      ]
    },
    colsResponsaveis() {
      return [
        {
          key: 'tecnico',
          name: 'Responsável técnico',
          type: this.$fieldTypes.SELECT,
          rel: { to: 'responsaveis', key: 'id', name: 'value'},
        },
        {
          key: 'produto',
          name: 'Responsável produto',
          type: this.$fieldTypes.SELECT,
          rel: { to: 'responsaveis', key: 'id', name: 'value'},
        },
        {
          key: 'financeiro',
          name: 'Responsável financeiro',
          type: this.$fieldTypes.SELECT,
          rel: { to: 'responsaveis', key: 'id', name: 'value'},
        },
      ]
    },
    customResource() {
      const resource = this.apiResource('/v1/captacao/tipoRubricas');
      return {
        ...resource,
        get(...params) {
          return resource.get(params).then((result) => {
            return result;
          })
        }
      }
    },
    resourceUrl() {
      return this.apiResource('/v1/captacao/tipoRubricas');
    },
  },
  // filters: {},
  // created() {},
  // mounted() {},
  // updated() {},
  // destroyed() {},
   methods: {
    setCurrentStepAndStatus(step) {
      this.currentStep = step;
    }
   },
  // watch: {},
}
</script>

<style>

</style>
