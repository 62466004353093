<script>
import moment from "moment";
import { isEqual, toNumber } from "lodash";
import { mapGetters } from "vuex";
import yearsOptions from "@/helpers/yearsOptions";

export default {
  props: {
    multiple: {
      type: Boolean,
      default: false
    },
    quarter: {
      type: [Number, Array],
      default: 1
    },
    year: {
      type: Number
    }
  },
  computed: {
    ...mapGetters(["anoBase"]),
    availableYears() {
      return this.availableYearsDesc.slice().sort();
    },
    availableYearsDesc() {
      return yearsOptions.map(({ value }) => value);
    },
    disableArrowLeft() {
      const initialYear = this.availableYears[0];
      return this.tmpYear <= initialYear;
    },
    disableArrowRight() {
      const lastIndex = this.availableYears.length - 1;
      const lastYear = this.availableYears[lastIndex];
      return this.tmpYear >= lastYear;
    },
    textFieldValue() {
      const [begin, end] = this.innerQuarters;

      if (!this.multiple || !end || begin === end) {
        return `${begin}º trimestre de ${this.innerYear}`;
      }

      return `Do ${begin}º ao ${end}º trimestre de ${this.innerYear}`;
    },
    widthWide() {
      const [begin, end] = this.innerQuarters;
      return this.multiple && end && begin !== end;
    },
  },
  data() {
    return {
      innerQuarters: [1, 1],
      innerYear: new Date().getFullYear(),
      tmpQuarters: [1, 1],
      tmpYear: new Date().getFullYear(),
      selectYearView: false,
      showMenu: false,
    };
  },
  created() {
    this.safeSetInnerQuarter(this.quarter);
    this.safeSetInnerYear(this.year || toNumber(this.anoBase));
  },
  methods: {
    isQuarterSelected(quarter) {
      const [begin, end] = this.tmpQuarters;

      if (this.multiple && end) {
        return begin <= quarter && quarter <= end;
      }

      return quarter === begin;
    },
    onClose() {
      const hasChangeYear = this.tmpYear !== this.innerYear;
      const hasChangeQuarters = this.tmpQuarters.length === 2 && !isEqual(this.tmpQuarters, this.innerQuarters);

      if (hasChangeYear) {
        this.innerYear = this.tmpYear;
        this.$emit("update:year", this.tmpYear);
        this.$store.commit("setAnoBase", this.tmpYear);
      }
      
      if (hasChangeQuarters) {
        this.innerQuarters = this.tmpQuarters;
        this.$emit("update:quarters", this.innerQuarters);
      }
      
      if (hasChangeQuarters || hasChangeYear) {
        const [begin, end] = this.tmpQuarters;
        const m1 = moment([this.tmpYear, ((begin - 1) * 3) + 1]).format('yyy-MM');
        const m2 = moment([this.tmpYear, ((end - 1) * 3) + 3]).format('yyy-MM');
        const months = [m1, m2];
        this.$emit("update:month", months);
        this.$emit("change", { months, quarters: this.tmpQuarters, year: this.tmpYear, });
      }
    },
    onOpen() {
      this.tmpQuarters = this.innerQuarters;
      this.tmpYear = this.innerYear;
    },
    safeSetInnerQuarter(value) {
      if (!value) {
        return;
      }

      const parser = () => {
        if (typeof value === 'number') {
          return [value, value];
        }

        if (Array.isArray(value) && value.length) {
          const [begin, end] = value;

          if (end) {
            return [begin, end];
          }

          return [begin, begin];
        }

        return this.innerQuarters;
      }

      const next = parser();

      if (!isEqual(next, this.innerQuarters)) {
        this.innerQuarters = next;
      }
    },
    safeSetInnerYear(value) {
      if (!value) {
        return;
      }

      const parser = () => {
        if (typeof value === 'number') {
          return value;
        }

        if (typeof value === 'string') {
          const num = toNumber(value);

          if (!isNaN(num)) {
            return value;
          }
        }

        return this.innerYear;
      }

      const next = parser();

      if (next !== this.innerYear) {
        this.innerYear = next;
      }
    },
    selectQuarter(quarter) {
      if (this.multiple) {
        if (this.tmpQuarters.length >= 2) {
          this.tmpQuarters = [quarter];
        } else {
          this.tmpQuarters.push(quarter);
          this.tmpQuarters.sort();
          this.showMenu = false;
        }
      } else {
        this.tmpQuarters = [quarter, quarter];
        this.showMenu = false;
      }
    }
  },
  watch: {
    quarter(nextValue) {
      this.safeSetInnerQuarter(nextValue);
    },
    showMenu(nextValue) {
      if (!nextValue) {
        this.onClose();
      }
    },
    year(nextValue) {
      if (nextValue) {
        this.safeSetInnerYear(nextValue);
      }
    },
  }
};
</script>

<template>
  <div>
    <v-menu
      v-model="showMenu"
      :close-on-content-click="false"
      right
      max-width="290px"
      min-width="290px"
      offset-y
      transition="scroll-y-transition"
      @input="$event || (selectYearView = false)"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-bind="attrs"
          v-on="on"
          dense
          readonly
          hide-details
          class="quarter-filter-field"
          :class="{ wide: widthWide }"
          placeholder="nenhuma"
          prepend-icon="mdi-calendar"
          :value="textFieldValue"
          @click:prepend.stop="on.click"
          @click.stop="onOpen"
        ></v-text-field>
      </template>

      <div
        v-if="selectYearView"
        class="v-picker v-card v-picker--date theme--light"
      >
        <div
          class="v-picker__body v-picker__body--no-title theme--light"
          style="width: 290px"
        >
          <div>
            <ul class="v-date-picker-years">
              <li
                v-for="(availYear, key) in availableYearsDesc"
                :key="key"
                :class="availYear === tmpYear ? 'active primary--text' : null"
                @click.stop="
                  tmpYear = availYear;
                  selectYearView = false;
                "
              >
                {{ availYear }}
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div v-else class="picker-container">
        <div class="picker-header">
          <v-btn :disabled="disableArrowLeft" icon @click.stop="tmpYear -= 1">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <div class="v-date-picker-header__value">
            <div class="accent--text">
              <v-btn text @click.stop="selectYearView = true">{{ tmpYear }}</v-btn>
            </div>
          </div>
          <v-btn :disabled="disableArrowRight" icon @click.stop="tmpYear += 1">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </div>
        <div class="picker-table">
          <v-btn
            v-for="q in 4"
            :key="q"
            :color="isQuarterSelected(q) ? 'primary' : null"
            :dark="isQuarterSelected(q)"
            :text="!isQuarterSelected(q)"
            @click.stop="selectQuarter(q)"
          >
            {{ q }}º trimestre
          </v-btn>
        </div>
      </div>
    </v-menu>
  </div>
</template>

<style lang="scss" scoped>
.quarter-filter-field.wide {
  min-width: 225px;
}

.v-date-picker-years {
  height: unset;
}

.picker-container {
  background-color: #fff;
  padding-bottom: 0.25rem;

  .picker-header {
    padding: 0.25rem 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
  }

  .picker-table {
    display: flex;
    flex-direction: column;
    padding: 0 0.75rem;
  }
}
</style>
