<template>
    <MasterDetail
      ref="masterDetail"
      :hasExportCSV="false"
      :hasExportXLSX="false"
      :opts="opts"
      :cols="cols"
      :canEdit="false"
      :canDelete="false"
      :hasNewButton="false"
      :actionBarButtons="actionBarButtons"
      :contextOptions="contextMenu"
      :customResource="customResource">
    </MasterDetail>
</template>

<script>
import MasterDetail from '@/components/master-detail.vue';
import { mapGetters } from 'vuex';

export default {
    // props: {},
    components: {
        MasterDetail,
    },
    // mixins: [],
    // directives: {},
     data() {
         return {
           opts: {
             statusRubrica: [
               {
                 id: 1,
                 value: 'Ativo'
               },
               {
                 id: 0,
                 value: 'Inativo'
               },
             ],
            },
         }
     },
    computed: {
      ...mapGetters(['clientId']),
      actionBarButtons() {
        const actionBarButtons = [];
        const that = this;
        actionBarButtons.push({
          text: "Adicionar",
          icon: "mdi-plus-box-outline",
          show: true,
          action(){
            that.$router.push({name: 'ativacaoped-projetos-form'})
          }
        });

        return actionBarButtons.filter(({ show }) => show);
      },
      contextMenu() {
        const contextOptions = [];
        // const that = this;

        contextOptions.push(
          {
            name: "Entregáveis",
            show: true,
            cb: () => {
              // this.$router.push({ name: "listagem-colaboradores", params: { clientId: row.id } });
            },
          },
          {
            name: "Timesheet",
            show: true,
            cb: () => {
              // this.$router.push({ name: "listagem-colaboradores", params: { clientId: row.id } });
            },
          },
          {
            name: "Editar",
            show: true,
            cb: (row) => {
              this.$router.push({ name: "ativacaoped-projetos-form-edicao", params: { id: row.id } });
            },
          }
        );

        return contextOptions.filter(({ show }) => show);
      },
      cols() {
        return [
          {
            key: 'id',
            name: 'Código',
            hideInform: true,
            hideInTable: true,
            width: '50px',
          },
          {
            key: 'nome',
            name: 'Título do BP',
            type: this.$fieldTypes.TEXT,
            rules: [{ rule: 'required' }],
          },
          {
            key: 'data_inicio',
            name: 'Data de início',
            type: this.$fieldTypes.DATE,
            rules: [{ rule: 'required' }],
          },
          {
            key: 'data_fim',
            name: 'Data de término',
            type: this.$fieldTypes.DATE,
            rules: [{ rule: 'required' }],
          },
          {
            key: 'status',
            name: 'Status',
            type: this.$fieldTypes.SELECT,
            rel: { to:'statusRubrica', key: 'id', name: 'value'},
            hideInform: true,
          },
        ]
      },
      resourceUrl() {
        return `/v1/ativacaoped/${this.clientId}/business-plans`;
      },
      customResource() {
        const resource = this.apiResource(this.resourceUrl);
        return {
          get(args) {
            return resource.get(args).then((result) => {
              return result.data;
            });
          }
        }
      }
    },
    // filters: {},
    // created() {},
    // mounted() {},
    // updated() {},
    // destroyed() {},
     methods: {
     },
    // watch: {},
}
</script>

<style>

</style>
