<template>
  <v-card class="teros-elevation" :color="tableColor">
    <v-card-title class="px-5 pb-0 text-uppercase v-title-cadastro">
      Status do Cálculo e Relatório de Inconsistências
    </v-card-title>
    <v-card-text>
      <div class="d-flex">
        <div class="table-v-action-button mr-3 pt-2" @click="doLoad()">
          <v-icon>mdi-refresh</v-icon>Atualizar
        </div>
        <year-select
          class="mr-3"
          style="max-width: 150px"
          v-model="anoBase"
          :aria-label="'anoBaseSelect'"
          @change="doLoad()"
        ></year-select>
        <servico-select
          class="mr-3"
          style="max-width: 255px"
          v-model="servico"
          :aria-label="'beneficioSelect'"
          @change="doLoad()"
        ></servico-select>
        <div v-if="typeof fila === 'number'" class="table-v-action-button mr-3 pt-3">
          <div>
            Fila de cálculo: {{ fila }}
          </div>
        </div>
      </div>
    </v-card-text>
    <table-v :cols="cols" :rows="registros"></table-v>
  </v-card>
</template>

<script>
import moment from "moment";
import { mapGetters } from 'vuex';

export default {
  components: {
    "table-v": () => import("@/components/table-v.vue"),
    "servico-select": () => import("@/components/servico-select.vue"),
    "year-select": () => import("@/components/year-select.vue"),
  },
  computed: {
    ...mapGetters(["clientId"]),
    isClient: function () {
      return this.getClient().isClient;
    },
    cols: function () {
      const anoBase = this.anoBase || moment().get("year");
      const columns = [
        {
          key: "statusCalculo",
          name: "Progresso Cálculo",
        },
      ];

      for (let i = 1; i <= 12; i++) {
        const month = `0${i}`.slice(-2);
        columns.push({
          key: `${anoBase}-${month}`,
          name: `${month}/${anoBase}`,
        });
      }

      return columns;
    },
  },
  data: function () {
    return {
      anoBase: null,
      servico: null,
      registros: [],
      fila: null,
    };
  },
  methods: {
    doLoad: function () {
      if (!this.anoBase || !this.servico) {
        return;
      }

      const status = [
        {
          key: "finalizadas",
          label: "Finalizadas",
        },
        {
          key: "calculadas",
          label: "Calculadas",
        },
        {
          key: "calculandoFopag",
          label: "Calculando FOPAG",
        },
        {
          key: "calculandoAjuste",
          label: "Calculando Ajuste",
        },
        {
          key: "alteradas",
          label: "Alteradas",
        },
        {
          key: "erro",
          label: "Com erro",
        },
      ];
      const resource = this.apiResource(
        `/v1/rh/clientes/${this.clientId}/progresso?ano=${this.anoBase}&trabalho=${this.servico}`
      );
      resource.get().then((response) => {
        this.fila = response.fila;
        this.registros = [];
        status.forEach(({ key, label }) => {
          const safeResponse = key in response && Array.isArray(response[key]) ? response[key] : [];
          const row = safeResponse.reduce(
            (prev, { competencia, quantidade }) => ({
              ...prev,
              [competencia]: quantidade,
            }),
            {
              statusCalculo: label,
            }
          );

          this.registros.push(row);
        });
      });
    },
  },
  watch: {
    clientId: function () {
      this.doLoad();
    },
  },
};
</script>

<style lang="scss" scoped>
.fila-label {
  text-align: center;
  height: 100%;
}
</style>
