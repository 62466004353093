<script>
import FormModal from "@/components/form-modal.vue";
import MasterDetail from "@/components/master-detail.vue";
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  components: {
    FormModal,
    MasterDetail
  },
  computed: {
    ...mapGetters(["clientId"]),
    actionBarButtons() {
      return [
        {
          text: "Recalcular",
          icon: "mdi-cog-sync",
          action: () => {
            // TODO
            window.alert("Não implementado");
          }
        },
        {
          text: "Cadastro FAF",
          icon: "mdi-square-edit-outline",
          action: () => {
            const competencia =
              this.periods.length > 0
                ? this.periods[0]
                : moment().format("yyyy-MM");
            this.createModalData = { competencia };
            this.createFAFModalOpened = true;            
          }
        },
        {
          text: "Cadastro Valor Utilizado",
          icon: "mdi-square-edit-outline",
          action: () => {
            const competencia =
              this.periods.length > 0
                ? this.periods[0]
                : moment().format("yyyy-MM");
            this.createModalData = { competencia };
            this.createModalOpened = true;
          }
        }
      ];
    },
    cols() {
      return this.periods.reduce(
        (acc, key) => [
          ...acc,
          {
            key,
            name: moment(`${key}-01`).format("MM/yyyy"),
            align: 1
          }
        ],
        [{ key: "label", name: "" }]
      );
    },
    customResource() {
      const resource = this.apiResource(
        `v1/faturamento/creditoPresumido/${this.clientId}`
      );
      const formatMoney = value => this.$options.filters.toCurrency(value);
      const formatPercent = value =>
        this.$options.filters.toDouble(value) + "%";
      const self = this;

      return {
        ...resource,
        async get(...args) {
          try {
            const response = await resource.get(...args);
            self.periods = response
              .reduce((acc, { competencia }) => {
                return acc.includes(competencia) ? acc : [...acc, competencia];
              }, [])
              .sort();
            const rows = response.reduce(
              (acc, curr) => {
                const { competencia, ...rest } = curr;
                Object.entries(rest).forEach(([key, value]) => {
                  if (key in acc) {
                    const format = ["faf_adotado", "faf_calculado"].includes(
                      key
                    )
                      ? formatPercent
                      : formatMoney;
                    acc[key][competencia] = format(value);
                  }
                });
                return acc;
              },
              {
                cred_presum_valor: {
                  label: "Valor do Crédito Presumido antes do FAF"
                },
                faf_adotado: { label: "FAF Adotado" },
                faf_calculado: { label: "FAF Calculado" },
                cred_presum_faf: {
                  label: "Crédito Presumido limitado pelo FAF"
                },
                cred_presum_utilizado: { label: "Crédito Presumido Utilizado" }
                // calculo: { label: "" },
              }
            );

            return Object.values(rows);
          } catch (error) {
            self.periods = [];
            return [];
          }
        }
      };
    },
    fields() {
      return [
        {
          key: "competencia",
          name: "Competência",
          type: this.$fieldTypes.MONTH,
          colSize: 6,
          rules: [{ rule: "required" }]
        },
        {
          key: "credito_presumido_utilizado",
          name: "Crédito Presumido Utilizado",
          type: this.$fieldTypes.MONEY,
          colSize: 6,
          rules: [{ rule: "required" }]
        }
      ];
    },
    fafFields() {
      return [
        {
          key: "competencia",
          name: "Competência",
          type: this.$fieldTypes.MONTH,
          colSize: 6,
          rules: [{ rule: "required" }]
        },
        {
          key: "aliquota",
          name: "Alíquota",
          type: this.$fieldTypes.NUMBER,
          colSize: 6,
          rules: [{ rule: "required" }]
        }
      ];
    }
  },
  data: function() {
    return {
      createModalData: {},
      createModalError: "",
      createModalOpened: false,
      createFAFModalOpened: false,
      periods: [],
      opts: {}
    };
  },
  methods: {
    async doSave(data, closeFn) {
      try {
        this.createModalError = "";
        await this.customResource.save(data);

        if (closeFn) {
          closeFn();
        }

        this.$refs.masterDetail?.doLoad();
      } catch (error) {
        this.createModalError = this.errorHandler(error);
      }
    },
    async doSaveFAF(data, closeFn) {
      try {
        const resource = this.apiResource(
          `v1/faturamento/fafCliente/${this.clientId}`
        );
        this.createModalError = "";
        await resource.save(data);

        if (closeFn) {
          closeFn();
        }
      } catch (error) {
        this.createModalError = this.errorHandler(error);
      }
    },
  }
};
</script>

<template>
  <MasterDetail
    ref="masterDetail"
    :actionBarButtons="actionBarButtons"
    class="master-detail"
    :cols="cols"
    :customResource="customResource"
    disableContextMenu
    disablePagination
    :opts="opts"
    :hasFilter="false"
    :hasMonthlyFilter="true"
    :canDelete="false"
    :canEdit="false"
    :hasNewButton="false"
  >
    <FormModal
      :cols="fields"
      :errorMessage="createModalError"
      :opened.sync="createModalOpened"
      title="Crédito Presumido Utilizado"
      :value.sync="createModalData"
      @save="doSave"
    />
    <FormModal
      :cols="fafFields"
      :errorMessage="createModalError"
      :opened.sync="createFAFModalOpened"
      title="Cadastro de FAF"
      :value.sync="createModalData"
      @save="doSaveFAF"
    />
  </MasterDetail>
</template>

<style lang="scss" scoped>
.master-detail::v-deep .v-data-table__wrapper tbody > tr {
  &:hover {
    background-color: inherit !important;
    cursor: auto;
  }
}
</style>
