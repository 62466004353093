<script>
import FormModal from "@/components/form-modal.vue";
import MasterDetail from "@/components/master-detail.vue";
import generateRandomFn from "@/helpers/seededRandom";
import yearsOptions from "@/helpers/yearsOptions";
import { toInteger } from "lodash";
import moment from "moment";

export default {
  components: {
    FormModal,
    MasterDetail
  },
  computed: {
    actionBarButtons() {
      return [
        {
          text: "Adicionar",
          icon: "mdi-plus-box-outline",
          action: () => {
            this.createModalData = {};
            this.createModalOpened = true;
          }
        }
      ];
    },
    cols() {
      return this.periods.reduce(
        (acc, key) => [
          ...acc,
          {
            key,
            name: this.isQuarter
              ? key.replace("tri", " TRI")
              : moment(`${key}-01`).format("MM/yyyy"),
            type: this.$fieldTypes.MONEY
          }
        ],
        [{ key: "label", name: "" }]
      );
    },
    customResource() {
      // TODO dados mockados, apagar depois de fazer a integração real
      // Gera dados aleatórios usando o ano base como seed
      // Assim filtrar pelo mesmo ano base irá gerar os mesmos dados "aleatórios" sempre
      const apiResourceMock = query => {
        const year = query.slice(-4);
        const isQuarter = toInteger(year) % 2 === 1;
        const rand = generateRandomFn(year);

        const itens = new Array(isQuarter ? 4 : 12)
          .fill(null)
          .map((_, index) => {
            const lucroContabil = Math.floor(rand() * 10000000) / 10000;
            const totalAdicoes = Math.floor(rand() * 10000000) / 10000;
            const totalExclusoes = Math.floor(rand() * 10000000) / 10000;
            const lucroTotal = lucroContabil + totalAdicoes - totalExclusoes;
            const exclusaoLeiDoBem = Math.floor(rand() * 10000000) / 10000;
            const irpj = Math.floor(rand() * 10000000) / 10000;
            const csll = Math.floor(rand() * 10000000) / 10000;

            const data = {
              id: index,
              lucro_contabil: lucroContabil,
              total_adicoes: totalAdicoes,
              total_exclusoes: totalExclusoes,
              lucro_total: lucroTotal,
              exclusao_lei_bem: exclusaoLeiDoBem,
              irpj,
              csll
            };

            if (isQuarter) {
              data.trimestre = `${index + 1}`;
            } else {
              const month = `${index + 1}`.padStart(2, "0");
              data.competencia = `${year}-${month}`;
            }

            return data;
          });

        // Exclui alguns registros aleatórios para não ficar uma tabela perfeira
        new Array(isQuarter ? 1 : 3).fill(null).forEach(() => {
          const i = Math.floor(rand() * itens.length - 1);
          itens.splice(i, 1);
        });

        return { trimestral: isQuarter, itens };
      };

      const self = this;

      return {
        async get({ query }) {
          try {
            // TODO inicio do mock e local pra substituir com integração real
            // const response = await this.apiResource(``);
            const response = apiResourceMock(query);
            // TODO fim do mock

            self.isQuarter = !!response.trimestral;
            self.periods = response.itens
              .reduce((acc, { competencia, trimestre }) => {
                if (self.isQuarter) {
                  const quarter = `${trimestre}tri`;
                  return acc.includes(quarter) ? acc : [...acc, quarter];
                }

                return acc.includes(competencia) ? acc : [...acc, competencia];
              }, [])
              .sort();
            const rows = response.itens.reduce(
              (acc, curr) => {
                const { competencia, trimestre, ...rest } = curr;
                const col = self.isQuarter ? `${trimestre}tri` : competencia;
                Object.entries(rest).forEach(([key, value]) => {
                  if (key in acc) {
                    acc[key][col] = value;
                  }
                });
                return acc;
              },
              {
                lucro_contabil: { label: "Lucro contábil (antes IRPJ/CSLL)" },
                total_adicoes: { label: "Total de adições" },
                total_exclusoes: { label: "Total de exclusões" },
                lucro_total: {
                  label: "Lucro após adições e exclusões"
                },
                exclusao_lei_bem: { label: "Exclusão lei do bem" },
                irpj: {
                  label: "Economia tributária — IRPJ (15% + adicional 10%)"
                },
                csll: { label: "Economia Tributária — CSLL (XXX%)" }
              }
            );

            return Promise.resolve(Object.values(rows));
          } catch (error) {
            self.isQuarter = false;
            self.periods = [];
            return Promise.resolve([]);
          }
        }
      };
    },
    fields() {
      return [
        {
          key: "lucro_contabil",
          name: "Lucro contábil (antes IRPJ/CSLL)",
          type: this.$fieldTypes.MONEY,
          colSize: 12,
          rules: [{ rule: "required" }]
        },
        {
          key: "total_adicoes",
          name: "Total de adições",
          type: this.$fieldTypes.MONEY,
          colSize: 6,
          rules: [{ rule: "required" }]
        },
        {
          key: "total_exclusoes",
          name: "Total exclusões",
          type: this.$fieldTypes.MONEY,
          colSize: 6,
          rules: [{ rule: "required" }]
        },
        {
          key: "exclusao_lei_bem",
          name: "Exclusão lei do bem",
          type: this.$fieldTypes.MONEY,
          colSize: 6,
          rules: [{ rule: "required" }]
        },
        {
          hideInform: this.isQuarter,
          key: "competencia",
          name: "Competência",
          type: this.$fieldTypes.DATE,
          colSize: 6,
          rules: [{ rule: "required" }]
        },
        {
          hideInform: !this.isQuarter,
          key: "trimestre",
          name: "Trimestre",
          type: this.$fieldTypes.SELECT,
          rel: {
            toEdit: [
              { id: 1, label: "1º trimestre" },
              { id: 2, label: "2º trimestre" },
              { id: 3, label: "3º trimestre" },
              { id: 4, label: "4º trimestre" }
            ],
            key: "id",
            name: "label"
          },
          colSize: 6,
          rules: [{ rule: "required" }]
        },
        {
          hideInform: !this.isQuarter,
          key: "ano",
          name: "Ano",
          type: this.$fieldTypes.SELECT,
          rel: { toEdit: yearsOptions, key: "value", name: "text" },
          colSize: 6,
          rules: [{ rule: "required" }]
        }
      ];
    }
  },
  data: function() {
    return {
      createModalData: {},
      createModalError: "",
      createModalOpened: false,
      isQuarter: false,
      periods: [],
      opts: {}
    };
  },
  methods: {
    async doSave(data, closeFn) {
      try {
        this.createModalError = "";
        // TODO inicio do mock e local pra substituir com integração real
        // const response = await this.apiResource();
        await this.customResource.save(data);
        // TODO fim do mock

        if (closeFn) {
          closeFn();
        }
      } catch (error) {
        this.createModalError = this.errorHandler(error);
      }
    }
  }
};
</script>

<template>
  <MasterDetail
    :actionBarButtons="actionBarButtons"
    class="master-detail"
    :cols="cols"
    :customResource="customResource"
    disableContextMenu
    disablePagination
    :opts="opts"
    has-year-filter
    :canDelete="false"
    :canEdit="false"
    :hasExportCSV="false"
    :hasNewButton="false"
  >
    <FormModal
      :cols="fields"
      :errorMessage="createModalError"
      :opened.sync="createModalOpened"
      title="Cadastro LALUR"
      :value.sync="createModalData"
    />
  </MasterDetail>
</template>

<style lang="scss" scoped>
.master-detail::v-deep .v-data-table__wrapper tbody > tr {
  &:hover {
    background-color: inherit !important;
    cursor: auto;
  }

  &:nth-of-type(4) {
    background-color: #e3f2fd;

    &:hover {
      background-color: #e3f2fd !important;
    }

    & span {
      font-weight: bold;
    }
  }
}
</style>
