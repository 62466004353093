<template>
  <MasterDetail
    formTitle="Cadastro de CFOP"
    descriptionProperty="cfop"
    :canEdit="doActions"
    :canDelete="doActions"
    :cols="cols"
    :opts="opts"
    :resourceUrl="resourceUrl"
    :filterQuery="filterQuery"
  >
    <v-select
      v-model="incentivadoSelecionado"
      :items="opts.incentivoItems"
      class="mb-n1 mt-0"
      style="width: 370px"
      prefix="Incentivo"
      dense
      clearable
      hide-details
    ></v-select>
  </MasterDetail>
</template>

<script>
import MasterDetail from "@/components/master-detail.vue";
import { UserTypeEnum } from "@/core/enums/user-types";
import { mapGetters } from 'vuex';

export default {
  components: {
    MasterDetail,
  },
  computed: {
    ...mapGetters(['user']),
    resourceUrl: function () {
      return `/v1/faturamento/cfop`;
    },
    filterQuery: function () {
      return this.incentivadoSelecionado ?  `incentivo=${this.incentivadoSelecionado}` : '';
    },
    doActions() {
      return [UserTypeEnum.ADMINISTRADOR].includes(this.user.tipo_usuario)
    }
  },
  data: function () {
    return {
      incentivadoSelecionado: null,
      cols: [
        {
          key: "id",
          name: "ID",
          align: 1,
          hideInform: true,
        },
        {
          key: "cfop",
          name: "CFOP",
          type: this.$fieldTypes.TEXT,
          rules: [{ rule: "required" }],
          colSize: 6,
        },
        {
          key: "categoria",
          name: "Categoria",
          type: this.$fieldTypes.TEXT,
          rules: [{ rule: "required" }],
          colSize: 6,
        },
        {
          key: "descricao",
          name: "Descrição",
          type: this.$fieldTypes.TEXT,
          rules: [{ rule: "required" }],
        },
        {
          key: "incentivado",
          name: "Incentivado",
          type: this.$fieldTypes.SELECT,
          rel: { to: "incentivoItems" },
          rules: [{ rule: "required" }],
          colSize: 3,
        },
        {
          key: "comp_ped",
          name: "Compromisso P&D",
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: "comp_ped", key: "name", name: "name" },
          rules: [{ rule: "required" }],
          colSize: 9,
        },
        {
          key: "credito_presumido",
          name: "Crédito Presumido",
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: "creditoPresumido", key: "name", name: "name" },
          rules: [{ rule: "required" }],
          colSize: 12,
        },
        {
          key: "comentario",
          name: "Comentário",
          type: this.$fieldTypes.TEXTAREA,
          hideInform: false,
          hideInTable: true,
          editable: true,
        },
      ],
      opts: {
        comp_ped: [
          { name: "OPERAÇÃO NÃO INCENTIVADA" },
          { name: "DEVOLUÇÃO DE VENDA INCENTIVADA" },
          { name: "DEVOLUÇÃO DE VENDA NÃO INCENTIVADA" },
          { name: "DEVOLUÇÃO EXPORTAÇÃO" },
          { name: "DEVOLUÇÃO ZFM" },
          { name: "EXPORTAÇÃO" },
          { name: "VENDA INCENTIVADA" },
          { name: "VENDA ZFM" },
          { name: "Nao" },
        ],
        incentivoItems: [
          { text: 'Sim', value: 'Sim' },
          { text: 'Não', value: 'Nao' },
        ],
        creditoPresumido: [
          { name: "Saída Incentivada" },
          { name: "Devolução de Saída Incentivada" },
          { name: "Devolução de Saída Não Incentivada" },
          { name: "Operação Não Incentivada" },
        ],
      },
    };
  },
};
</script>