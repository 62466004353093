<template>
  <v-card>
    <v-card-title class="px-5 v-title-cadastro">Resumo Crédito Financeiro — Sigplani</v-card-title>
    <v-card-text class="pb-0">
      <v-row class="ml-n3 mr-0 d-flex align-baseline">
        <v-col :cols="12" class="d-flex align-center">
          <monthly-filter style="width: 270px" @change="doLoad"></monthly-filter>
          <v-menu offset-y max-width="200">
            <template v-slot:activator="{ on, attrs }">
              <div
                class="table-v-action-button ml-3 my-auto"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-cog-sync</v-icon>
                Opções de Cálculo
              </div>
            </template>
            <v-list>
              <v-list-item>
                <v-list-item-title
                  class="table-v-action-button"
                  @click="recalcularDialog = true"
                >
                  <v-icon>mdi-cog-sync</v-icon> Recalcular
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title
                  class="table-v-action-button"
                  @click="finalizarDialog = true"
                >
                  <v-icon>mdi-archive-cog</v-icon> Finalizar Cálculo
                </v-list-item-title>
              </v-list-item>
              <v-list-item v-if="userIsAdmin">
                <v-list-item-title
                  class="table-v-action-button"
                  @click="desFinalizarDialog = true"
                >
                  <v-icon>mdi-archive-sync</v-icon>Reabrir Cálculo
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <div class="table-v-action-button ml-3" @click="doRPAIntegration()">
            <v-icon icon>mdi-cloud-arrow-up-outline</v-icon>
            Realizar integração RPA
          </div>
        </v-col>
        <v-col :cols="12">
          <v-row>
            <v-col
              v-for="({ color, icon, label, title }, index) in statusCalcTri"
              class="d-flex justify-content-around"
              :cols="1"
              :key="index"
              :title="title"
            >
              <strong>{{ label }}</strong>
              <v-icon :color="color">{{ icon }}</v-icon>
            </v-col>
            <v-col v-if="tipoRelatorioRDA" :col="2">
              <strong>Relatório RDA: </strong>
              <i>{{ tipoRelatorioRDA }}</i>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <template v-if="!isLoading && !!resumo.qtde_produzida">
        <v-row class="ml-n4 mr-0">
          <template v-for="(i, k) in cols">
            <input-v
              class="pl-4"
              v-if="
                typeof resumo[i.key] !== 'undefined' ||
                i.type == $fieldTypes.SUBTITLE
              "
              v-model="resumo[i.key]"
              :key="k"
              :valid.sync="i.valid"
              :label="i"
              :editable="false"
              :colSize="i.colSize"
            ></input-v>
          </template>
        </v-row>
        <v-row class="ml-n4 mr-0 v-label-input">
          <v-col :cols="2" class="pl-4"></v-col>
          <v-col
            :style="{ maxWidth: '150px' }"
            :cols="2"
            class="pl-4 align-self-end"
          >
            Investimentos em P,D&I realizado por faturamento nas opções dos incisos I, II, III e IV
          </v-col>
          <v-col
            :style="{ maxWidth: '150px' }"
            :cols="2"
            class="pl-4 align-self-end"
          >
            Faturamento Total dos Produtos Incentivados
          </v-col>
          <v-col
            :style="{ maxWidth: '150px' }"
            :cols="2"
            class="pl-4 align-self-end"
          >
            Valor de Crédito
          </v-col>
          <v-col
            :style="{ maxWidth: '150px' }"
            :cols="2"
            class="pl-4 align-self-end"
          >
            Limite de Crédito
          </v-col>
          <v-col
            :style="{ maxWidth: '150px' }"
            :cols="2"
            class="pl-4 align-self-end"
          >
            Valor de Crédito Excedente
          </v-col>
          <v-col
            :style="{ maxWidth: '150px' }"
            :cols="2"
            class="pl-4 align-self-end"
          >
            Valor de Crédito Remanescente
          </v-col>
        </v-row>
        <v-row class="ml-n4 mr-0 v-label-input">
          <v-col :cols="2" class="pl-4 align-self-end">
            Sudam/Sudene/Centro-Oeste
          </v-col>
          <col-val
            :style="{ maxWidth: '150px' }"
            :value="resumo.investimento_co"
          ></col-val>
          <col-val
            :style="{ maxWidth: '150px' }"
            :value="resumo.faturamento_co"
          ></col-val>
          <col-val
            :style="{ maxWidth: '150px' }"
            :value="resumo.valorcredito_co"
          ></col-val>
          <col-val
            :style="{ maxWidth: '150px' }"
            :value="resumo.limitecredito_co"
          ></col-val>
          <col-val
            :style="{ maxWidth: '150px' }"
            :value="resumo.valorpdi_co"
          ></col-val>
          <col-val
            :style="{ maxWidth: '150px' }"
            :value="resumo.valorpdi_co_remanescente"
          ></col-val>
        </v-row>
        <v-row class="ml-n4 mr-0 v-label-input">
          <v-col :cols="2" class="pl-4 align-self-end">
            Sudam/Sudene/C.O. (Tecn. Nac.)
          </v-col>
          <col-val
            :style="{ maxWidth: '150px' }"
            :value="resumo.investimento_co_tecnac"
          ></col-val>
          <col-val
            :style="{ maxWidth: '150px' }"
            :value="resumo.faturamento_co_tecnac"
          ></col-val>
          <col-val
            :style="{ maxWidth: '150px' }"
            :value="resumo.valorcredito_co_tecnac"
          ></col-val>
          <col-val
            :style="{ maxWidth: '150px' }"
            :value="resumo.limitecredito_co_tecnac"
          ></col-val>
          <col-val
            :style="{ maxWidth: '150px' }"
            :value="resumo.valorpdi_co_tecnac"
          ></col-val>
          <col-val
            :style="{ maxWidth: '150px' }"
            :value="resumo.valorpdi_co_tecnac_remanescente"
          ></col-val>
        </v-row>
        <v-row class="ml-n4 mr-0 v-label-input">
          <v-col :cols="2" class="pl-4 align-self-end">Outras Regiões</v-col>
          <col-val
            :style="{ maxWidth: '150px' }"
            :value="resumo.investimento_or"
          ></col-val>
          <col-val
            :style="{ maxWidth: '150px' }"
            :value="resumo.faturamento_or"
          ></col-val>
          <col-val
            :style="{ maxWidth: '150px' }"
            :value="resumo.valorcredito_or"
          ></col-val>
          <col-val
            :style="{ maxWidth: '150px' }"
            :value="resumo.limitecredito_or"
          ></col-val>
          <col-val
            :style="{ maxWidth: '150px' }"
            :value="resumo.valorpdi_or"
          ></col-val>
          <col-val
            :style="{ maxWidth: '150px' }"
            :value="resumo.valorpdi_or_remanescente"
          ></col-val>
        </v-row>
        <v-row class="ml-n4 mr-0 v-label-input">
          <v-col :cols="2" class="pl-4 align-self-end">
            Outras Regiões (Tecn. Nacional)
          </v-col>
          <col-val
            :style="{ maxWidth: '150px' }"
            :value="resumo.investimento_or_tecnac"
          ></col-val>
          <col-val
            :style="{ maxWidth: '150px' }"
            :value="resumo.faturamento_or_tecnac"
          ></col-val>
          <col-val
            :style="{ maxWidth: '150px' }"
            :value="resumo.valorcredito_or_tecnac"
          ></col-val>
          <col-val
            :style="{ maxWidth: '150px' }"
            :value="resumo.limitecredito_or_tecnac"
          ></col-val>
          <col-val
            :style="{ maxWidth: '150px' }"
            :value="resumo.valorpdi_or_tecnac"
          ></col-val>
          <col-val
            :style="{ maxWidth: '150px' }"
            :value="resumo.valorpdi_or_tecnac_remanescente"
          ></col-val>
        </v-row>
        <v-row class="ml-n4 mr-0 v-label-input">
          <v-col :cols="2" class="pl-4 align-self-end">Total</v-col>
          <col-val
            :style="{ maxWidth: '150px' }"
            :value="resumo.investimento_total"
          ></col-val>
          <col-val
            :style="{ maxWidth: '150px' }"
            :value="resumo.faturamento_total"
          ></col-val>
          <col-val
            :style="{ maxWidth: '150px' }"
            :value="resumo.valorcredito_total"
          ></col-val>
          <col-val
            :style="{ maxWidth: '150px' }"
            :value="resumo.limitecredito_total"
          ></col-val>
          <col-val
            :style="{ maxWidth: '150px' }"
            :value="resumo.valorpdi_total"
          ></col-val>
          <col-val
            :style="{ maxWidth: '150px' }"
            :value="resumo.valorpdi_total_remanescente"
          ></col-val>
        </v-row>
        <v-row class="ml-n4 mr-0 v-label-input">
          <v-col :cols="2" class="pl-4"></v-col>
          <v-col :cols="3" class="align-self-end">Valor de Crédito</v-col>
          <v-col
            v-if="showValorCreditoRemanescente"
            :cols="3"
            class="align-self-end"
          >
            Valor de Crédito Remanescente
          </v-col>
          <v-col v-else :cols="3" class="align-self-end">
            Valor de Crédito Excedente
          </v-col>
        </v-row>
        <v-row class="ml-n4 mr-0 mb-6 v-label-input">
          <v-col :cols="2" class="pl-4 align-self-end">
            Crédito Disponível
          </v-col>
          <col-val :cols="3" :value="resumo.valor_credito"></col-val>
          <col-val
            :cols="3"
            :value="valorCreditoExcedenteRemanescente"
          ></col-val>
        </v-row>
      </template>

      <v-row v-else-if="!resumo.qtde_produzida">
        <v-col>Não existem dados para o período selecionado.</v-col>
      </v-row>

      <v-row v-else>
        <v-col>Carregando...</v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="px-5 pb-4 pt-0">
      <v-spacer></v-spacer>
      <v-btn
        depressed
        color="primary"
        dark
        class="px-4"
        @click="goToSigplaniProdutos()"
      >
        Ver Produtos
      </v-btn>
    </v-card-actions>
    <kore-modal
      :value="recalcularDialog || finalizarDialog || desFinalizarDialog"
      :title="getModalTitle"
      @confirm="doExecute()"
      @cancel="closeModal()"
    >
      <p>
        <b>
          {{ getModalText }}:
        </b>
      </p>
      <v-menu
        v-model="dateMenu"
        :close-on-click="setCompetencia()"
        :close-on-content-click="false"
        transition="scroll-y-transition"
        offset-y
        right
        max-width="290px"
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            :value="intervaloCalculo | toMonthPeriod"
            dense
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            @click:prepend.stop="on.click"
            v-on="on"
            hide-details
            class="mb-n1 mt-0 mr-3"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="intervaloCalculo"
          range
          type="month"
          @click:month="dateMenu = intervaloCalculo.length != 2"
          no-title
        ></v-date-picker>

      </v-menu>
      <v-alert v-if="finalizarDialog" class="mt-2" dense type="warning">
        <b>Cuidado</b>, fechar o resumo irá fechar o cálculo de todos os módulos relevantes do Teros para o mesmo período.
      </v-alert>
      <v-alert v-else-if="desFinalizarDialog" class="mt-2" dense type="info">
        <p>Abrir o resumo não abre nenhum módulo, apenas libera o cálculo desse resumo.</p>

        <p class="mb-0">Para alterar dados, é necessário abrir diretamente o módulo desejado.</p>
      </v-alert>
    </kore-modal>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { ReportTypeLabel } from "@/core/enums/dispendio-li-rda";

export default {
  components: {
    "col-val": () =>
      import("@/templates/faturamento/sigplani_resumo.col-val.vue"),
    "input-v": () => import("@/components/input-v.vue"),
    "monthly-filter": () => import("@/components/monthly-filter.vue"),
    "kore-modal": () => import("@/components/kore-modal.vue"),
  },
  computed: {
    ...mapGetters(['clientId', 'intervaloCompetencia', 'userIsAdmin']),
    isClient: function () {
      return this.getClient().isClient;
    },
    intervalo() {
      return this.$store.getters.intervaloCompetencia
    },
    resourceUrl: function () {
      return `/v1/faturamento/resumocalc/${this.clientId}`;
    },
    resource: function () {
      return this.apiResource(this.resourceUrl);
    },
    getModalText: function () {
      let base = 'Selecione o período para';
      if (this.recalcularDialog) {
        return `${base} recalcular:`;
      }

      if (this.finalizarDialog) {
        return `${base} fechar o cálculo:`;
      }

      return `${base} abrir o cálculo:`;
    },
    getModalTitle: function () {
      if (this.recalcularDialog) {
        return 'Recalcular resumo';
      }

      if (this.finalizarDialog) {
        return 'Fechar cálculo resumo';
      }

      return 'Abrir cálculo resumo';
    },
    tipoRelatorioRDA: function () {
      return this.relatorioRDA ? ReportTypeLabel[this.relatorioRDA] : null;
    },
    showValorCreditoRemanescente: function () {
      const valorpdi_total = this.resumo.valorpdi_total || 0;
      const valorpdi_total_remanescente =
        this.resumo.valorpdi_total_remanescente || 0;
      return valorpdi_total - valorpdi_total_remanescente < 0;
    },
    statusCalcTri: function () {
      return Array(4)
        .fill(0)
        .map((_, index) => {
          const status = this.status[index + 1];
          return !status
            ? null
            : {
                color: status === 'aberto' ? 'green' : 'info',
                icon: status === 'aberto' ? 'mdi-lock-open' : 'mdi-lock-check',
                label: `${index + 1}TRI`,
                title: status === 'aberto' ? 'Aberto' : 'Fechado',
              };
        })
        .filter((status) => status);
    },
    valorCreditoExcedenteRemanescente: function () {
      const valorpdi_total = this.resumo.valorpdi_total || 0;
      const valorpdi_total_remanescente =
        this.resumo.valorpdi_total_remanescente || 0;
      return Math.abs(valorpdi_total - valorpdi_total_remanescente);
    },
  },
  data: function () {
    return {
      dateMenu: false,
      isLoading: false,
      recalcularDialog: false,
      finalizarDialog: false,
      desFinalizarDialog: false,
      resumo: {},
      status: {},
      relatorioRDA: "",
      cols: [
        {
          key: "_subtitle1",
          name: "Faturamento, Exportações, Quantidade Produzida e Tributos",
          type: this.$fieldTypes.SUBTITLE,
        },
        {
          key: "total_mi",
          name: "Faturamento Bruto no Mercado Interno",
          type: this.$fieldTypes.MONEY,
          nowrap: true,
          colSize: 7,
          editable: false,
          hideInTable: true,
        },
        {
          key: "total_ex",
          name: "Exportações (R$)",
          type: this.$fieldTypes.MONEY,
          nowrap: true,
          colSize: 5,
          editable: false,
          hideInTable: true,
        },
        {
          key: "total_zf",
          name: "Vendas para a ZFM (Zona Franca de Manaus)",
          type: this.$fieldTypes.MONEY,
          nowrap: true,
          colSize: 7,
          editable: false,
          hideInTable: true,
        },
        {
          key: "qtde_produzida",
          name: "Quantidade Produzida",
          type: this.$fieldTypes.NUMBER,
          colSize: 5,
          editable: false,
          hideInTable: true,
        },
        {
          key: "_subtitle2",
          name: "Recolhimento sobre o faturamento bruto",
          type: this.$fieldTypes.SUBTITLE,
        },
        {
          key: "ipi",
          name: "IPI",
          type: this.$fieldTypes.MONEY,
          nowrap: true,
          colSize: 4,
          editable: false,
          hideInTable: true,
        },
        {
          key: "piscofins",
          name: "PIS/Cofins",
          type: this.$fieldTypes.MONEY,
          nowrap: true,
          colSize: 4,
          editable: false,
          hideInTable: true,
        },
        {
          key: "icms",
          name: "ICMS",
          type: this.$fieldTypes.MONEY,
          nowrap: true,
          colSize: 4,
          editable: false,
          hideInTable: true,
        },
        {
          key: "aquisicoes_bens",
          name: "Aquisições de Bens de Informática Incentivados",
          type: this.$fieldTypes.MONEY,
          nowrap: true,
          colSize: 7,
          editable: false,
          hideInTable: true,
        },
        {
          key: "devolucoes_icmsst",
          name: "Devoluções + ICMS (ST)",
          type: this.$fieldTypes.MONEY,
          nowrap: true,
          colSize: 5,
          editable: false,
          hideInTable: true,
        },
        {
          key: "_subtitle3",
          name: "Incentivo Auferido",
          type: this.$fieldTypes.SUBTITLE,
        },
        {
          key: "ipi_auferido",
          name: "IPI",
          type: this.$fieldTypes.MONEY,
          nowrap: true,
          colSize: 7,
          editable: false,
          hideInTable: true,
        },
        {
          key: "icms_auferido",
          name: "ICMS",
          type: this.$fieldTypes.MONEY,
          nowrap: true,
          colSize: 5,
          editable: false,
          hideInTable: true,
        },
      ],
      intervaloCalculo: [],
    };
  },
  methods: {
    closeModal: function () {
      this.finalizarDialog = false;
      this.desFinalizarDialog = false;
      this.recalcularDialog = false;
    },
    doExecute: function () {
      if (
        this.intervalo.lenght < 2 ||
        (!this.recalcularDialog && !this.finalizarDialog && !this.desFinalizarDialog)
      ) {
        return;
      }

      const [ini, fim] = this.intervalo;
      const query = `competenciaIni=${ini}&competenciaFim=${fim}`;
      let url = '';

      if (this.recalcularDialog) {
        url = `v1/faturamento/calculoresumo/${this.clientId}?${query}`;
      } else if (this.desFinalizarDialog) {
        url = `v1/clientes/${this.clientId}/abrir/creditoFinanceiro?${query}`;
      } else {
        url = `v1/clientes/${this.clientId}/fechar/creditoFinanceiro?${query}`;
      }

      this.apiResource(url).save().then(() => this.doLoad()).then(() => this.closeModal());
    },
    doLoad: function (competencia = this.intervaloCompetencia) {
      this.isLoading = true;
      const [ini, fim] = competencia;
      this.intervaloCalculo = competencia;
      return this.resource
        .get({ query: `competenciaIni=${ini}&competenciaFim=${fim}` })
        .then(({ info, resumo }) => {
          this.resumo = resumo ?? {};
          this.status = info.status ?? [];
          this.relatorioRDA = info.relatorioRDA ?? "";
        })
        .finally(() => this.isLoading = false);
    },
    goToSigplaniProdutos: function () {
      this.$router.push({ name: "sigplani-produtos" });
    },
    doRPAIntegration: function() {
      const [ini, fim] = this.intervalo;
      this.apiResource(`v1/rpa/${this.clientId}/preenchimento`)
        .save({
          "plataforma": "novo-sigplani-credito-financeiro",
          "competenciaIni": `${ini}`,
          "competenciaFim": `${fim}`
        }).then((ret) => {
          if(!ret.error) {
            this.$notify({
              group: "geral",
              duration: 7000,
              type: "success",
              title: "Preenchimento enviado para fila",
              text: `${ret.message}`,
            })
          }
        })
    },
    setCompetencia() {
      this.setFilters({ monthly: this.intervaloCalculo });
      if(this.intervaloCalculo == 2) return true
    }
  },
  watch: {
    clientId: function () {
      this.doLoad();
    },
  },
};
</script>
