<template>
  <v-row>
    <v-col cols="8">
      <v-item-group v-model="currentStep" class="text-center d-flex justify-content-between" mandatory>
        <v-item v-slot="{ active }">
          <v-btn dense text tile :style="{
            display: 'flex',
            'border-radius': '5px 0',
            opacity: active ? 1 : 0.8,
            'border-bottom': currentStep >= 0 ? '5px solid green' : 'none',
            padding: '0 1rem',
          }" @click="setCurrentStepAndStatus(0)">
            Business Plan
          </v-btn>
        </v-item>
        <v-item v-slot="{ active }">
          <v-btn dense text tile :style="{
            display: 'flex',
            'border-radius': '5px 0',
            opacity: active ? 1 : 0.8,
            'border-bottom': currentStep >= 1 ? '5px solid green' : 'none',
            padding: '0 1rem',
          }" @click="setCurrentStepAndStatus(1)" :disabled="true">
            Iniciativa
          </v-btn>
        </v-item>
        <v-item v-slot="{ active }">
          <v-btn dense text tile :style="{
            display: 'flex',
            'border-radius': '5px 0',
            opacity: active ? 1 : 0.8,
            'border-bottom': currentStep >= 2 ? '5px solid green' : 'none',
            padding: '0 1rem',
          }" @click="setCurrentStepAndStatus(2)" :disabled="true">
            Roadmap
          </v-btn>
        </v-item>
        <v-item v-slot="{ active }">
          <v-btn dense text tile :style="{
            display: 'flex',
            'border-radius': '5px 0',
            opacity: active ? 1 : 0.8,
            'border-bottom': currentStep >= 3 ? '5px solid green' : 'none',
            width: 'auto',
            padding: '0 1rem',
          }" @click="setCurrentStepAndStatus(3)">
            Participantes
          </v-btn>
        </v-item>
        <v-item v-slot="{ active }">
          <v-btn dense text tile :style="{
            display: 'flex',
            'border-radius': '5px 0',
            opacity: active ? 1 : 0.8,
            'border-bottom': currentStep >= 4 ? '5px solid green' : 'none',
            width: 'auto',
            padding: '0 1rem',
          }" @click="setCurrentStepAndStatus(4)">
            CostPeople
          </v-btn>
        </v-item>
        <v-item v-slot="{ active }">
          <v-btn dense text tile :style="{
            display: 'flex',
            'border-radius': '5px 0',
            opacity: active ? 1 : 0.8,
            'border-bottom': currentStep >= 5 ? '5px solid green' : 'none',
            width: 'auto',
            padding: '0 1rem',
          }" @click="setCurrentStepAndStatus(5)" :disabled="true">
            Memória de cálculo
          </v-btn>
        </v-item>
      </v-item-group>
      <v-window v-model="currentStep">
        <v-window-item>
          <BPForm ref="BPForm" :projeto="dadosBP"></BPForm>
        </v-window-item>
        <v-window-item>
          <p>Iniciativa</p>
        </v-window-item>
        <v-window-item>
          <p>Roadmap</p>
        </v-window-item>
        <v-window-item>
          <Participantes></Participantes>
        </v-window-item>
        <v-window-item>
          <CostPeople></CostPeople>
        </v-window-item>
        <v-window-item>
          <p>Memória de cálculo</p>
        </v-window-item>
      </v-window>
    </v-col>
    <v-col class="mr-3">
      <v-card class="p-3">
        <v-card-subtitle class="pb-0">
          <v-alert
            color="blue"
            dense
            text
            v-if="false"
            class="v-label-input mt-1 mb-0"
          >
            Anexos cujo upload não foi concluído são desconsiderados no processo de salvamento.
          </v-alert>
          <v-alert
            dense
            text
            type="error"
            v-if="false"
            class="v-label-input mt-1 mb-0"
          >
            Anexos com erro são exibidos aqui, mas são desconsiderados no processo de salvamento.
          </v-alert>
        </v-card-subtitle>
        <v-card-subtitle class="pb-1">
          <uploader-v
            v-model="projeto.anexo"
            v-slot="{ selectFiles }"
            @input="anexosChanged"
          >
            <v-btn
              class="mx-auto d-block px-2"
              dense
              text
              @click="selectFiles()"
            >
              <v-icon left>mdi-paperclip</v-icon>
              Clique aqui para anexar arquivos
            </v-btn>
          </uploader-v>
        </v-card-subtitle>
        <v-divider></v-divider>
        <v-card-text class="py-0">
          <template v-if="!projeto.anexo.length">
            <v-alert
              border="left"
              class="v-label-input"
              color="primary"
              dense
              outlined
              text
              type="info"
            >
              Não existem anexos.
            </v-alert>
          </template>
          <v-card
            elevation="0"
            class="mb-2"
            v-for="(anexo, akey) in projeto.anexo"
            :key="akey"
          >
            <v-progress-linear
              :color="anexo.error ? 'error' : 'secondary'"
              :value="anexo.percent"
              :striped="anexo.uploading"
              height="2em"
            >
              <v-card-text class="p-2">
                <v-row
                  justify="space-between"
                  align="center"
                  no-gutters
                  class="py-2 pl-2 pr-0"
                >
                  <span
                    class="v-label-input text-truncate"
                    :style="{ width: anexo.uploading ? '260px' : '290px' }"
                  >
                    <v-icon left>
                      {{
                        anexo.error
                          ? "mdi-file-document-alert"
                          : "mdi-file-document"
                      }}
                    </v-icon>
                    <span
                      :title="getAnexoTitle(anexo)"
                    >
                      {{ anexo.error || anexo.nome }}
                    </span>
                  </span>
                  <span v-if="anexo.uploading" class="v-label-input">
                    {{ anexo.percent }}%
                  </span>
                  <v-btn
                    v-if="isClient || !idProjeto"
                    :disabled="!canEdit"
                    small
                    icon
                    @click="projeto.anexo.splice(akey, 1)"
                  >
                    <v-icon small>mdi-close-circle-outline</v-icon>
                  </v-btn>
                  <v-btn v-else small icon :href="anexo.url" target="_blank">
                    <v-icon small>mdi-download</v-icon>
                  </v-btn>
                </v-row>
              </v-card-text>
            </v-progress-linear>
          </v-card>
        </v-card-text>
      </v-card>
      <v-card class="mt-5">
        <v-card-actions class="d-flex justify-content-between">
          <v-btn color="primary" depressed @click="currentStep--">
            <!-- <v-icon>mdi-chevron-left</v-icon> -->
             Cancelar
          </v-btn>
          <v-btn color="secondary" depressed @click="saveBP()">
            <!-- <v-icon>mdi-chevron-right</v-icon> -->
             Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>

import BPForm from './bp.ativacao.form.vue';
import { cloneDeep } from 'lodash';
import { mapGetters } from 'vuex';

export default {
  // props: {},
  components: {
    BPForm,
    'uploader-v': () => import('@/components/uploader-v.vue'),
    'Participantes': () => import('@/templates/ativacaoped/participantes/participantes.ativacao.vue'),
    'CostPeople': () => import('@/templates/ativacaoped/costpeople/costpeople.ativacao.vue'),
  },
  // mixins: [],
  // directives: {},
  data() {
      return {
        currentStep: 0,
        projeto: {
          anexo: [],
        },
        anexos: [],
        opts: {
          statusRubrica: [
            {
              id: 1,
              value: 'Ativo'
            },
            {
              id: 0,
              value: 'Inativo'
            },
          ],
        },
        dadosBP: {},
      }
  },
  computed: {
    ...mapGetters(['clientId']),
    businessPlanId() {
      return this.$route.params.id || this.dadosBP.id;
    },
    cols() {
      return [
        {
          key: 'id',
          name: 'Código',
          hideInform: true,
          hideInTable: true,
          width: '50px',
        },
        {
          key: 'titulo',
          name: 'Título do BP',
          type: this.$fieldTypes.TEXT,
          rules: [{ rule: 'required' }],
        },
        {
          key: 'data_inicio',
          name: 'Data de início',
          type: this.$fieldTypes.TEXT,
          rules: [{ rule: 'required' }],
        },
        {
          key: 'data_termino',
          name: 'Data de término',
          type: this.$fieldTypes.TEXT,
          rules: [{ rule: 'required' }],
        },
        {
          key: 'status',
          name: 'Status',
          type: this.$fieldTypes.SELECT,
          rel: { to:'statusRubrica', key: 'id', name: 'value'},
          hideInform: true,
        },
      ]
    },
    customResource() {
      const resource = this.apiResource('/v1/captacao/tipoRubricas');
      return {
        ...resource,
        get(...params) {
          return resource.get(params).then((result) => {
            return result;
          })
        }
      }
    },
    resourceUrl() {
      return this.apiResource(`/v1/ativacaoped/${this.clientId}/business-plans`);
    },
  },
  // filters: {},
  created() {
    this.getBP();
  },
  // mounted() {},
  // updated() {},
  // destroyed() {},
   methods: {
    setCurrentStepAndStatus(step) {
      this.currentStep = step;
    },
    anexosChanged: function (nextAnexos) {
      this.anexos = cloneDeep(nextAnexos);
    },
    getBP() {
      this.resourceUrl.get(this.businessPlanId).then((result) => {
        return this.dadosBP = result.data;
      });
    },
    saveBP() {
      const dadosBP = this.$refs.BPForm.$data.projeto;
      this.resourceUrl.save(dadosBP).then((result) => {
        this.$router.push({ name: 'ativacaoped-projetos-form-edicao', params: { id: result.data.id } })
        return this.dadosBP = result.data;
      });
    }
   },
  // watch: {},
}
</script>

<style>

</style>
