<template>
  <v-row>
    <v-col cols="8">
      <v-card class="teros-elevation">
        <v-card-title class="px-5 text-uppercase v-title-cadastro">
          Costpeople
        </v-card-title>
        <v-card-text class="px-5 v-form-cadastro">

        </v-card-text>
      </v-card>
    </v-col>
    <v-col>

    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    // props: {},
    components: {
    },
    // mixins: [],
    // directives: {},
    data() {
        return {
          opts: {
            statusRubrica: [
              {
                id: 1,
                value: 'Ativo'
              },
              {
                id: 0,
                value: 'Inativo'
              },
            ],
            colaboradores: []
          },
        }
    },
    computed: {
      ...mapGetters(['clientId']),
      costPeopleCols() {
        return [
          {
            key: 'colaborador',
            name: 'Colaborador',
            type: this.$fieldTypes.SELECT,
            rel: { to: 'colaboradores', key: 'id', name: 'nome' }
          },
          {
            key: 'colaborador',
            name: 'E-mail',
            type: this.$fieldTypes.SELECT,
            rel: { to: 'colaboradores', key: 'id', name: 'email' }
          },
          {
            key: 'senioridade',
            name: 'Senioridade',
            type: this.$fieldTypes.SELECT,
            rel: { to: 'colaboradores', key: 'id', name: 'email' }
          },
          {
            key: 'customedio',
            name: 'Custo médio FP&A',
            type: this.$fieldTypes.SELECT,
            rel: { to: 'colaboradores', key: 'id', name: 'email' }
          },
        ]
      },
      cols() {
        return [
          {
            key: 'id',
            name: 'Código',
            hideInform: true,
            hideInTable: true,
            width: '50px',
          },
          {
            key: 'nome',
            name: 'Nome',
            type: this.$fieldTypes.TEXT,
            rules: [{ rule: 'required' }],
          },
          {
            key: 'email',
            name: 'E-mail',
            type: this.$fieldTypes.TEXT,
            rules: [{ rule: 'required' }],
          },
          {
            key: 'senioridade',
            name: 'Senioridade',
            type: this.$fieldTypes.TEXT,
            rules: [{ rule: 'required' }],
          },
          {
            key: 'jan/2024',
            name: 'Formação técnica',
            type: this.$fieldTypes.TEXT,
          },
        ]
      },
    },
    // filters: {},
    // created() {},
    // mounted() {},
    // updated() {},
    // destroyed() {},
     methods: {
     },
    // watch: {},
}
</script>

<style>

</style>
