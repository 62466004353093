<template>
  <v-row>
    <v-col :cols="isVisibleViagensDiarias ? 6 : 12">
      <v-row>
        <v-card class="teros-elevation">
          <v-card-title class="px-5 text-uppercase v-title-cadastro">
            {{ isEditForm ? "Edição de despesas" : "Cadastro de despesas" }}
          </v-card-title>
          <v-card-text>
            <v-row class="mb-1">
              <InputV
                class="px-3"
                v-for="(col, index) in cols"
                :key="index"
                :colSize="col.colSize"
                :label="col"
                :opts="opts"
                :valid.sync="col.valid"
                v-model="dispendio[col.key]"
                @changed="updateFields(col.key, $event)"
                @blur="atualizaMoney(col.key)"
                :style="{
                  display: col.hideInform ? 'none' : ''
                }"
              />
            </v-row>
          </v-card-text>
          <v-card-actions
            class="justify-end px-3"
            v-if="!isVisibleViagensDiarias"
          >
            <v-btn
              style="width: 150px"
              color="secondary"
              depressed
              @click="close()"
            >
              Cancelar
            </v-btn>
            <v-btn
              style="width: 150px"
              color="primary"
              depressed
              @click="saveDispendios()"
            >
              Salvar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-row>
    </v-col>
    <v-col
      v-if="isVisibleViagensDiarias"
      class="d-flex justify-content-between flex-column"
    >
      <v-card>
        <v-card-title>
          {{
            isEditForm
              ? "Edição de viagens e diárias"
              : "Cadastro de viagens e diárias"
          }}
        </v-card-title>
        <v-card-text>
          <v-row>
            <InputV
              class="px-5 py-0"
              v-for="(col, index) in viagensDiariasCols"
              :key="index"
              :colSize="col.colSize"
              :label="col"
              :opts="opts"
              :valid.sync="col.valid"
              v-model="dispendio[col.key]"
              @changed="updateFields(col.key, $event)"
              :style="{
                display: col.hideInform ? 'none' : ''
              }"
            />
          </v-row>
        </v-card-text>
      </v-card>
      <v-card
        class="teros-elevation action-buttons-wrap"
        style="position: fixed;
            bottom: 0.5rem;
            right: 5.5rem;
            padding-inline: 3rem;"
      >
        <v-card-actions class="px-5 py-4 flex-wrap justify-content-center">
          <div class="d-flex justify-content-between">
            <v-btn
              class="mx-2"
              style="width: 150px"
              color="secondary"
              depressed
              @click="close()"
            >
              Cancelar
            </v-btn>
            <v-btn
              style="width: 150px"
              color="primary"
              depressed
              @click="saveDispendios()"
            >
              Salvar
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import InputV from "@/components/input-v.vue";
import toDouble from "@/helpers/toDouble";
import { toCurrencySave } from "@/helpers/toCurrency";

const tipoRubricasCamposAdicionaisEnum = ["Viagens e Diárias"];

export default {
  components: {
    InputV
  },
  data() {
    return {
      opts: {
        rubricas: [],
        descricaoRubricas: [],
        documentos: [],
        fornecedores: [],
        tipoDespesasViagensEDiarias: [
          {
            id: 1,
            tipo: "Alimentação"
          },
          {
            id: 2,
            tipo: "Passagem"
          },
          {
            id: 3,
            tipo: "Diária"
          },
          {
            id: 4,
            tipo: "Deslocamento"
          }
        ],
        funcionarios: []
      },
      dispendio: {
        rubrica_id: null,
        pareamento_id: null,
        descricao: null,
        quantidade: null,
        valor: "0",
        fornecedor_id: null,
        cnpj_novo_fornecedor: null,
        razao_social_novo_fornecedor: null,
        uf_novo_fornecedor: null,
        endereco_novo_fornecedor: null,
        documento_id: null,
        nro_documento: null,
        fornecedor: {
          id: null,
          cnpj: null,
          razao_social: null,
          uf: null,
          endereco: null
        },
        observacao: null,
        tipoRubrica: null,
        tipoRubricaId: null,
        data_emissao: null,
        data_pagamento: null,
        contrapartida: false,
        rta: null,
        funcionario: null,
        equipe_executora: null,
        motivo: null,
        data_inicio: null,
        data_fim: null,
        origem: null,
        destino: null,
        tipo_despesa: null
      },
      isVisibleNewFornecedorData: false,
      isVisibleViagensDiarias: false,
      isDescricaoEditable: false,
      isEquipeExecutora: false,
      toCurrencySave
    };
  },
  computed: {
    ...mapGetters(["clientId"]),
    isEditForm() {
      return this.$route.params.dispendio || false;
    },
    dispendioId() {
      return this.$route.params.dispendio;
    },
    projetoId() {
      return this.$route.params.id;
    },
    cols() {
      return [
        {
          key: "rubrica_id",
          name: "Rubrica",
          type: this.$fieldTypes.SELECT,
          rel: { to: "rubricas", key: "id", name: "tipo" },
          rules: [{ rule: "required" }],
          colSize: this.isVisibleViagensDiarias ? 12 : 4,
          tooltip: true
        },
        {
          key: "pareamento_id",
          name: "Descrições da Rubrica",
          type: this.$fieldTypes.SELECT,
          rel: { to: "descricaoRubricas", key: "id", name: "descricao" },
          rules: !this.isVisibleViagensDiarias ? [{ rule: "required" }] : [],
          tooltip: true,
          colSize: 8,
          hideInform: this.isVisibleViagensDiarias,
          editable: this.isDescricaoEditable
        },
        {
          key: "descricao",
          name: "Descrição produto",
          type: this.$fieldTypes.TEXTAREA,
          rows: 1,
          rules: !this.isVisibleViagensDiarias ? [{ rule: "required" }] : [],
          tooltip: true
        },
        {
          type: this.$fieldTypes.SUBTITLE,
          name: "Cadastro de fornecedor",
          hideInform: !this.isVisibleNewFornecedorData
        },
        {
          key: "fornecedor_id",
          name: "CNPJ",
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: "fornecedores", key: "id", name: "cnpj" },
          rules: !this.isVisibleViagensDiarias ? [{ rule: "required" }] : [],
          tooltip: true,
          width: "100px",
          hideInform: this.isVisibleNewFornecedorData,
          colSize: 4
        },
        {
          key: "fornecedor_id",
          name: "Fornecedor",
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: "fornecedores", key: "id", name: "razao_social" },
          rules: !this.isVisibleViagensDiarias ? [{ rule: "required" }] : [],
          tooltip: true,
          width: "250px",
          colSize: this.isVisibleNewFornecedorData ? 4 : 8
        },

        {
          key: "fornecedor_id",
          name: "UF/País origem",
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: "fornecedores", key: "id", name: "uf" },
          tooltip: true,
          width: "250px",
          hideInform: this.isVisibleNewFornecedorData,
          colSize: 6
        },
        {
          key: "fornecedor_id",
          name: "Endereço",
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: "fornecedores", key: "id", name: "endereco" },
          hideInform: this.isVisibleNewFornecedorData,
          colSize: 6
        },
        {
          key: "cnpj_novo_fornecedor",
          name: "CNPJ",
          type: this.$fieldTypes.CNPJ,
          hideInform: !this.isVisibleNewFornecedorData,
          colSize: 4
        },
        {
          key: "razao_social_novo_fornecedor",
          name: "Razão Social",
          type: this.$fieldTypes.TEXT,

          hideInform: !this.isVisibleNewFornecedorData,
          colSize: 4
        },
        {
          key: "uf_novo_fornecedor",
          name: "UF/País",
          type: this.$fieldTypes.TEXT,
          hideInform: !this.isVisibleNewFornecedorData,
          colSize: 4
        },
        {
          key: "endereco_novo_fornecedor",
          name: "Endereço",
          type: this.$fieldTypes.TEXT,

          hideInform: !this.isVisibleNewFornecedorData,
          colSize: 8
        },
        {
          key: "documento_id",
          name: "Tipo documento",
          type: this.$fieldTypes.SELECT,
          rel: { to: "documentos", key: "id", name: "tipo" },
          rules: this.isVisibleViagensDiarias ? [{ rule: "required" }] : [],
          colSize: 6
        },
        {
          key: "nro_documento",
          name: "Nº Documento",
          type: this.$fieldTypes.TEXT,
          colSize: 6
        },
        {
          key: "valor",
          name: "Valor",
          type: this.$fieldTypes.TEXT,
          colSize: 4
        },
        {
          key: "data_emissao",
          name: "Data Emissão",
          type: this.$fieldTypes.DATE,
          colSize: 4,
          rules: this.isVisibleViagensDiarias ? [{ rule: "required" }] : []
        },
        {
          key: "data_pagamento",
          name: "Data Pagamento",
          type: this.$fieldTypes.DATE,
          colSize: 4
        },
        {
          key: "contrapartida",
          name: "Contrapartida",
          colSize: 4,
          type: this.$fieldTypes.SWITCH,
          rel: {
            toEdit: [
              { id: false, nome: "Não" },
              { id: true, nome: "Sim" }
            ],
            key: "id",
            name: "nome"
          }
        },
        // {
        //   key: "rta",
        //   name: "RTA",
        //   colSize: 8,
        //   type: this.$fieldTypes.SELECT,
        //   rel: {
        //     toEdit: [
        //       { id: 1, nome: "Primeiro RTA" },
        //       { id: 2, nome: "Segundo RTA" },
        //     ],
        //     key: "id",
        //     name: "nome",
        //   },
        // },
        {
          key: "observacao",
          name: "Observação",
          rows: 3,
          type: this.$fieldTypes.TEXTAREA,
          tooltip: true,
          width: "250px"
        }
      ];
    },
    viagensDiariasCols() {
      return [
        {
          key: "equipe_executora",
          name: "Equipe executora",
          rel: {
            toEdit: [
              { id: 0, nome: "Não" },
              { id: 1, nome: "Sim" }
            ],
            key: "id",
            name: "nome"
          },
          type: this.$fieldTypes.SWITCH,
          rules: [{ rule: "required" }],
          colSize: 4
        },
        {
          key: "funcionario",
          name: "Nome funcionário",
          type: this.$fieldTypes.TEXT,
          hideInform: this.isEquipeExecutora,
          colSize: 8
        },
        {
          key: "funcionario",
          name: "Nome funcionário",
          type: this.$fieldTypes.SELECT,
          hideInform: !this.isEquipeExecutora,
          rel: { to: "funcionarios", key: "nome", name: "nome" },
          colSize: 8
        },
        {
          key: "motivo",
          name: "Motivo da viagem",
          type: this.$fieldTypes.TEXTAREA,
          rows: 2
        },
        {
          name: "Período viajado",
          type: this.$fieldTypes.SUBTITLE
        },
        {
          key: "data_inicio",
          name: "Data início",
          type: this.$fieldTypes.DATE,
          colSize: 6,
          rules: [{ rule: "required" }]
        },
        {
          key: "data_fim",
          name: "Data término",
          type: this.$fieldTypes.DATE,
          rules: [{ rule: "required" }],
          colSize: 6
        },
        {
          name: "Trecho viajado",
          type: this.$fieldTypes.SUBTITLE
        },
        {
          key: "origem",
          name: "Origem",
          type: this.$fieldTypes.TEXT,
          colSize: 6
        },
        {
          key: "destino",
          name: "Destino",
          type: this.$fieldTypes.TEXT,
          colSize: 6
        },
        {
          name: "Documento da despesa",
          type: this.$fieldTypes.SUBTITLE
        },
        {
          key: "tipo_despesa",
          name: "Tipo da despesa",
          type: this.$fieldTypes.SELECT,
          rel: { to: "tipoDespesasViagensEDiarias", key: "tipo", name: "tipo" },
          colSize: 12
        }
      ];
    }
  },
  created() {
    this.getRubricas();
    this.getFornecedores();
    this.getDocumentos();
  },
  methods: {
    getDispendio() {
      this.apiResource(
        `/v1/captacao/${this.clientId}/dispendios/${this.projetoId}/${this.dispendioId}`
      )
        .get()
        .then(result => {
          this.dispendio = result[0];
          this.dispendio.valor = parseFloat(
            this.dispendio.valor | toDouble
          ).toLocaleString("pt-br", { style: "currency", currency: "BRL" });
          this.updateFornecedor(this.dispendio.fornecedor_id);
          this.updateRubricaDescricao(this.dispendio.rubrica_id);
          return result;
        });
    },
    getRubricas() {
      this.apiResource(`/v1/captacao/tipoRubricas`)
        .get()
        .then(result => {
          return (this.opts.rubricas = result);
        });
    },
    getDocumentos() {
      this.apiResource(`/v1/captacao/documentos`)
        .get()
        .then(result => {
          return (this.opts.documentos = result || []);
        });
    },
    getFornecedores() {
      const that = this;
      this.apiResource(`/v1/captacao/fornecedores`)
        .get()
        .then(result => {
          this.opts.fornecedores = result;
          this.opts.fornecedores.unshift({
            id: -1,
            razao_social: "Criar um fornecedor",
            cnpj: "Criar um fornecedor"
          });

          if (that.isEditForm) that.getDispendio();
          return result;
        });
    },
    updateRubricaDescricao(rubricaId) {
      this.isVisibleViagensDiarias = false;
      if (!rubricaId) return (this.isDescricaoEditable = false);
      const rubricaSelecionada = this.opts.rubricas.filter(rubrica => {
        return rubrica.id == rubricaId;
      })[0];
      if (rubricaSelecionada.tipo == tipoRubricasCamposAdicionaisEnum[0])
        this.isVisibleViagensDiarias = true;
      this.getPareamentos(rubricaId);
    },
    updateFornecedor(fornecedorId) {
      if (fornecedorId !== -1) {
        const fornecedor = this.opts.fornecedores.filter(
          fornecedor => fornecedor.id == fornecedorId
        )[0];
        this.dispendio["fornecedor"] = fornecedor;
        return (this.isVisibleNewFornecedorData = false);
      }
      this.isVisibleNewFornecedorData = true;
    },
    updateEquipeExecutora(hasEquipe) {
      if (!hasEquipe) return;
      this.apiResource(`/v1/rh/${this.clientId}/selecao`)
        .get()
        .then(result => {
          return (this.opts.funcionarios = result.colaboradores || []);
        });
    },
    getPareamentos(rubrica) {
      const that = this;
      const resource = this.apiResource(
        `/v1/captacao/${this.clientId}/rubricas/pareamento/${this.projetoId}/${rubrica}`
      );
      return resource.get().then(descricoes => {
        that.opts.descricaoRubricas = descricoes || [];
        this.isDescricaoEditable = true;
        return descricoes;
      });
    },
    saveDispendios() {
      const resource = this.apiResource(
        `/v1/captacao/${this.clientId}/dispendios/${this.projetoId}${this.isEditForm ? `/${this.dispendioId}` : ""}`
      );
      let dados = this.dispendio;
      dados["empresa_id"] = this.clientId;
      dados["projeto_id"] = this.projetoId;
      dados["fornecedor_id"] = dados.fornecedor_id || null;
      dados["valor"] = toCurrencySave(this.dispendio.valor);

      if (!this.isVisibleViagensDiarias) {
        dados.fornecedor = {
          id: !this.isVisibleNewFornecedorData ? dados.fornecedor_id : null,
          cnpj: this.isVisibleNewFornecedorData
            ? dados.cnpj_novo_fornecedor
            : dados.fornecedor.cnpj,
          razao_social: this.isVisibleNewFornecedorData
            ? dados.razao_social_novo_fornecedor
            : dados.fornecedor.razao_social,
          uf: this.isVisibleNewFornecedorData
            ? dados.uf_novo_fornecedor
            : dados.fornecedor.uf,
          endereco: this.isVisibleNewFornecedorData
            ? dados.endereco_novo_fornecedor
            : dados.fornecedor.endereco
        };
      } else {
        dados.fornecedor = null;
      }

      if (!this.isVisibleViagensDiarias) {
        (dados.funcionario = null),
          (dados.equipe_executora = null),
          (dados.motivo = null),
          (dados.data_inicio = null),
          (dados.data_fim = null),
          (dados.origem = null),
          (dados.destino = null),
          (dados.tipo_despesa = null);
      }

      return resource.save(dados).then(result => {
        if (result.error) return result.error;
        this.$notify({
          title: "Sucesso!",
          text: "Dispendio salvo com sucesso!",
          type: "success",
          group: "geral"
        });
        this.$router.push({
          name: "demais-dispendios",
          params: { id: this.projetoId }
        });
        return result;
      });
    },
    atualizaMoney(key) {
      if (key == "valor") {
        let valorSemCifrao = this.dispendio.valor.replace(/^R\$\s?/, "");
        let newVal = parseFloat(
          valorSemCifrao.replace(/\./g, "").replace(",", ".")
        );
        let formatted = newVal.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
          maximumFractionDigits: 2
        });
        this.dispendio.valor = formatted;
      }
    },
    updateFields(key, event) {
      switch (key) {
        case "fornecedor_id": {
          this.updateFornecedor(event);
          break;
        }
        case "rubrica_id": {
          if (!event) {
            this.opts.descricaoRubricas = [];
            this.isDescricaoEditable = false;
            return (this.isVisibleViagensDiarias = false);
          }
          this.updateRubricaDescricao(event);
          break;
        }
        case "equipe_executora": {
          this.isEquipeExecutora = event;
          this.updateEquipeExecutora(event);
          break;
        }
      }
    },
    close() {
      this.$router.push({
        name: "demais-dispendios",
        params: { id: this.projetoId }
      });
    }
  },
  watch: {
    clientId(novo, velho) {
      if (!!velho || !!novo)
        return this.$router.push({ name: "projetos-captacao" });
    }
  }
};
</script>
