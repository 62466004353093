<template>
  <MasterDetail
    :hasFilter="false"
    :hasExportCSV="false"
    :hasExportXLSX="false"
    :opts="opts"
    :cols="cols"
    ref="masterDetail"
    @update:finep_id="validaSelecaoFinep($event)"
    :customResource="customResource">
  </MasterDetail>
</template>

<script>
import MasterDetail from '@/components/master-detail.vue';
import { mapGetters } from 'vuex';

export default {
    // props: {},
    components: {
        MasterDetail,
    },
    // mixins: [],
    // directives: {},
     data() {
         return {
           opts: {
            tipo_garantias: [
              { tipo: 'Garantia real'},
              { tipo: 'Carta fiança'},
              { tipo: 'Seguro'},
            ],
            consultores: [],
            fineps: [],
           },
           isVisibleAddNewFinep: false,
         }
     },
    computed: {
      ...mapGetters(['clientId']),
      projetoId() {
        return this.$route.params.id;
      },
      cols() {
        return [
          {
            key: 'id',
            hideInform: true,
            hideInTable: true,
          },
          {
            key: 'projeto_id',
            hideInform: true,
            hideInTable: true,
          },
          {
            key: 'empresa_id',
            hideInform: true,
            hideInTable: true,
          },
          {
            key: 'consultor_id',
            name: 'Analista Galapos',
            type: this.$fieldTypes.AUTOCOMPLETE,
            rel: { to: 'consultores', key: 'id', name: 'nome'},
            colSize: 12
          },
          {
            key: 'finep_id',
            name: 'FINEP',
            type: this.$fieldTypes.AUTOCOMPLETE,
            rel: { to: 'fineps', key: 'id', name: 'nome'},
            colSize: 12
          },
          {
            name: 'FINEP - Dados do novo responsável',
            type: this.$fieldTypes.SUBTITLE,
            hideInform: !this.isVisibleAddNewFinep,
            hideInTable: true,
            colSize: 12
          },
          {
            key: 'novo_nome_finep',
            name: 'Nome',
            type: this.$fieldTypes.TEXT,
            hideInform: !this.isVisibleAddNewFinep,
            hideInTable: true,
            colSize: 12
          },
          {
            key: 'novo_telefone_finep',
            name: 'Telefone',
            type: this.$fieldTypes.TELEPHONE,
            hideInform: !this.isVisibleAddNewFinep,
            hideInTable: true,
            colSize: 6
          },
          {
            key: 'novo_email_finep',
            name: 'E-mail',
            type: this.$fieldTypes.TEXT,
            hideInform: !this.isVisibleAddNewFinep,
            hideInTable: true,
            colSize: 6
          },
          {
            key: 'tipo_garantia',
            name: 'Tipo garantia',
            type: this.$fieldTypes.SELECT,
            rel: { to: 'tipo_garantias', key: 'tipo', name: 'tipo'},
            colSize: 6
          },
          {
            key: 'seguradora',
            name: 'Banco/Seguradora',
            type: this.$fieldTypes.TEXT,
            colSize: 6
          },
          {
            key: 'data_emissao',
            name: 'Emissão',
            type: this.$fieldTypes.DATE,
            colSize: 6
          },
          {
            key: 'data_vencimento',
            name: 'Vencimento',
            type: this.$fieldTypes.DATE,
            colSize: 4,
            hideInform: true,
          },
          {
            key: 'data_finep',
            name: 'Data finep',
            type: this.$fieldTypes.DATE,
            colSize: 4,
            hideInform: true,
          },
          {
            key: 'nro_garantia',
            name: 'Nº garantia',
            type: this.$fieldTypes.TEXT,
            colSize: 6
          },
          {
            key: 'valor_garantia',
            name: 'Valor',
            type: this.$fieldTypes.MONEY,
            colSize: 6
          },
          {
            key: 'contato_cliente_primario',
            name: 'Contato 1',
            type: this.$fieldTypes.TEXT,
            colSize: 6
          },
          {
            key: 'contato_cliente_secundario',
            name: 'Contato 2',
            type: this.$fieldTypes.TEXT,
            colSize: 6
          },
          {
            key: 'observacoes',
            name: 'Obs.',
            type: this.$fieldTypes.TEXTAREA,
            rows: 1,
            colSize: 6
          },
          {
            key: 'data_alerta_consultor',
            name: 'Alertou consultor?',
            type: this.$fieldTypes.TEXT,
            hideInform: true,
          },
          {
            key: 'data_alerta_cliente',
            name: 'Alertou cliente?',
            type: this.$fieldTypes.TEXT,
            hideInform: true,
          },
          {
            key: 'anexo',
            name: 'Anexo',
            type: this.$fieldTypes.FILE,
            hideInTable: true,
          },
          {
            key: 'anexo',
            name: 'Baixar',
            type: this.$fieldTypes.DOWNLOAD,
            hideInform: true,
          },
        ]
      },
      resourceUrl() {
        return this.apiResource(`/v1/captacao/${this.clientId}/garantias/${this.projetoId}/garantia`);
      },
      customResource() {
        const resource = this.resourceUrl;
        const that = this;
        return {
          ...resource,
          get(...params) {
            return resource.get(params).then((result) => {
              return result;
            })
          },
          save(args) {
            let objAnexo = new FormData()
            const objectFile = that.$store.getters.uploadedFile;
            args.anexo = objectFile;
            objAnexo.append('projeto_id', that.projetoId)
            objAnexo.append('empresa_id', that.clientId)
            Object.keys(args).forEach((key) => objAnexo.append(key, args[key]))
            if(!that.isVisibleAddNewFinep) {
              return that.saveGarantia(objAnexo, resource);
            }

            that.saveResponsavel(objAnexo).then((responsavel) => {
              objAnexo['finep_id'] = responsavel.id;
              that.saveGarantia(objAnexo, resource)
            })
          }
        }
      }
    },
    // filters: {},
    created() {
      this.getConsultores();
      this.getFineps();
    },
    // mounted() {},
    // updated() {},
    // destroyed() {},
    methods: {
      async getConsultores() {
        const resource = this.apiResource(`/v1/consultores`);
        return await resource.get({ query: `empresaId=${this.clientId}` }).then((consultores) => {
          return this.opts.consultores = consultores.consultores;
        })
      },
      async getFineps() {
        const resource = this.apiResource('/v1/captacao/responsaveis-finep/selecao');
        return await resource.get().then((fineps) => {
          fineps.unshift({ id: -1, nome: 'Adicionar novo responsável'});
          return this.opts.fineps = fineps;
        })
      },
      async saveResponsavel(dados) {
        const resource = this.apiResource('/v1/captacao/responsaveis-finep');
        const responsavel = this.objetoResponsavel(dados);
        return await resource.save({...responsavel}, null).then((responsavel) => {
          return responsavel;
        })
      },
      objetoResponsavel(dados) {
        return {
          nome: dados['novo_nome_finep'],
          telefone: dados['novo_telefone_finep'],
          email: dados['novo_email_finep'],
        }
      },
      saveGarantia(dados, resource) {
        dados['projeto_id'] = this.projetoId;
        dados['empresa_id'] = this.clientId;
        return resource.save(dados, dados.id).then(() => {
          this.$refs.masterDetail.doLoad();
          this.getFineps();
          this.$notify({
            group: 'geral',
            duration: 5000,
            type: 'success',
            title: 'Dados salvos',
            text: 'Os dados inseridos foram salvos com sucesso !'
          })
          this.$refs.masterDetail.doExitForm();
          this.isVisibleAddNewFinep = false;
        })
      },
      validaSelecaoFinep(value) {
        if(value < 0) {
          return this.isVisibleAddNewFinep = true;
        }
        return this.isVisibleAddNewFinep = false;
      }
    },
    // watch: {},
}
</script>

<style>
</style>
