<template>
  <v-card class="teros-elevation mb-6" :color="tableColor">
    <v-card-title class="px-5 pb-0 text-uppercase v-title-cadastro">
      Horas Logadas por Projeto
    </v-card-title>
    <v-card-text class="pb-6">
      <div class="d-flex pt-1">
        <div class="table-v-action-button mr-3 pt-1">
          <v-icon>mdi-refresh</v-icon>Atualizar
        </div>
        <monthly-filter
          class="mb-n1 mt-0 mr-3"
          style="width: 270px"
        ></monthly-filter>
        <servico-select
          class="mb-n1 mt-0 mr-3"
          style="max-width: 255px"
          v-model="servico"
        ></servico-select>
      </div>
    </v-card-text>
    <v-row class="m-0">
      <v-col class="p-0 white">
        <div
          class="font-weight-medium pt-4 pb-3 chart-title"
          style="
            text-align: center;
            border-bottom: thin solid rgba(0, 0, 0, 0.12);
            height: 100%;
          "
        >
          Horas Totais Dedicadas a Projetos (TOP 6)
        </div>
      </v-col>
      <v-col class="p-0 white">
        <div
          class="font-weight-medium pt-4 pb-3 chart-title"
          style="
            text-align: center;
            border-bottom: thin solid rgba(0, 0, 0, 0.12);
            height: 100%;
          "
        >
          Curva ABC de Horas Dedicadas a Projetos de P&D
        </div>
      </v-col>
      <v-col class="p-0 white">
        <div
          class="font-weight-medium pt-4 pb-3 chart-title"
          style="
            text-align: center;
            border-bottom: thin solid rgba(0, 0, 0, 0.12);
            height: 100%;
          "
        >
          Curva ABC de Atividades Dedicadas a Projetos
        </div>
      </v-col>
    </v-row>
    <v-row class="m-0">
      <v-col class="p-0 white">
        <horas-projetos-competencia
          v-if="clientId"
          :competenciaIni="intervaloCompetencia[0]"
          :competenciaFim="intervaloCompetencia[1]"
          :trabalho="servico"
        ></horas-projetos-competencia>
      </v-col>
      <v-col class="p-0 white">
        <horas-projetos
          v-if="clientId"
          :competenciaIni="intervaloCompetencia[0]"
          :competenciaFim="intervaloCompetencia[1]"
          :trabalho="servico"
          :percentual="true"
        ></horas-projetos>
      </v-col>
      <v-col class="p-0 white">
        <horas-atividades
          v-if="clientId"
          :competenciaIni="intervaloCompetencia[0]"
          :competenciaFim="intervaloCompetencia[1]"
          :trabalho="servico"
          :percentual="true"
        ></horas-atividades>
      </v-col>
    </v-row>
    <v-row class="m-0">
      <v-col class="p-0 white">
        <div
          class="font-weight-medium pt-4 pb-3 chart-title"
          style="
            text-align: center;
            border-bottom: thin solid rgba(0, 0, 0, 0.12);
            height: 100%;
          "
        >
          Horas Dedicadas em Projetos por Setor
        </div>
      </v-col>
      <v-col class="p-0 white">
        <div
          class="font-weight-medium pt-4 pb-3 chart-title"
          style="
            text-align: center;
            border-bottom: thin solid rgba(0, 0, 0, 0.12);
            height: 100%;
          "
        >
          Horas Dedicadas por Projeto
        </div>
      </v-col>
      <v-col class="p-0 white">
        <div
          class="font-weight-medium pt-4 pb-3 chart-title"
          style="
            text-align: center;
            border-bottom: thin solid rgba(0, 0, 0, 0.12);
            height: 100%;
          "
        >
          Horas Dedicadas a Projetos por Atividade
        </div>
      </v-col>
    </v-row>
    <v-row class="m-0">
      <v-col class="p-0 white">
        <horas-setor-competencia
          v-if="clientId"
          :competenciaIni="intervaloCompetencia[0]"
          :competenciaFim="intervaloCompetencia[1]"
          :trabalho="servico"
        ></horas-setor-competencia>
      </v-col>
      <v-col class="p-0 white">
        <horas-projetos
          v-if="clientId"
          :competenciaIni="intervaloCompetencia[0]"
          :competenciaFim="intervaloCompetencia[1]"
          :trabalho="servico"
        ></horas-projetos>
      </v-col>
      <v-col class="p-0 white">
        <horas-atividades
          v-if="clientId"
          :competenciaIni="intervaloCompetencia[0]"
          :competenciaFim="intervaloCompetencia[1]"
          :trabalho="servico"
        ></horas-atividades>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {
    "horas-atividades": () => import("./horasprojeto/horasatividades.vue"),
    "horas-projetos": () => import("./horasprojeto/horasprojetos.vue"),
    "horas-projetos-competencia": () =>
      import("./horasprojeto/horasprojetoscompetencia.vue"),
    "horas-setor-competencia": () =>
      import("./horasprojeto/horassetorcompetencia.vue"),
    "monthly-filter": () => import("@/components/monthly-filter.vue"),
    "servico-select": () => import("@/components/servico-select.vue"),
  },
  watch: {
    clientId: function() {
      this.isClient();
    }
  },
  methods: {
    validaEmpresaSelecionada() {
      if (!this.clientId) {
        this.$notify({
          group: "geral",
          duration: 5000,
          type: "error",
          title: "Sem empresa selecionada.",
          text: "Selecione uma empresa no cabeçalho antes de acessar esse relatório.",
        });
        this.$router.push("/index");        
        return
      }
    }    
  },
  computed: {
    ...mapGetters(["intervaloCompetencia", "clientId"]),
    isClient: function () {
      this.validaEmpresaSelecionada();
      return this.getClient().isClient;
    },
  },
  data: function () {
    return {
      servico: null,
    };
  },
};
</script>

<style lang="scss" scoped>
.chart-title {
  background-color: var(--v-table-header-base) !important;
}
</style>
