<script>
import MasterDetail from "@/components/master-detail.vue";
import generateRandomFn from "@/helpers/seededRandom";
import { toNumber } from 'lodash';

// Gera dados aleatórios usando o ano base como seed
// Assim filtrar pelo mesmo ano base irá gerar os mesmos dados "aleatórios" sempre
const apiResourceMock = query => {
  const queryParams = query
    .split("&")
    .map(param => param.split("="))
    .reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: value
      }),
      {}
    );
  const { ano } = queryParams;
  const trimestral = toNumber(ano) % 2 === 1;
  const rand = generateRandomFn(query);
  const valor_IRPJ = Math.floor(rand() * 10000000) / 10000;
  const valor_CSLL = Math.floor(rand() * 10000000) / 10000;

  return { trimestral, valor_IRPJ, valor_CSLL };
};

export default {
  components: {
    MasterDetail
  },
  computed: {
    columns() {
      return [
        {
          key: "n",
          name: "Nº",
          align: 1,
        },
        {
          key: "incentivo",
          name: "Nome Incentivo"
        },
        {
          key: "valor_IRPJ",
          name: "Valor IRPJ",
        },
        {
          key: "valor_CSLL",
          name: "Valor CSLL",
        }
      ];
    },
    customResource: function() {
      const toCurrency = this.$options.filters.toCurrency;
      const setTrimestral = (value) => this.trimestral = value;

      return {
        async get({ query }) {
          // TODO inicio do mock e local pra substituir com integração real
          // const response = await this.apiResponse(``);
          const response = apiResourceMock(query);
          // TODO fim do mock

          const { trimestral, valor_IRPJ, valor_CSLL } = response ?? {};
          setTrimestral(!!trimestral);

          return [
            "Dispêndios como Despesa Operacional",
            "Redução de 50% de IPI",
            "Depreciação Acelerada Integral no Ano de Aquisição",
            "Amortização Acelerada de Bens Intangíveis",
            "Universidades, Instituições de Pesquisa e Inventores Independentes",
            "Transferências a Micro e Pequenas Empresas",
            "Transferências a Inventor Independente",
            "Dispêndios - Adicional de 60 a 80%", // index === 7
            "Patentes e Cultivares - Adicional de 20%",
            "Instituições Científicas e Tecnológicas - ICT e Entidades Científicas e Tecnológicas Privadas, sem Fins Lucrativos",
            "Depreciação Acelerada Vinculada a Projetos",
            "Amortização Acelerada de Instalações Fixas",
            "Subvenções Governamentais da União",
            "Atividades de Informática e Automação"
          ].map((label, index) => ({
            n: 30 + index,
            incentivo: `INOVAÇÃO TECNOLÓGICA - ${label}`,
            valor_IRPJ: index === 7 ? toCurrency(valor_IRPJ) : "",
            valor_CSLL: index === 7 ? toCurrency(valor_CSLL) : ""
          }));
        }
      };
    }
  },
  data: function() {
    return {
      opts: {},
      trimestral: false,
    };
  }
};
</script>

<template>
  <!-- TODO caso seja necessárioinserir a segunda linha de cabeçalho indicando o trimestre ou "Somatório do Ano" provavelmente deve trocar MasterDetail por v-data-table -->
  <MasterDetail
    class="master-detail"
    :cols="columns"
    :customResource="customResource"
    disableContextMenu
    disablePagination
    :opts="opts"
    :hasOneQuarterFilter="trimestral"
    :hasYearFilter="!trimestral"
    :canDelete="false"
    :canEdit="false"
    :hasExportCSV="false"
    :hasNewButton="false"
  ></MasterDetail>
</template>

<style lang="scss" scoped>
.master-detail {
  &,
  &::v-deep .v-data-table__wrapper {
    height: 100% !important;
  }
}
</style>
