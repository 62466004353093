<script>
import { mapGetters } from "vuex";
import yearsOptions from "@/helpers/yearsOptions";

export default {
  components: {
    MasterDetail: () => import("@/components/master-detail.vue")
  },
  computed: {
    ...mapGetters(["clientId"]),
    columns() {
      return [
        {
          key: "aliquota",
          name: "Alíquota",
          colSize: 6,
          type: this.$fieldTypes.DOUBLE,
          rules: [{ rule: "required" }]
        },
        {
          key: "ano_base",
          name: "Ano base",
          sort: 0,
          colSize: 6,
          type: this.$fieldTypes.SELECT,
          rel: { toEdit: yearsOptions, key: "value", name: "text" },
          rules: [{ rule: "required" }]
        },
        {
          key: "competencia_ini",
          name: "Competência De",
          sort: 0,
          colSize: 6,
          type: this.$fieldTypes.MONTH,
          rules: [{ rule: "required" }]
        },
        {
          key: "competencia_fim",
          name: "Competência Até",
          sort: 0,
          colSize: 6,
          type: this.$fieldTypes.MONTH,
          rules: [{ rule: "required" }]
        },
        {
          key: "criado_em",
          name: "Criado Em",
          type: this.$fieldTypes.DATETIME,
          hideInform: true
        },
        {
          key: "modificado_em",
          name: "Modificado Em",
          type: this.$fieldTypes.DATETIME,
          hideInform: true
        }
      ];
    },
    resourceUrl() {
      return `v1/faturamento/faf`;
    }
  },
  data() {
    return {
      opts: {},
    };
  }
};
</script>

<template>
  <MasterDetail
    formTitle="FAF Global"
    :cols="columns"
    :opts="opts"
    has-year-filter
    :resourceUrl="resourceUrl"
  ></MasterDetail>
</template>
